import { ConnectionOptions } from 'nats';
// import { IncomingMessage, ServerResponse, STATUS_CODES } from 'http'
import Logquery from './node/index';

declare module koa {
  interface Application {
    logquery: Logquery;
  }
}

declare module 'http' {
  interface IncomingMessage {
    logquery: Logquery;
    businessLog(msg: BusinessLog, meta?: MetaInfo): void;
    serviceLog(msg: ServicesLog, meta?: MetaInfo): void;
  }
  interface ServerResponse {
    logquery: Logquery;
    businessLog: (msg: BusinessLog, meta?: MetaInfo) => void;
    serviceLog: (msg: ServicesLog, meta?: MetaInfo) => void;
  }
}

export interface Obj {
  [key: string]: any;
}

export type LogLevel = 'D' | 'I' | 'W' | 'E' | 'F';
export type Platfrom = 'desktop' | 'mobile';
export type LogType = 0 | 1 | 2 | 4 | 5;

export interface LogObj {
  timestamp?: number;
  level: LogLevel;
  message: string | Obj;
  tag?: string;
  userId?: string;
  requestId?: string;
  logVersion?: number;
  codeVersion?: string;

  // mask
  isMasked?: boolean; // default false
  is_masked?: boolean; // default false

  [key: string]: any;
}

export interface Headers {
  'X-Platform'?: Platfrom;
  'X-DeviceID'?: string;
  [key: string]: any;
}

export type RequestCallback = (error: Error | null, data?: any) => void;

export type BeforeRequest = (options: Options) => Options;
export type AfterResponse = RequestCallback;

export interface Options {
  url?: string;
  headers?: Headers;
  timeout?: number;
  queue?: { interval: number; size: number } | null;
  transform?: boolean;
  withCredentials?: boolean;
  beforeRequest?: BeforeRequest;
  afterResponse?: AfterResponse;
  data?: Obj;
  subject?: string;
  serviceName?: string;
  instanceId?: string;
  nats?: NatsOption;
  // nats?: NatsLogOptions;
  dev?: Boolean;
  debug?: Boolean;
  encoding?: String;
  useJson?: Boolean;
  host?: string;
  type?: 'none' | 'nats' | 'normal' | 'console';
}

export type EncodeFn = (str: string) => string;

export type PostFn = (url: string, data: any, options: Options, callback: RequestCallback) => void;

export type Replacer = (m: string, ...args: any[]) => string;
export interface Pattern {
  regexp: RegExp;
  replacer: Replacer;
}

export interface LogDataType {
  timestamp?: number;
  instanceId?: string; //alias of instance_id
  instance_id?: string; // ip_pid or ip_port
  serviceName?: string; // alias of service_name klook-xxx/xxxsrv {git仓库名}}/{{服务名}}
  service_name?: string; // klook-xxx/xxxsrv {git仓库名}}/{{服务名}}
  // mask
  isMasked?: boolean; // default false

  message: string | Obj;
  level: LogLevel;
  file?: string;
  line?: string;
  funcName?: string;
  host?: string;
  subject?: string;
  [key: string]: any;
}

export type NatsLogOptions = {
  serviceName?: string;
  instanceId?: string;
  nats?: NatsOption;
};
export interface NatsOption extends ConnectionOptions {
  serviceName?: string;
  instanceId?: string;
  url?: string;
}

export type MixLogData = LogDataType | LogObj;

export enum ActionEnum {
  router = 0,
  controller = 1,
  service = 2,
  render = 3,
  other = 4,
}
export interface ServicesLog {
  action: ActionEnum;
  url: String;
  method: String;
  request_id: String;
  req_status: Number;
  level?: LogLevel;
  post_data?: any;
  cookie?: String;
  http_status?: Number;
  used?: Number;
  description?: string;
}

export interface BusinessLog {
  action: ActionEnum;
  level?: LogLevel;
  description?: string;
  msg: String | { [k: string]: any };
}

export interface MetaInfo {
  level?: LogLevel;
  file?: string;
  line?: string;
  funcName?: string;
  host?: string;
  subject?: string;
  timestamp?: number;
  instanceId?: string; //alias of instance_id
  instance_id?: string; // ip_pid or ip_port
  serviceName?: string; // alias of service_name klook-xxx/xxxsrv {git仓库名}}/{{服务名}}
  service_name?: string; // klook-xxx/xxxsrv {git仓库名}}/{{服务名}}
}
