import { IMessage, IClientCore } from "@klook/apem-core/types";
import { PluginCore } from "@klook/apem-core";
import { getHashByException,exceptionFromError } from '../utils/stackParser'

export class ApemStackParser extends PluginCore {
    init(client: IClientCore) {
        super.init(client)

        client.on('build', (message: IMessage) => {
            // 判断是否是第三方报错
            const { stack = '', message: exceptionMessage = '', name = '' } = message.exception || {}
            let isThirdPart = message.isThirdPart
            if (isThirdPart == null) {
                isThirdPart =  !!(stack && !stack.includes('klook'))
            }
            const parsedException = exceptionFromError({
                name,
                message: exceptionMessage,
                stack
            })

            return {
                ...message,
                isThirdPart,
                fingerprint: getHashByException(parsedException)
            }
        })
    }
}
