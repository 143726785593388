import { onCLS, onLCP, onINP, onTTFB, onFCP, Metric, INPMetricWithAttribution } from 'web-vitals/attribution'
import createElementTimingDirective from './ElementTimingHelper'

import { Options, LogObj, Obj, PageData } from './types'
import conf from './conf'
import { isBrowser, onPageLoaded, getDomain, getContentLength, onPageSsrLoaded, convertHighResTimeToInt, formatToThreeDecimals, generateRandomNumber } from './utils'
import report from './report'
import './shims'
// import querystring from "querystring";

// 强制开启debug
const DEBUG_UA = 'SeoValidate';
const DEBUG_STORAGE = '__clientReport:debug';

type CustomMetric = Metric | {
    name: string
    value: number
}

export default class Report {
    options: Options
    getCLS?: Promise<Metric>
    getLCP?: Promise<Metric>
    getINP?: Promise<Metric>
    getTTFB?: Promise<Metric>
    getFCP?: Promise<Metric>
    elementTimingDirective: any
    hasReportedKcp: boolean = false
    metricPageId?: string

    static promisifyRunWebVitals(fn: Function, ...args): Promise<Metric> {
        return new Promise((resolve) => {
            fn((metric: Metric) => {
                resolve(metric)
            }, ...args)
        })
    }

    static formatMetric({ name, value }: CustomMetric): Obj {
        return {
            // 等于0 就调整为0.001
            [name.toLowerCase()]: value === 0 ? 0.001 : formatToThreeDecimals(value)
        }
    }

    constructor(options: Options) {
        const forceDebug = window.navigator.userAgent.includes(DEBUG_UA) || !!window.localStorage.getItem(DEBUG_STORAGE)

        this.options = Object.assign({}, conf, options, {
            url: `${options.url}${options.url.includes('?') ? '&' : '?'}platform=${options.platform}`,
            debug: forceDebug || !!options.debug
        })

        this.elementTimingDirective = createElementTimingDirective((timing) => this.reportKcp(timing))
        this.metricPageId = generateRandomNumber()
    }

    /**
     * 页面基础的 performance 信息
     * @param pageData
     */
    private async reportPageRenderDetail(pageData: PageData) {
        if (
            !performance.getEntriesByType('navigation') ||
            !performance.getEntriesByType('paint') ||
            !performance.getEntriesByType('navigation').length
        ) {
            return
        }

        if (!pageData.pageName) return;

        const navTiming = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;

        if (navTiming.loadEventEnd <= 0) {
            const timer = window.setTimeout(() => {
                window.clearTimeout(timer)
                this.reportPageRenderDetail(pageData)
            }, 500)
            return
        }

        const paintEntries = performance.getEntriesByType('paint')
        let firstPaintTime = 0
        paintEntries.forEach((entry) => {
            if (entry.name === 'first-paint') {
                firstPaintTime = entry.startTime
            }
        })

        const url = this.options.getPageUrl!()

        // 这里也需要包含 cls lcp
        const vitalsData = await Promise.all([
            this.getCLS!,
            this.getLCP!,
        ]).then(res => res.reduce((pre: Obj, curMetric: Metric) => {
            return {
                ...pre,
                ...Report.formatMetric(curMetric)
            }
        }, {}))

        const logObj: LogObj = {
            timestamp: Date.now(),
            level: 'I',
            is_masked: true,
            message: {
                _page_id_: this.metricPageId,
                _domain_: getDomain(),
                _logger_name_: 'access_log:frontend:web',
                _page_: pageData.pageName,
                _url_: url,
                _duration_: convertHighResTimeToInt(navTiming.duration),
                _succ_: true,
                _code_: 200,
                _error_: '',
                network: 'NA',
                upstream: 0,
                downstream: 0,
                // Ref: http://www.alloyteam.com/2020/01/14184/
                redirect_dt: convertHighResTimeToInt(navTiming.redirectEnd - navTiming.redirectStart), // 重定向耗时
                fp_dt: convertHighResTimeToInt(firstPaintTime), // 首次绘制耗时
                dns_dt: convertHighResTimeToInt(navTiming.domainLookupEnd - navTiming.domainLookupStart), // DNS 查询耗时
                tcp_dt: convertHighResTimeToInt(navTiming.connectEnd - navTiming.connectStart), // TCP 连接耗时
                reqres_dt: convertHighResTimeToInt(navTiming.responseEnd - navTiming.requestStart), // 请求耗时
                dominteractive_dt: convertHighResTimeToInt(navTiming.domInteractive - navTiming.startTime), // DOM 树状态改为 interactive 耗时
                domready_dt: convertHighResTimeToInt(navTiming.domContentLoadedEventEnd - navTiming.startTime), // DOMReady 耗时
                load_dt: convertHighResTimeToInt(navTiming.loadEventEnd - navTiming.startTime), // window onload 耗时
                ...vitalsData // 添加lcp, cls 时间统计
            }
        }

        this.reportData(logObj)  // @NOTE: 页面加载完成，立即上报，防止漏报
    }

    private reportData(logObj: LogObj, useQueue: boolean = false) {
        Object.assign(logObj.message, this.options.extraMsg)
        report(this.options, logObj, useQueue)
    }

    // 手动更新扩展msg信息
    setExtraMsg(msg: Obj) {
        this.options.extraMsg = msg
    }

    // 发送web vitals 数据
    sendWebVitalsData(metric: CustomMetric) {
        const pageData = this.options.getPageData()
        const data = Report.formatMetric(metric)
        const url = this.options.getPageUrl!()

        const logObj: LogObj = {
            timestamp: Date.now(),
            level: 'I',
            is_masked: true,
            message: {
                _page_id_: this.metricPageId,
                _domain_: getDomain(),
                _logger_name_: 'access_log:frontend:vitals',
                _page_: pageData.pageName,
                _url_: url,
                _code_: 200,
                ...data,
            }
        }

        this.reportData(logObj)
    }

    /**
     * 追踪页面核心指标
     * @param pageData
     */
    private reportWebVitals() {
        [this.getCLS, this.getLCP, this.getINP, this.getFCP, this.getTTFB].forEach((getMetricFn) => {
            if (getMetricFn) {
                getMetricFn.then((metric: Metric) => {
                    this.sendWebVitalsData(metric)
                })
            }
        })
    }

    /**
     * 页面基础的 图片加载信息
     * @param options
     */
    reportImgLoadTime(options?: {
        filter: {
            minSize?: number, // kb
            minDuration?: number, // ms
            custom?: (item: any) => boolean
        }
    }) {
        const pageData = this.options.getPageData()

        const sendImgData = (imgData: {
            encodedBodySize: number
            duration: number
            imgUrl: string
            imgWidth?: number
            imgHeight?: number
            containerWidth?: number
            containerHeight?: number
        }) => {
            const url = this.options.getPageUrl!()
            const logObj: LogObj = {
                timestamp: Date.now(),
                level: 'I',
                is_masked: true,
                message: {
                    _domain_: getDomain(),
                    _logger_name_: 'access_log:frontend:web-image',
                    _page_: pageData.pageName,
                    _url_: url,
                    ...imgData
                }
            }

            this.reportData(logObj, true)
        }

        const filterOptions = {
            minSize: 200, // 200kb
            minDuration: 200, // 200ms
            custom: () => true,
            ...options ? options.filter : {}
        }

        // report entryList
        const reportImgEntryList = (entryList: PerformanceEntryList) => {
            const filterList = entryList.map(item => item.toJSON())
                .filter(item => {
                    // img 或者 css包含png、webp等
                    if (
                        !(item.initiatorType === 'img' || (item.initiatorType === 'css' && /\.(png|jpe?g|gif|bmp|psd|tiff|tga|eps|webp)$/.test(item.name)))
                    ) {
                        return false
                    }

                    if ((item.encodedBodySize / 1024) < filterOptions.minSize) {
                        return false
                    }

                    if ((item.duration) < filterOptions.minDuration) {
                        return false
                    }

                    if (filterOptions.custom) {
                        return filterOptions.custom(item)
                    }

                    return true
                })

            if (!filterList.length) return

            filterList.forEach(item => {
                const data = {
                    encodedBodySize: Math.round(item.encodedBodySize / 1024),
                    duration: Math.round(item.duration),
                    imgUrl: item.name
                }

                sendImgData(data)
            })
        }

        // PerformanceObserver ？
        if (performance?.getEntriesByType && PerformanceObserver) {
            // 立即上报
            reportImgEntryList(performance.getEntriesByType('resource'))

            // 监听图片资源加载 增量上报
            const observer = new PerformanceObserver((list) => {
                reportImgEntryList(list.getEntries())
            });

            observer.observe({ entryTypes: ['resource'] });
        }
    }


    /**
     * 自动追踪页面性能相关数据
     * @param pageData
     */
    reportPerformance(vueApp, pageData?: PageData) {
        try {
            // 默认pageData
            if (!pageData) pageData = this.options.getPageData()

            if (!isBrowser() || !pageData.pageName) return

            // 反复调用不更新值
            if (!this.getCLS) {
                this.getCLS = Report.promisifyRunWebVitals(onCLS)
                this.getLCP = Report.promisifyRunWebVitals(onLCP)
                this.getINP = Report.promisifyRunWebVitals(onINP)
                this.getTTFB = Report.promisifyRunWebVitals(onTTFB)
                this.getFCP = Report.promisifyRunWebVitals(onFCP)
            }

            // 上报 web vitals
            this.reportWebVitals()

            // 单独上报 INP
            this.reportInp()

            // 旧的performance 信息，onload上报
            onPageLoaded(() => {
                if (pageData) {
                    this.reportPageRenderDetail(pageData).then(() => {
                        // nothing ...
                    })
                }
            })

            // 上报ssr hydration
            onPageSsrLoaded(vueApp, () => {
                this.reportHydration()
            })
        } catch (e) {
            // 如果有异常，不影响页面正常运行
        }

    }

    /**
     * 追踪请求成功
     * @param pageData
     * @param config axios config
     * @param response axios response
     */
    reportRequestSuccess(pageData: PageData, config: any, response: any) {
        if (!isBrowser()) return

        if (!pageData) pageData = this.options.getPageData()

        setTimeout(() => {
            try {
                // const [url, query = ''] = config.url.split('?')
                const [url] = config.url.split('?')
                const error = (response.data && response.data.error) || {}
                const logObj: LogObj = {
                    timestamp: Date.now(),
                    level: 'I',
                    is_masked: true,
                    message: {
                        _domain_: getDomain(),
                        _logger_name_: 'access_log:frontend_http_client',
                        _url_: url,
                        _page_: pageData.pageName || 'global_page',
                        _duration_: Date.now() - config.startTime,
                        _succ_: true,
                        _code_: error.code || '',
                        _error_: error.message || '',
                        http_rsp_code: response.status,
                        network: 'NA',
                        upstream: config.data ? getContentLength(JSON.stringify(config.data)) : 0,
                        downstream: getContentLength(JSON.stringify(response.data)),
                        // arguments: querystring.stringify(config.param) + (query ? '&' + query : '')
                    }
                }

                this.reportData(logObj, true)
            } catch (error) {
                // Ignore
            }
        }, 0)
    }

    /**
     * 追踪请求失败
     * @param pageData
     * @param config axios config
     * @param response axios response
     * @param error Error
     */
    reportRequestError(pageData: PageData, config: any, response: any, error: any) {
        if (!isBrowser()) return

        if (!pageData) pageData = this.options.getPageData()

        setTimeout(() => {
            try {
                // const [url, query = ''] = config.url.split('?')
                const [url] = config.url.split('?')

                const logObj: LogObj = {
                    timestamp: Date.now(),
                    level: 'I',
                    is_masked: true,
                    message: {
                        _domain_: getDomain(),
                        _logger_name_: 'access_log:frontend_http_client',
                        _url_: url,
                        _page_: pageData.pageName || 'global_page',
                        _duration_: Date.now() - config.startTime,
                        _succ_: false,
                        _code_: '',
                        _error_: error.message || '',
                        http_rsp_code: (response && response.status) || 400,
                        network: 'NA',
                        upstream: 0,
                        downstream: 0,
                        // arguments: querystring.stringify(config.param) + (query ? '&' + query : '')
                    }
                }

                this.reportData(logObj, true)
            } catch (error) {
                // Ignore
            }
        }, 0)
    }

    /**
     * 上报自定义关键内容呈现时间
     * timing：如果使用 Element Timing API 就传呈现时间，手动调方法就不用传
     * @param timing
    */
    reportKcp(timing?: any) {
        if (this.hasReportedKcp) return
        const renderTime = timing || performance.now()
        const dataObj = {
            name: 'kcp_dt',
            value: renderTime,
        }

        this.sendWebVitalsData(dataObj)
        this.hasReportedKcp = true
    }

    /**
     * 上报hydration
     * @param pageData
     */
    private async reportHydration() {
        // 计算hydration
        const hydrationDuration = performance.now()
        const dataObj = {
            name: 'hydration_dt',
            value: hydrationDuration,
        }

        this.sendWebVitalsData(dataObj)
    }

    /**
     * 单独上报 INP
     * @param pageData
     */
    private async reportInp() {
        onINP((inpMetric: INPMetricWithAttribution) => {
            const pageData = this.options.getPageData()
            const url = this.options.getPageUrl!()
            const logObj: LogObj = {
                timestamp: Date.now(),
                level: 'I',
                is_masked: true,
                message: {
                    _page_id_: this.metricPageId,
                    _domain_: getDomain(),
                    _logger_name_: 'access_log:frontend:always_inp',
                    _page_: pageData.pageName,
                    _url_: url,
                    _code_: 200,
                    inp: formatToThreeDecimals(inpMetric.value),
                    interaction_target: inpMetric?.attribution?.interactionTarget,
                    input_delay: formatToThreeDecimals(inpMetric?.attribution?.inputDelay),
                    processing_duration: formatToThreeDecimals(inpMetric?.attribution?.processingDuration),
                    presentation_delay: formatToThreeDecimals(inpMetric?.attribution?.presentationDelay),
                }
            }

            if (
                this.options.debug &&
                inpMetric.value &&
                inpMetric.value < 200
            ) {
                console.log(`--client-report--[${logObj.message?._logger_name_}--not-reported]`, logObj) // 不会上报，用于排查
                return
            }

            this.reportData(logObj)
        }, { reportAllChanges: true })
    }
}
