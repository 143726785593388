import storage from './storage'
import { getDbItem, setDbItem, getDbKeys, removeDbItem } from './db'

class TrackerCache {
  static setItem(key: string, value: any, maxAge?: number) {
    storage.local.set(key, value, maxAge)
  }
  static getItem(key: string) {
    return storage.local.get(key)
  }
  static removeItem(key: string) {
    storage.local.remove(key)
  }

  static async setItemAsync(key: string, value: any) {
    await setDbItem(key, value)
  }

  static async getItemAsync() {
    const keys = await getDbKeys()
    if (keys.length) {
      const key = keys[0]
      const data = await getDbItem(key)
      if (!data) {
        await removeDbItem(key)
        return null
      }
      return { key, value: data, length: keys.length }
    }
    return null
  }

  static async removeItemAsync(key: string) {
    await removeDbItem(key)
  }
}

export default TrackerCache
