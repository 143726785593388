function isMergeable(value: any) {
    return value && typeof value === "object"
}

function emptyTarget(val) {
    return Array.isArray(val) ? [] : {}
}

function clone(value) {
    if (!isMergeable(value)) {
        return value
    }

    return deepmerge(emptyTarget(value), value)
}

function arrayMerge(target, source) {
    return target.concat(source).map(function(element) {
        return clone(element)
    })
}

function getKeys(target) {
    return Object.keys(target)
}

function propertyIsOnObject(object, property) {
    try {
        return property in object
    } catch(_) {
        return false
    }
}

function mergeObject(target, source) {
    const destination = {}
    if (isMergeable(target)) {
        getKeys(target).forEach(function(key) {
            destination[key] = clone(target[key])
        })
    }

    getKeys(source).forEach(function(key) {
        const s = source[key]
        if (propertyIsOnObject(target, key) && isMergeable(s)) {
            destination[key] = deepmerge(target[key], s)
        } else {
            destination[key] = clone(source[key])
        }
    })
    return destination
}

export default function deepmerge(target, source) {
    const sourceIsArray = Array.isArray(source)
    const targetIsArray = Array.isArray(target)
    const sourceAndTargetTypesMatch = sourceIsArray === targetIsArray

    if (!sourceAndTargetTypesMatch) {
        return clone(source)
    }

    if (sourceIsArray) {
        return arrayMerge(target, source)
    }

    return mergeObject(target, source)
}
