function isBuiltin(wat: unknown, className: string): boolean {
    return Object.prototype.toString.call(wat) === `[object ${className}]`;
}

export function isString(wat: unknown): wat is string {
    return isBuiltin(wat, 'String');
}

/**
 * Returns a simple, query-selector representation of a DOM element
 * e.g. [HTMLElement] => input#foo.btn[name=baz]
 * @returns generated DOM path
 */
export function _htmlElementAsString(el: unknown): string {
    const elem = el as {
        tagName?: string;
        id?: string;
        className?: string;
        getAttribute(key: string): string;
    };

    const out = [];
    let className;
    let classes;
    let key;
    let attr;
    let i;

    if (!elem || !elem.tagName) {
        return '';
    }

    out.push(elem.tagName.toLowerCase());

    if (elem.id) {
        out.push(`#${elem.id}`);
    }

    // eslint-disable-next-line prefer-const
    className = elem.className;
    if (className && isString(className)) {
        classes = className.split(/\s+/);
        for (i = 0; i < classes.length; i++) {
            out.push(`.${classes[i]}`);
        }
    }
    const allowedAttrs = ['type', 'name', 'title', 'alt'];
    for (i = 0; i < allowedAttrs.length; i++) {
        key = allowedAttrs[i];
        attr = elem.getAttribute(key);
        if (attr) {
            out.push(`[${key}="${attr}"]`);
        }
    }
    return out.join('');
}

export function htmlTreeAsString(elem: HTMLElement, dom?: {
    maxTraverseHeight: number
    maxOutputLen: number
}): string {
    type SimpleNode = {
        parentNode: SimpleNode;
    } | null;

    const MAX_TRAVERSE_HEIGHT = dom?.maxTraverseHeight || 5;
    const MAX_OUTPUT_LEN = dom?.maxOutputLen || 80;

    try {
        let currentElem = elem as SimpleNode;
        const out = [];
        let height = 0;
        let len = 0;
        const separator = ' > ';
        const sepLength = separator.length;
        let nextStr;

        // eslint-disable-next-line no-plusplus
        while (currentElem && height++ < MAX_TRAVERSE_HEIGHT) {
            nextStr = _htmlElementAsString(currentElem);
            if (nextStr === 'html' || (height > 1 && len + out.length * sepLength + nextStr.length >= MAX_OUTPUT_LEN)) {
                break;
            }

            out.push(nextStr);

            len += nextStr.length;
            currentElem = currentElem.parentNode;
        }

        return out.reverse().join(separator);
    } catch (_oO) {
        return '<unknown>';
    }
}
