import { Tracker } from '../tracker'
import * as Constants from '../constants'
import { ActionTypeOptions, StateType } from '../types'
import { getNow } from '../utils'

export default function time(tracker: Tracker) {
  const config = {
    valid: false,
    serverTime: getNow(),
    usePerformance: false,
    now: getNow(),
  }

  const now = () => (config.usePerformance ? performance.now() : Date.now())

  function serverTimeAction(state: StateType, action?: ActionTypeOptions) {
    if (action?.type === 'ServerTime') {
      const clientNow = now()
      const range = Math.ceil(clientNow - config.now)
      const timestamp = config.serverTime + range
      return { ...state, timestamp }
    }
    return state
  }

  tracker.on(Constants.ConfigChangeEvent, () => {
    const serverTime = tracker.options.serverTime
    if (!serverTime || config.valid) return
    config.valid = true
    config.serverTime = serverTime!
    if (typeof window.performance.now !== 'undefined') {
      config.usePerformance = true
      config.now = window.performance.now()
    } else {
      config.usePerformance = false
      config.now = getNow()
    }
  })

  tracker.on(Constants.ServerTimeInvalid, () => {
    config.valid = false
  })

  tracker.registerAction(serverTimeAction)
}
