import { Pattern } from './types';

const defaultPatterns = [
  // email
  {
    regexp: /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g,
    replacer: (m: string): string =>
      m.replace(/^(\S)(.*)(\S@.*)$/, (_, p1, p2, p3) => {
        return `${p1}${'*'.repeat(p2.length)}${p3}`;
      }),
  },
  // cell phone
  {
    regexp: /\d{1}\d{0,5}-\d{1,14}/g,
    replacer: (m: string): string =>
      m.replace(/^(\d+-)(\d+)(\d{4})/, (_, p1, p2, p3) => {
        return `${p1}${'*'.repeat(p2.length)}${p3}`;
      }),
  },
  // credit card
  {
    regexp: /[1-6]\d{14,18}/g,
    replacer: (m: string): string =>
      m.replace(/^(\d{4})(\d+)(\d{4})/, (_, p1, p2, p3) => {
        return `${p1}${'*'.repeat(p2.length)}${p3}`;
      }),
  },
];

export function toFuzzy(str: string, patterns: Pattern[] = defaultPatterns): string {
  let source = str;

  patterns.forEach(pattern => {
    source = source.replace(pattern.regexp, pattern.replacer);
  });

  return source;
}
