class ElementTimingHelper {
    private observer: PerformanceObserver | null

    constructor() {
        this.observer = null
    }

    startObserving(callback) {
        if ('PerformanceObserver' in window && PerformanceObserver?.supportedEntryTypes?.includes('element')) {
            this.observer = new PerformanceObserver((list) => {
                const entries = list.getEntries()
                entries.forEach((entry: any) => {
                    if (callback) {
                        callback(entry.renderTime)
                    }
                })
            })
            this.observer.observe({ type: 'element', buffered: true })
        }
    }

    stopObserving() {
        if (this.observer) {
            this.observer.disconnect()
            this.observer = null
        }
    }
}

export default function (callback: Function) {
    const elementTimingHelper = new ElementTimingHelper()

    return {
        bind: (el, binding) => {
            if (binding.value === 'kcp') {
                el.setAttribute('elementtiming', binding.value)
            }
        },
        inserted: () => {
            elementTimingHelper.startObserving((timing) => {
                elementTimingHelper.stopObserving()
                callback(timing)
            })
        },
        unbind: () => {
            elementTimingHelper.stopObserving()
        }
    }
}