/**
 * Created by Liu.Jun on 2022/12/8 11:44.
 */
import Data from '../types'

export interface langItem {
  label: string
  value: Data.Language
  f2bValue: string,
  path: string,
  countryCode: Data.Country | 'BS' | 'HKG'
}

// en_BS语言
export const enBSLang: langItem[] = [{ label: 'English (International)', value: 'en', f2bValue: 'en_BS', path: '', countryCode: 'BS' }]

// en_US语言
export const enUSLang: langItem[] = [{ label: 'English (US)', value: 'en-US', f2bValue: 'en_US', path: '/en-US', countryCode: 'US' }]

// en语言(不包括en_BS) length = 10
export const enLangExceptBS: langItem[] = [
  ...enUSLang,
  { label: 'English (Australia)', value: 'en-AU', f2bValue: 'en_AU', path: '/en-AU', countryCode: 'AU' },
  { label: 'English (New Zealand)', value: 'en-NZ', f2bValue: 'en_NZ', path: '/en-NZ', countryCode: 'NZ' },
  { label: 'English (UK)', value: 'en-GB', f2bValue: 'en_GB', path: '/en-GB', countryCode: 'GB' },
  { label: 'English (India)', value: 'en-IN', f2bValue: 'en_IN', path: '/en-IN', countryCode: 'IN' },
  { label: 'English (Singapore)', value: 'en-SG', f2bValue: 'en_SG', path: '/en-SG', countryCode: 'SG' },
  { label: 'English (Canada)', value: 'en-CA', f2bValue: 'en_CA', path: '/en-CA', countryCode: 'CA' },
  { label: 'English (Hong Kong, SAR)', value: 'en-HK', f2bValue: 'en_HK', path: '/en-HK', countryCode: 'HKG' },
  { label: 'English (Philippines)', value: 'en-PH', f2bValue: 'en_PH', path: '/en-PH', countryCode: 'PH' },
  { label: 'English (Malaysia)', value: 'en-MY', f2bValue: 'en_MY', path: '/en-MY', countryCode: 'MY' }
]

// eu语言 length = 5
export const euLang: langItem[] = [
  { label: 'Français', value: 'fr', f2bValue: 'fr_FR', path: '/fr', countryCode: 'FR' },
  { label: 'Español', value: 'es', f2bValue: 'es_ES', path: '/es', countryCode: 'ES' },
  { label: 'Deutsch', value: 'de', f2bValue: 'de_DE', path: '/de', countryCode: 'DE' },
  { label: 'Русский', value: 'ru', f2bValue: 'ru_RU', path: '/ru', countryCode: 'RU' },
  { label: 'Italiano', value: 'it', f2bValue: 'it_IT', path: '/it', countryCode: 'IT' }
]

// 其他语言 length = 9
export const otherLang: langItem[] = [
  { label: '简体中文', value: 'zh-CN', f2bValue: 'zh_CN', path: '/zh-CN', countryCode: 'CN' },
  { label: '香港繁體', value: 'zh-HK', f2bValue: 'zh_HK', path: '/zh-HK', countryCode: 'HK' },
  { label: '台灣繁體', value: 'zh-TW', f2bValue: 'zh_TW', path: '/zh-TW', countryCode: 'TW' },
  { label: '日本語', value: 'ja', f2bValue: 'ja_JP', path: '/ja', countryCode: 'JP' },
  { label: '한국어', value: 'ko', f2bValue: 'ko_KR', path: '/ko', countryCode: 'KR' },
  { label: 'ไทย', value: 'th', f2bValue: 'th_TH', path: '/th', countryCode: 'TH' },
  { label: 'Tiếng Việt', value: 'vi', f2bValue: 'vi_VN', path: '/vi', countryCode: 'VN' },
  { label: 'Bahasa Indonesia', value: 'id', f2bValue: 'id_ID', path: '/id', countryCode: 'ID' },
  { label: 'Bahasa Malaysia', value: 'ms-MY', f2bValue: 'ms_MY', path: '/ms-MY', countryCode: 'MY' }
]

// 全部语言 length = 25
export const allLang: langItem[] = [
  ...enBSLang,
  ...enLangExceptBS,
  ...euLang,
  ...otherLang
]
