import {allLang, enBSLang, enLangExceptBS, enUSLang, euLang, otherLang} from './config'

// 获取所有最全的语言内容
export function getAllLang() {
  return allLang
}

/**
 * klook-admin && klook-admin-web-platform项目 lang数据结构映射
 * {
    label => undefined
    value => F_LANG: "en",
    path => LANG_PATH: "/en-US",
    f2bValue => B_LANG: "en_US",
    countryCode => COUNTRY_CODE: "US"
  },
 */
function klookAdminLangFormat(arr) {
  return arr.map((item) => {
    return {
      F_LANG: item.value,
      LANG_PATH: item.path,
      B_LANG: item.f2bValue,
      COUNTRY_CODE: item.countryCode
    }
  })
}

// klook-admin && klook-admin-web-platform LANGUAGES_CONF 变量
export function getLanguagesConf() {
  const enUS = JSON.parse(JSON.stringify(enUSLang))
  enUS[0].value = 'en'  // 特殊逻辑，en-US的value为en
  const arr = [...enUS, ...otherLang]
  return klookAdminLangFormat(arr)
}

// klook-admin && klook-admin-web-platform en_lang_codes 变量
export function getEnLangCodes() {
  const enBS = JSON.parse(JSON.stringify(enBSLang))
  enBS[0].value = 'en-BS'  // 特殊逻辑，en-BS的value由en改为en-BS
  const arr = [...enBS, ...enLangExceptBS]
  return klookAdminLangFormat(arr)
}

// klook-admin && klook-admin-web-platform eu_lang_codes 变量
export function getEuLangCodes() {
  return klookAdminLangFormat(euLang)
}


/**
 * klook-admin-config-tool lang数据结构映射
 * {
    label => label,
    value => value,
    path => undefined
    f2bValue => f2bvalue & F2bLang
    countryCode => undefined
  },
 */
function klookAdminConfigToolLangFormat(arr) {
  return arr.map((item) => {
    return {
      label: item.label,
      value: item.value,
      f2bvalue: item.f2bValue,
      F2bLang: item.f2bValue
    }
  })
}

// klook-admin-config-tool lang 变量
export function getLang() {
  return klookAdminConfigToolLangFormat(allLang)
}

// klook-admin-config-tool onlyEnLang 变量
export function getAggregateEnLang() {
  const arr = [...enBSLang, ...otherLang, ...euLang]
  return klookAdminConfigToolLangFormat(arr)
}

// klook-admin-config-tool groupLang 变量
export function getGroupLang() {
  return [
    {
      label: 'English Local',
      options: enLangExceptBS
    },
    {
      label: 'English (International)',
      options: enBSLang
    },
    {
      label: 'Other languages',
      options: [...otherLang, ...euLang]
    }
  ]
}

export {
  allLang,
  enBSLang,
  enLangExceptBS,
  enUSLang,
  euLang,
  otherLang
}