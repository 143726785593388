import { IMessage } from '@klook/apem-core/types/index'
import {PluginCore} from "@klook/apem-core";
import { getCookie } from "../utils/cookie";

interface IEnvConfig {
    sdk: {
        version: string,
        name: string
    }
}

const ENVINFO = Symbol('Context#device#deviceInfo');
const APPINFO = Symbol('Context#device#cookieInfo');
const mobileRegexp = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk|iPad|iPhone|iPod|klook/i

export class ApemPluginEnv extends PluginCore {
    static pluginName = 'ApemPluginEnv'
    config!: IEnvConfig

    constructor(config?: IEnvConfig) {
        super(config)

        const defaultConfig = {
            sdk: {
                name: ApemPluginEnv.name,
                version: '?'
            }
        }
        this.config = config || defaultConfig
    }

    get appInfo() {
        if (this[APPINFO]) {
            return this[APPINFO];
        }

        return {
            aid: getCookie('aid')
        }
    }

    get envInfo() {
        if (this[ENVINFO]) {
            return this[ENVINFO];
        }

        let keplerId = this.client.config.report?.keplerId

        if (!keplerId) {
            keplerId = getCookie('kepler_id')
        }

        // 使用方可以传入platform，这里只做一个简单的兜底
        const isMobile = mobileRegexp.test(window.navigator.userAgent)
        const platform = isMobile ? 'mobile' : 'desktop'

        this[ENVINFO] = {
            sdk: this.config.sdk,
            keplerId,
            platform,
        }

        return this[ENVINFO]
    }

    getRequestContext(event: IMessage) {
        const url = (event.request && event.request.url) || (window.location && window.location.href);
        const { referrer } = window.document;
        const { userAgent } = window.navigator;

        const headers = {
            ...(event.request && event.request.headers),
            ...(referrer && { Referer: referrer }),
            ...(userAgent && { 'User-Agent': userAgent }),
        };

        return { url, ...headers };
    }

    build(message: IMessage, context: { plugins: any[] }) {
        context.plugins.push({ name: ApemPluginEnv.pluginName })

        return {
            ...message,
            ...this.envInfo,
            ...this.getRequestContext(message),
            ...this.appInfo,
            timestamp: Date.now()
        }
    }
}
