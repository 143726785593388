import {PluginCore} from "@klook/apem-core";
import {IClientCore} from "@klook/apem-core/types";

// 上报apem sdk加载之前提前收集的错误信息
export class ApemPluginPreparation extends PluginCore {
    init(client: IClientCore) {
        client.on('plugin', () => {
            const precolletion = window.precolletion

            if (precolletion?.length) {
                precolletion.forEach(v => {
                    const { event, eventName } = v

                    const customEvent = 'error' in event ?
                        new ErrorEvent(eventName, event)
                        : new PromiseRejectionEvent(eventName, event)

                    window.dispatchEvent(customEvent);
                })
            }
        })
    }
}
