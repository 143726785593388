import { EncodeFn } from './types'

/**
 * Base64 编码
 * @param str 待编码字符
 */
export const encode: EncodeFn = str => {
  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(Number(`0x${p1}`))
    )
  )
}
