export const EventSpm = 'event:spm'

export const EventPageView = 'event:pageview'
export const PageViewData = 'pageview:data'
export const BeforePageViewData = 'before:pv:data'

export const EventPrePush = 'event:pre-push'

export const EventReadyPush = 'event:ready-push'

export const EventPushFail = 'event:push-fail'

export const EventPushSuccess = 'event:push-success'

export const EventPreparePushExposure = 'event:pre:exposure'

export const EventExposure = 'event:exposure:success'

export const EventCustom = 'event:custom'
export const CustomData = 'custom:data'
export const BeforeCustomData = 'before:custom:data'

export const EventAction = 'event:action'

export const EventVirtualAction = 'event:virtual:action'

export const ExposureSyncKey = 'local:exposure:key'

export const LocalConfigKey = 'local:config'

export const Manual = 'manual'

export const ConfigChangeEvent = 'config:change'

export const ServerTimeInvalid = 'ServerTimeInvalid'

export const InhouseInit = 'init'
export const InhouseInitReady = 'init-ready'

export const RequestSuccess = 'request:success'
export const RequestFinish = 'request:finish'
export const RequestFail = 'request:fail'

export const SpmTypeMap = {
  page: 'data-spm-page',
  module: 'data-spm-module',
  item: 'data-spm-item',
  virtualItem: 'data-spm-virtual-item',
}

export const SpmKeyMap = {
  custom: 'page',
  page: 'page',
  item: 'item',
  virtualItem: 'item',
  module: 'module',
}

export const AsyncDataCache = 'A._dataCache'

export const ApplicationJson = 'application/json'

export const TrackerAttr = '__tracker__'
