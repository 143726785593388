import { Options, LogObj, Obj } from './types';
import {isBrowser} from './utils';

// @ts-ignore
import { toFuzzy, deepMerge, encode } from '@klook/logquery'

/**
 * encode数据，同 logQuery
 * @param logObj
 */
function transform(logObj: LogObj): LogObj | Obj {
    const logObjCopy = deepMerge({} as LogObj, logObj);

    logObjCopy.logVersion = logObjCopy.hasOwnProperty('logVersion') ? logObjCopy.logVersion : 1;
    logObjCopy.type === 5 && ((logObjCopy.message as Obj).system = 'optimus');
    logObjCopy.message =
        logObjCopy.type === 0
            ? encode(toFuzzy(logObjCopy.message as string))
            : encode(JSON.stringify(logObjCopy.message));

    const {
        timestamp,
        message,
        userId,
        requestId,
        logVersion,
        codeVersion,
        ...others
    } = logObjCopy;

    const newLogObj: Obj = {
        time: timestamp,
        msg: message,
        log_ver: logVersion,
        type: 4,
        ...others,
    };

    userId && (newLogObj.uid = userId);
    requestId && (newLogObj.req_id = requestId);
    codeVersion && (newLogObj.code_ver = codeVersion);

    return newLogObj;
}

/**
 * beacon fetch 发送数据
 * @param url
 * @param data
 */
function fetch(url: string, data: Obj) {
    try {
        if (!data) return;

        const reportData = JSON.stringify(data)

        if (navigator.sendBeacon) {
            navigator.sendBeacon(url, new Blob([reportData], {type : 'application/json'}))
            return;
        }

        if (window.fetch) {
            window.fetch!(url, {
                headers: { 'content-type': 'application/json' },
                body: reportData,
                method: "POST",
                credentials: "omit"
            });
        }
    } catch (error) {
        // Ignore
    }
}

/**
 * 格式化report数据
 * @param data
 */
function formatReportData(data: any) {
    return {
        logs: data
    }
}

const queueLogData: Obj[] = []
let queueTimer: undefined | number = undefined

/**
 * 上报数据，优先 beacon、fetch 不支持不上报数据
 * @param options
 * @param logObj
 * @param useQueue
 */
export default function (options: Options, logObj: LogObj, useQueue: boolean = false) {
    if (!isBrowser()) return;
    if (options.debug) {
        console.log(`--client-report--[${logObj.message?._logger_name_}]`, logObj)
    }

    const realLogObj = transform(logObj)

    // 合并上报
    if (useQueue) {
        clearTimeout(queueTimer)
        queueLogData.push(realLogObj)

        if (queueLogData.length >= options.queueSize!) {
            fetch(options.url, formatReportData(queueLogData))

            // flush
            queueLogData.length = 0
            return;
        }

        queueTimer = window.setTimeout(() => {
            fetch(options.url, formatReportData(queueLogData))
            queueLogData.length = 0
        }, options.queueInterval)
        return;
    }

    // 立即上报
    fetch(options.url, formatReportData([realLogObj]))
};
