import {Options} from './types';

const conf:Options = {
    url: 'https://t145.test.klook.io/v2/frontlogsrv/log/web',
    platform: 'desktop',
    queueInterval: 5000, // 合并5s内的请求
    queueSize: 15, // 最多合并条数
    getPageData: () => ({}),
    getPageUrl() {
        return window.location.href.split('?')[0]
    },
    extraMsg: {
        // tag: 'default'
    }
}

export default conf
