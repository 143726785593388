import { Tracker } from '../tracker'
import * as Constants from '../constants'
import { LogObj, PageElement } from '../types'
import { uidWithVersion } from '../utils'

export default function pageview(track: Tracker) {
  const spmSet = new Set<string>()
  const beforePage = (page: PageElement) => {
    const spm = page?.__tracker__?.value

    if (spm && spmSet.has(spm)) {
      page.__tracker__.pageId = uidWithVersion()
    }
  }

  const afterPage = (_data: LogObj, page: PageElement) => {
    const spm = page?.__tracker__?.value
    if (!spm) return
    if (!spmSet.has(spm)) {
      spmSet.add(spm)
    }
  }

  track.on(Constants.EventPageView, afterPage)
  track.on(Constants.BeforePageViewData, beforePage)
  track.on(Constants.ConfigChangeEvent, () => {
    track.getDataInfo()
  })
}
