import {PluginCore} from "@klook/apem-core";

export class ApemPluginFetch extends PluginCore {
    monitor() {
        const originalFetch = window.fetch
        const client = this.client
        const logUrl = client.config.logger?.options?.url

        if (originalFetch) {
            window.fetch = function (...args: any) {
                const method = String(args[1]?.method || 'get').toUpperCase()
                const url = String(args[0])
                const isAddBreadcrumb = logUrl && url !== logUrl

                const handlerData = {
                    args,
                    fetchData: {
                        method,
                        url,
                    }
                };

                if (isAddBreadcrumb) {
                    client.breadcrumb.add({
                        ...handlerData,
                        startTimestamp: Date.now(),
                    })
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                return originalFetch.apply(window, args).then(
                    (response: Response) => response,
                    (error: Error) => {
                        if (isAddBreadcrumb) {
                            client.breadcrumb.add({
                                ...handlerData,
                                endTimestamp: Date.now(),
                            })
                        }

                        client.capture({
                            isMasked: true,
                            exception: error,
                            catchBy: 'web:ApemPluginFetch'
                        })

                        throw error;
                    },
                );
            }
        }
    }
}
