import { deepMerge } from './utils'
// import storage from './storage'

type Type = 'info' | 'warn' | 'error'
interface Options {
  debug?: boolean
  prefix?: string
  name?: string
  colors?: {
    info: string
    warn: string
    error: string
  }
}

class Logger {
  options: Options = {
    debug: false,
    prefix: '[Inhouse Tracking]',
    name: '',
    colors: {
      info: '#09f',
      warn: '#f90',
      error: '#f66',
    },
  }

  constructor(options: Options = {}) {
    this.set(options)
  }

  set(options: Options = {}) {
    this.options = deepMerge(this.options, options, {})
    const { name, prefix } = this.options
    if (name) {
      this.options.prefix = prefix + `[${name}]`
    }
  }

  log(type: Type, ...args: any[]) {
    const { debug, prefix, colors } = this.options
    if (debug) {
      const style = colors ? `color: ${colors[type]}` : ''
      if (type === 'warn') {
        console.warn(`%c${prefix} ${type.toUpperCase()}`, style, ...args)
        return
      }
      console.log(`%c${prefix} ${type.toUpperCase()}`, style, ...args)
    }
  }

  info(...args: any[]) {
    this.log('info', ...args)
  }

  warn(...args: any[]) {
    this.log('warn', ...args)
  }

  error(...args: any[]) {
    this.log('error', ...args)
  }
}

export default Logger
