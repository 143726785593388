declare interface EventTarget {
  addCustomEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions
  ): void
  removeEventListeners(targetType: string): void
}

const _listeners: any[] = []

if (typeof EventTarget === 'function') {
  EventTarget.prototype.addCustomEventListener = function(
    type,
    listener,
    options
  ) {
    _listeners.push({ target: this, type: type, listener: listener })
    this.addEventListener(type, listener, options)
  }

  EventTarget.prototype.removeEventListeners = function(targetType) {
    for (let index = 0; index !== _listeners.length; index++) {
      const item = _listeners[index]

      const { target, type, listener } = item

      if (target == this && type == targetType) {
        this.removeEventListener(type, listener, false)
        this.removeEventListener(type, listener, true)
      }
    }
  }
}
