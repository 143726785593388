import db from 'localforage'
import Logger from './logger'

const logger = new Logger()

db.config({
  name: 'klk-inhouse-tracker',
  storeName: 'klk-inhouse-tracker',
  description: '',
})

export const getDbItem = async (key: string) => {
  try {
    return await db.getItem(key)
  } catch (err) {
    logger.warn('localForage getItem error', JSON.stringify(err))
    return null
  }
}

export const setDbItem = async (key: string, value: any) => {
  try {
    await db.setItem(key, value)
  } catch (err) {
    logger.warn('localForage setItem error', JSON.stringify(err))
  }
}

export const removeDbItem = async (key: string) => {
  try {
    await db.removeItem(key)
  } catch (err) {
    logger.warn('localForage removeItem error', JSON.stringify(err))
  }
}

export const getDbKeys = async () => {
  try {
    return await db.keys()
  } catch (err) {
    logger.warn('localForage getKeys error', JSON.stringify(err))
    return []
  }
}
