import Data from '../types'

// 货币符号映射
export const currencySymbolMap: Record<Data.Currency, string> = {
    HKD: 'HK$',
    USD: 'US$',
    TWD: 'NT$',
    SGD: 'S$',
    CNY: '¥',
    AUD: 'AUD',
    GBP: '£',
    LAK: '₭',
    EUR: '€',
    THB: '฿',
    NZD: 'NZ$',
    MYR: 'RM',
    JPY: '¥',
    AED: 'AED',
    PHP: '₱',
    KRW: '₩',
    VND: '₫',
    IDR: 'Rp',
    SEK: 'kr',
    NOK: 'kr',
    DKK: 'kr',
    CHF: 'CHF',
    RUB: '₽',
    TRY: 'YTL',
    ISK: 'kr',
    INR: '₹',
    KHR: 'KHR',
    MMK: 'BUK',
    MOP: 'MOP$',
    QAR: 'QR',
    OMR: 'OMR',
    JOD: 'JOD',
    LBP: 'LBP',
    FJD: '$',
    CAD: 'CAD',
    MUR: 'Rs',
    MXN: '$',
    MAD: 'DH',
    EGP: 'EGP',
    ZAR: 'R',
    MGA: 'MGA',
    ILS: '₪',
    SAR: 'ر.س'
}

// 对比港币汇率，只是一份兜底数据
export const currencyRateMap: Record<string, number> = {
    USDHKD: 7.845045,
    CNYHKD: 1.185581,
    EURHKD: 9.171115,
    GBPHKD: 10.360332,
    AUDHKD: 5.810271,
    SGDHKD: 5.759771,
    TWDHKD: 0.257535,
    THBHKD: 0.237656,
    JPYHKD: 0.070887,
    KRWHKD: 0.007046,
    IDRHKD: 0.000548,
    PHPHKD: 0.147159,
    LAKHKD: 0.000934,
    MYRHKD: 1.942785,
    VNDHKD: 0.000342,
    AEDHKD: 2.13604,
    NZDHKD: 5.310731,
    ISKHKD: 0.074466,
    SEKHKD: 0.877825,
    NOKHKD: 0.963397,
    DKKHKD: 1.230825,
    CHFHKD: 7.921733,
    RUBHKD: 0.124951,
    TRYHKD: 1.710574,
    INRHKD: 0.11461,
    KHRHKD: 0.001947,
    MMKHKD: 0.005568,
    QARHKD: 2.155052,
    OMRHKD: 20.381958,
    JODHKD: 11.072726,
    LBPHKD: 0.005211,
    AFNHKD: 0.107201,
    ALLHKD: 0.072842,
    AMDHKD: 0.016285,
    ANGHKD: 4.304537,
    AOAHKD: 0.03151,
    ARSHKD: 0.271643,
    AWGHKD: 4.407325,
    AZNHKD: 4.616081,
    BAMHKD: 4.68053,
    BBDHKD: 3.922522,
    BDTHKD: 0.09384,
    BGNHKD: 4.676066,
    BHDHKD: 20.751624,
    BIFHKD: 0.00448,
    BMDHKD: 7.845045,
    BNDHKD: 5.817573,
    BOBHKD: 1.143525,
    BRLHKD: 2.026457,
    BSDHKD: 7.845045,
    BTCHKD: 49933.08218,
    BTNHKD: 0.114109,
    BWPHKD: 0.755028,
    BYNHKD: 3.921815,
    BYRHKD: 0.0004,
    BZDHKD: 3.926835,
    CADHKD: 5.972146,
    CDFHKD: 0.005011,
    CLFHKD: 329.163924,
    CLPHKD: 0.012001,
    COPHKD: 0.002678,
    CRCHKD: 0.013891,
    CUCHKD: 7.845045,
    CUPHKD: 0.296039,
    CVEHKD: 0.083122,
    CZKHKD: 0.353125,
    DJFHKD: 0.044196,
    DOPHKD: 0.159032,
    DZDHKD: 0.066866,
    EGPHKD: 0.439735,
    ERNHKD: 0.513742,
    ETBHKD: 0.288315,
    FJDHKD: 3.758635,
    FKPHKD: 10.360547,
    GELHKD: 3.210572,
    GGPHKD: 10.360332,
    GHSHKD: 1.646939,
    GIPHKD: 10.35647,
    GMDHKD: 0.167807,
    GNFHKD: 0.000872,
    GTQHKD: 1.047051,
    GYDHKD: 0.038042,
    HKDHKD: 1,
    HNLHKD: 0.328093,
    HRKHKD: 1.240224,
    HTGHKD: 0.120749,
    HUFHKD: 0.027862,
    ILSHKD: 2.147909,
    IMPHKD: 10.360332,
    IQDHKD: 0.006626,
    IRRHKD: 0.000184,
    JEPHKD: 10.360332,
    JMDHKD: 0.061132,
    KESHKD: 0.077944,
    KGSHKD: 0.115106,
    KMFHKD: 0.01862,
    KPWHKD: 0.008717,
    KWDHKD: 25.942356,
    KYDHKD: 9.562698,
    KZTHKD: 0.022992,
    LKRHKD: 0.049589,
    LRDHKD: 0.05257,
    LSLHKD: 0.569704,
    LTLHKD: 2.573241,
    LVLHKD: 12.642065,
    LYDHKD: 5.711707,
    MADHKD: 0.827667,
    MDLHKD: 0.466216,
    MGAHKD: 0.002399,
    MKDHKD: 0.149371,
    MNTHKD: 0.003202,
    MOPHKD: 0.970872,
    MROHKD: 0.022099,
    MURHKD: 0.227368,
    MVRHKD: 0.503844,
    MWKHKD: 0.010996,
    MXNHKD: 0.394044,
    MZNHKD: 0.133577,
    NADHKD: 0.572197,
    NGNHKD: 0.021975,
    NIOHKD: 0.250878,
    NPRHKD: 0.071742,
    PABHKD: 7.845045,
    PENHKD: 2.391995,
    PGKHKD: 2.420182,
    PKRHKD: 0.064499,
    PLNHKD: 2.096928,
    PYGHKD: 0.001384,
    RONHKD: 1.968442,
    RSDHKD: 0.077648,
    RWFHKD: 0.009236,
    SARHKD: 2.091954,
    SBDHKD: 0.988782,
    SCRHKD: 0.585231,
    SDGHKD: 0.436923,
    SHPHKD: 10.35647,
    SLLHKD: 0.000981,
    SOSHKD: 0.013739,
    SRDHKD: 1.058657,
    STDHKD: 0.000374,
    SVCHKD: 0.896538,
    SYPHKD: 0.015234,
    SZLHKD: 0.572083,
    TJSHKD: 0.856735,
    TMTHKD: 2.300596,
    TNDHKD: 2.993869,
    TOPHKD: 3.387167,
    TTDHKD: 1.159051,
    TZSHKD: 0.003457,
    UAHHKD: 0.298518,
    UGXHKD: 0.002025,
    UYUHKD: 0.250878,
    UZSHKD: 0.000999,
    VEFHKD: 0.000082,
    VUVHKD: 0.072012,
    WSTHKD: 3.012221,
    XAFHKD: 0.013987,
    XAGHKD: 126.326427,
    XAUHKD: 9803.921569,
    XCDHKD: 2.901696,
    XDRHKD: 11.034605,
    XOFHKD: 0.013987,
    XPFHKD: 0.076853,
    YERHKD: 0.031393,
    ZARHKD: 0.5717,
    ZMKHKD: 0.000872,
    ZMWHKD: 0.789211,
    ZWLHKD: 0.024337
}

// 国家码币种映射
export const countryCurrencyMap: Record<Data.Country, Data.Currency> = {
    AU: 'AUD',
    CN: 'CNY',
    GB: 'GBP',
    HK: 'HKD',
    SG: 'SGD',
    TW: 'TWD',
    US: 'USD',
    TH: 'THB',
    JP: 'JPY',
    KR: 'KRW',
    ID: 'IDR',
    PH: 'PHP',
    AT: 'EUR',
    BE: 'EUR',
    BG: 'EUR',
    HR: 'EUR',
    CY: 'EUR',
    CZ: 'EUR',
    EE: 'EUR',
    FI: 'EUR',
    FR: 'EUR',
    DE: 'EUR',
    GR: 'EUR',
    HU: 'EUR',
    IE: 'EUR',
    IT: 'EUR',
    LV: 'EUR',
    LT: 'EUR',
    LU: 'EUR',
    MT: 'EUR',
    NL: 'EUR',
    PL: 'EUR',
    PT: 'EUR',
    RO: 'EUR',
    SK: 'EUR',
    SI: 'EUR',
    ES: 'EUR',
    MO: 'MOP',
    MY: 'MYR',
    MA: 'MAD',
    EG: 'EGP',
    ZA: 'ZAR',
    MG: 'MGA',
    AE: 'AED',
    CA: 'CAD',
    SW: 'CHF',
    DK: 'DKK',
    FJ: 'FJD',
    UK: 'GBP',
    IN: 'INR',
    IS: 'ISK',
    JO: 'JOD',
    KH: 'KHR',
    LA: 'LAK',
    LB: 'EUR',
    // MM: 'MMK',
    MU: 'MUR',
    MX: 'MXN',
    NO: 'NOK',
    NZ: 'NZD',
    OM: 'OMR',
    QA: 'QAR',
    RU: 'EUR',
    SE: 'SEK',
    TR: 'TRY',
    VN: 'VND',
    IL: 'ILS'
}

// 语言币种映射
// 目前只针对搜索引擎使用改映射，特殊配置一个 ar-SA的数据
export const langCurrencyMap: Record<Data.Language | 'ar-SA', Data.Currency> = {
    en: 'USD',
    'en-AU': 'AUD',
    'en-NZ': 'NZD',
    'en-GB': 'GBP',
    'en-IN': 'INR',
    'en-CA': 'CAD',
    'en-SG': 'SGD',
    'en-HK': 'HKD',
    'en-PH': 'PHP',
    'en-US': 'USD',
    'en-MY': 'MYR',
    'ms-MY': 'MYR',
    'zh-HK': 'HKD',
    'zh-TW': 'TWD',
    'zh-CN': 'CNY',
    ko: 'KRW',
    th: 'THB',
    vi: 'VND',
    id: 'IDR',
    ja: 'JPY',
    de: 'EUR',
    it: 'EUR',
    fr: 'EUR',
    ru: 'EUR',
    es: 'EUR',
    'ar-SA': 'SAR'
}

// 推荐货币映射表，主要用于切换货币面板的 UI 展示
// 特殊配置一个 ar-SA的数据
export const suggestCurrencyMap: Record<Data.Language | 'ar-SA', Data.Currency[]> = {
    de: ['EUR', 'GBP', 'USD', 'HKD', 'TWD', 'CNY'],
    en: ['SGD', 'HKD', 'PHP', 'MYR', 'TWD', 'USD', 'CNY', 'KRW', 'ILS'],
    'en-AU': ['AUD', 'SGD', 'HKD', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-CA': ['CAD', 'SGD', 'HKD', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-GB': ['GBP', 'SGD', 'HKD', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-HK': ['HKD', 'TWD', 'SGD', 'USD', 'MYR', 'CNY', 'KRW'],
    'en-IN': ['INR', 'SGD', 'HKD', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-MY': ['MYR', 'PHP', 'HKD', 'SGD', 'TWD', 'USD', 'CNY', 'KRW'],
    'ms-MY': ['MYR', 'PHP', 'HKD', 'SGD', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-NZ': ['NZD', 'SGD', 'HKD', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-PH': ['PHP', 'HKD', 'SGD', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-SG': ['SGD', 'HKD', 'PHP', 'MYR', 'TWD', 'USD', 'CNY', 'KRW'],
    'en-US': ['USD', 'SGD', 'HKD', 'PHP', 'MYR', 'TWD', 'CNY', 'KRW', 'ILS'],
    es: ['EUR', 'GBP', 'USD', 'HKD', 'TWD', 'CNY'],
    fr: ['EUR', 'GBP', 'USD', 'HKD', 'TWD', 'CNY'],
    id: ['IDR', 'HKD', 'SGD', 'USD', 'TWD', 'CNY', 'KRW'],
    it: ['EUR', 'GBP', 'USD', 'HKD', 'TWD', 'CNY'],
    ja: ['JPY', 'HKD', 'SGD', 'USD', 'TWD', 'CNY', 'KRW'],
    ko: ['KRW', 'HKD', 'SGD', 'USD', 'TWD', 'CNY', 'MYR'],
    ru: ['EUR', 'GBP', 'USD', 'HKD', 'TWD', 'CNY'],
    th: ['THB', 'HKD', 'SGD', 'USD', 'TWD', 'CNY', 'MYR', 'KRW'],
    vi: ['VND', 'HKD', 'SGD', 'USD', 'TWD', 'CNY', 'KRW'],
    'zh-CN': ['CNY', 'HKD', 'MYR', 'TWD', 'SGD', 'USD', 'KRW'],
    'zh-HK': ['HKD', 'TWD', 'SGD', 'USD', 'MYR', 'CNY', 'KRW'],
    'zh-TW': ['TWD', 'HKD', 'SGD', 'USD', 'MYR', 'CNY', 'KRW'],
    'ar-SA': ['SAR', 'USD']
}
