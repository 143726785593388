import { isString } from "../utils/utils";
import {PluginCore} from "@klook/apem-core";

export class ApemPluginXHR extends PluginCore {
    monitor() {
        const xhrproto = XMLHttpRequest.prototype;
        const originalOpen = xhrproto.open
        // const originalSend = xhrproto.send
        const client = this.client

        xhrproto.open = function (this, ...args: any): void {
            const xhr = this;
            const url = args[1]
            const xhrInfo = {
                method: isString(args[0]) ? args[0].toUpperCase() : args[0],
                url: args[1],
                status_code: 0,
                startTimestamp: Date.now(),
                endTimestamp: 0
            };

            const onreadystatechangeHandler = function (): void {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    try {
                        xhrInfo.status_code = xhr.status;
                        xhrInfo.endTimestamp = Date.now()
                    } catch (e) {
                        /* do nothing */
                    }

                    const logUrl = client.config.logger?.options?.url
                    if (logUrl && url !== logUrl) {
                        client.breadcrumb.add(xhrInfo)
                    }
                }
            };

            if ('onreadystatechange' in xhr && typeof xhr.onreadystatechange === 'function') {
                const original = xhr.onreadystatechange
                xhr.onreadystatechange = function (...readyStateArgs: any): void {
                    onreadystatechangeHandler();
                    return original.apply(xhr, readyStateArgs);
                }
            } else {
                xhr.addEventListener('readystatechange', onreadystatechangeHandler);
            }

            return originalOpen.apply(xhr, args);
        };

        // xhrproto.send = function (this, ...args: any): void {
        //
        //     this.addEventListener('error', (event) => {
        //         console.log('-------------- event xhr -------------')
        //     })
        //
        //     return originalSend.apply(this, args);
        // };
    }
}
