import { toFuzzy } from './sens';
import {
  deepMerge,
  isArray,
  isFunction,
  isObject,
  isBrowser,
  isNull,
  isStr,
  stringify,
} from './utils';
import { Options, LogObj, Obj, EncodeFn, PostFn } from './types';

export abstract class BaseLogquery {
  private queue: LogObj[] = [];
  private timer: any;
  options: Options = {
    url: '',
    headers: { 'Content-Type': 'application/json' },
    timeout: 15000,
    queue: undefined,
    transform: true,
    beforeRequest: options => options,
    afterResponse: () => {},
  };

  constructor(options: Options = {}) {
    this.set(options);
  }

  set(options: Options) {
    const opts = deepMerge(this.options, options);
    const interval = opts.queue && opts.queue.interval;

    clearInterval(this.timer);

    if (interval) {
      this.timer = setInterval(() => {
        this.pushQueue();
      }, interval);
    }

    this.options = opts;
    return opts;
  }

  protected encode: EncodeFn = () => '';

  protected post: PostFn = () => {};

  private transform(logObj: LogObj, options: Options): LogObj | Obj {
    const logObjCopy = deepMerge({} as LogObj, logObj);
    const { message: _message, type } = logObjCopy;

    logObjCopy.logVersion = logObjCopy.hasOwnProperty('logVersion') ? logObjCopy.logVersion : 1;

    const strMessage = isStr(_message) ? _message : stringify(_message);
    logObjCopy.message = this.encode(type === 0 ? toFuzzy(strMessage) : strMessage);

    if (options.transform) {
      const {
        timestamp = Date.now(),
        message,
        userId,
        requestId,
        logVersion,
        codeVersion,
        ...others
      } = logObjCopy;
      const newLogObj: Obj = {
        time: timestamp,
        msg: message,
        log_ver: logVersion,
        ...others,
      };

      userId && (newLogObj.uid = userId);
      requestId && (newLogObj.req_id = requestId);
      codeVersion && (newLogObj.code_ver = codeVersion);

      return newLogObj;
    }

    return logObjCopy;
  }

  pushQueue() {
    const len = this.queue.length;
    const size = this.options.queue && this.options.queue.size;

    if (size) {
      const num = Math.ceil(len / size);

      for (let i = 0; i < num; i++) {
        this.push(this.queue.splice(0, size), this.options);
      }
    } else {
      this.push(this.queue.splice(0), this.options);
    }
  }

  protected prePush(logObj: LogObj, options: Options = {}) {
    if (!isNull(logObj?.isMasked) && isNull(logObj?.is_masked) && isBrowser()) {
      logObj.is_masked = logObj.isMasked;
      logObj.isMasked = undefined;
    }
    let opts = deepMerge({}, this.options, options);
    if (!Object.keys(options).length) {
      if (opts.queue && opts.queue.interval) {
        this.queue.push(logObj);
        return;
      }
    }

    this.push(logObj, opts);
  }

  push(logObj: LogObj | LogObj[], options: Options) {
    const logs = isArray(logObj) ? logObj : [logObj];
    let opts = deepMerge({}, options);

    if (!logs.length) {
      return;
    }

    // Setting data
    opts.data = {
      logs: logs.map((log: LogObj) => this.transform(log, opts)),
    };

    // Call hook: beforeRequest
    if (isFunction(opts.beforeRequest)) {
      opts = opts.beforeRequest(opts);
    }

    if (!opts.url) {
      console.warn('The parameter URL is required');
      return;
    }

    this.post(opts.url, opts.data, opts, (error: Error | null, data: any): void => {
      // Call hook: afterResponse
      if (isFunction(opts.afterResponse)) {
        opts.afterResponse(error, data);
      }
      this.endCallback(error, data, opts.afterResponse);
    });
  }

  protected endCallback(error: any, data: any, cb?: Function) {
    if (isFunction(cb)) {
      cb(error, data);
    }
  }

  masked() {
    return;
  }

  service(logObj: LogObj, options?: Options) {
    logObj.type = 0;
    this.prePush(logObj, options);
  }

  business(logObj: LogObj, options?: Options) {
    logObj.type = 1;
    this.prePush(logObj, options);
  }

  performance(logObj: LogObj, options?: Options) {
    logObj.type = 2;
    this.prePush(logObj, options);
  }

  optimus(logObj: LogObj, options?: Options) {
    logObj.type = 4;
    this.prePush(logObj, options);
  }

  general(logObj: LogObj, options?: Options) {
    logObj.type = 5;
    if (isObject(logObj.message)) {
      logObj.message.system = logObj.message.system || 'optimus';
      if (!isBrowser()) {
        try {
          logObj.message = stringify(logObj.message);
        } catch (e) {
          console.warn('Stringify message Error', e);
        }
      }
    }
    this.prePush(logObj, options);
  }
}
