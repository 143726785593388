import { IMessage, NSender, IClientCore } from '../../types'
import { PluginCore } from "./core";

export class PluginBizLogger extends PluginCore {
    config!: { subtype: string }
    tags: Map<string, (message: any, extra: Omit<IMessage, 'exception'>) => void> = new Map()

    constructor(config: NSender.IConfig) {
        super();

        this.config = {
            ...config,
            subtype: config.subtype || 'web_biz_log',
        }
    }

    init(client: IClientCore) {
        super.init(client);

        this.client.createLogger = this.createLogger.bind(this)
        this.client.on('PluginCoreSender:beforeSender', DHFMsg => {
            if (DHFMsg.message.message.tag) {
                DHFMsg.message.subtype = this.config.subtype
            }
            return DHFMsg
        })
    }

    createLogger(tag: string) {
        try {
            if (!tag) {
                return (_message: any) => { console.warn('Params error. tag is required') }
            }

            const existed = this.tags[tag]

            if (existed) {
                return existed
            }

            const client = this.client;
            const logger = (message: any, extra: Omit<IMessage, 'exception'> = { level: 'I' }) => {
                try {
                    // const { level } = extra
                    // const key = level === 'E' ? 'exception' : 'message'

                    client.capture({
                        ...extra,
                        // [key]: message,
                        message,
                        tag
                    })
                } catch (e) {
                    console.warn((e as any).message)
                }
            }

            this.tags[tag] = logger

            return logger
        } catch (e) {
            return (_message: any) => { console.warn((e as any).message) }
        }
    }
}
