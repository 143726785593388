//https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach

const supportsPushState =
  typeof window !== 'undefined' &&
  (function() {
    const ua = window.navigator.userAgent

    if (
      (ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) &&
      ua.indexOf('Mobile Safari') !== -1 &&
      ua.indexOf('Chrome') === -1 &&
      ua.indexOf('Windows Phone') === -1
    ) {
      return false
    }

    return window.history && typeof window.history.pushState === 'function'
  })()

export const polyfillNodeList = () => {
  if (window.NodeList && !NodeList.prototype.forEach) {
    // @ts-ignore
    NodeList.prototype.forEach = Array.prototype.forEach
  }

  const history = window.history
  const rawPushState = history.pushState
  const replaceState = history.replaceState

  function getMaybeUrl(c: any) {
    const __in_house = window.__in_house
    let href = c
    if (typeof __in_house !== 'undefined' && typeof c === 'string') {
      try {
        href = __in_house.get_spm_info(c)
      } catch (e) {
        console.log('GetMaybeUrl href error', e)
      }
    }
    return href
  }

  function getNow() {
    return Date.now ? Date.now() : +new Date()
  }
  let latestClickUrl: any = {}

  if (!supportsPushState) return

  window.history.pushState = function(a, b, c) {
    let href = getMaybeUrl(c)
    latestClickUrl = { url: c, href, time: getNow() }

    return rawPushState.call(this, a, b, href)
  }
  window.history.replaceState = function(a, b, c) {
    let href = ''
    if (
      latestClickUrl.url === c &&
      latestClickUrl.time &&
      getNow() - latestClickUrl.time < 10
    ) {
      href = latestClickUrl.href || c
    } else {
      href = getMaybeUrl(c)
    }
    latestClickUrl = {}
    return replaceState.call(this, a, b, href)
  }
}
