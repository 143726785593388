import Data from "../types";

// 语言列表，主要用于切换语言面板的 UI 展示
export interface RenderLanguageItem {
    title: string
    value?: Data.Language
    children?: RenderLanguageItem[]
}

export type RenderLanguages = RenderLanguageItem[]

export const renderLanguages: RenderLanguages = [
    {
        title: 'English (Australia)',
        value: 'en-AU'
    },
    {
        title: 'English (Canada)',
        value: 'en-CA'
    },
    {
        title: 'English (Hong Kong, SAR)',
        value: 'en-HK'
    },
    {
        title: 'English (India)',
        value: 'en-IN'
    },
    {
        title: 'English (Malaysia)',
        value: 'en-MY'
    },
    {
        title: 'English (New Zealand)',
        value: 'en-NZ'
    },
    {
        title: 'English (Philippines)',
        value: 'en-PH'
    },
    {
        title: 'English (Singapore)',
        value: 'en-SG'
    },
    {
        title: 'English (US)',
        value: 'en-US'
    },
    {
        title: 'English (UK)',
        value: 'en-GB'
    },
    {
        title: 'English (International)',
        value: 'en'
    },
    {
        title: '简体中文',
        value: 'zh-CN'
    },
    {
        title: '繁體中文',
        children: [
            {
                title: '香港繁體',
                value: 'zh-HK'
            },
            {
                title: '台灣繁體',
                value: 'zh-TW'
            }
        ]
    },
    {
        title: '日本語',
        value: 'ja'
    },
    {
        title: '한국어',
        value: 'ko'
    },
    {
        title: 'ไทย',
        value: 'th'
    },
    {
        title: 'Tiếng Việt',
        value: 'vi'
    },
    {
        title: 'Bahasa Indonesia',
        value: 'id'
    },
    {
        title: 'Bahasa Malaysia',
        value: 'ms-MY'
    },
    {
        title: 'Français',
        value: 'fr'
    },
    {
        title: 'Español',
        value: 'es'
    },
    {
        title: 'Deutsch',
        value: 'de'
    },
    {
        title: 'Italiano',
        value: 'it'
    },
    {
        title: 'Русский',
        value: 'ru'
    }
]

// 默认支持的语言列表
export const supportLanguageList: Data.SupportLanguageList = [
    'de',
    'en',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-HK',
    'en-IN',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-US',
    'es',
    'fr',
    'id',
    'it',
    'ja',
    'ko',
    'ru',
    'th',
    'vi',
    'zh-CN',
    'zh-HK',
    'zh-TW',
    'ms-MY'
]

// 国家和语言映射表
export type CountryLanguageMap = Record<string, Data.Language>

export const countryLanguageMap: CountryLanguageMap = {
    DE: 'de',
    BS: 'en',
    AU: 'en-AU',
    CA: 'en-CA',
    GB: 'en-GB',
    IN: 'en-IN',
    MY: 'ms-MY',
    NZ: 'en-NZ',
    PH: 'en-PH',
    SG: 'en-SG',
    US: 'en-US',
    ES: 'es',
    FR: 'fr',
    ID: 'id',
    IT: 'it',
    JP: 'ja',
    KR: 'ko',
    RU: 'ru',
    TH: 'th',
    VN: 'vi',
    CN: 'zh-CN',
    HK: 'zh-HK',
    TW: 'zh-TW',
    VI: 'vi'
}

// 需要根据浏览器语言再次判断
export const acceptLanguageMap: Record<string, Record<string, Data.Language>> = {
    'en-MY': {
        ms: 'ms-MY'
    }
}


// 前端 与 后端 的语言代码 Map 表
type F2bLanguageMap = Record<Data.Language, string>
export const f2bLanguageMap: F2bLanguageMap = {
    de: 'de_DE',
    en: 'en_BS',
    'en-AU': 'en_AU',
    'en-CA': 'en_CA',
    'en-GB': 'en_GB',
    'en-HK': 'en_HK',
    'en-IN': 'en_IN',
    'en-MY': 'en_MY',
    'en-NZ': 'en_NZ',
    'en-PH': 'en_PH',
    'en-SG': 'en_SG',
    'en-US': 'en_US',
    es: 'es_ES',
    fr: 'fr_FR',
    id: 'id_ID',
    it: 'it_IT',
    ja: 'ja_JP',
    ko: 'ko_KR',
    ru: 'ru_RU',
    th: 'th_TH',
    vi: 'vi_VN',
    'zh-CN': 'zh_CN',
    'zh-HK': 'zh_HK',
    'zh-TW': 'zh_TW',
    'ms-MY': 'ms_MY'
}

// 语言与 ISO 码 Map 表
type LanguageIsoMap = Record<Data.Language, string>
export const languageIsoMap: LanguageIsoMap = {
    de: 'de',
    en: 'en',
    'en-AU': 'en-AU',
    'en-CA': 'en-CA',
    'en-GB': 'en-GB',
    'en-HK': 'en-HK',
    'en-IN': 'en-IN',
    'en-MY': 'en-MY',
    'ms-MY': 'ms-MY',
    'en-NZ': 'en-NZ',
    'en-PH': 'en-PH',
    'en-SG': 'en-SG',
    'en-US': 'en-US',
    es: 'es',
    fr: 'fr',
    id: 'id',
    it: 'it',
    ja: 'ja',
    ko: 'ko',
    ru: 'ru',
    th: 'th',
    vi: 'vi',
    'zh-CN': 'zh-Hans-CN',
    'zh-HK': 'zh-Hant-HK',
    'zh-TW': 'zh-Hant-TW'
}


// 语言 与 dayjs 的语言 Map 表
export const languageDayjsLocaleMap: Record<Data.Language, string> = {
    de: 'de',
    en: 'en',
    'en-AU': 'en',
    'en-CA': 'en',
    'en-GB': 'en',
    'en-HK': 'en',
    'en-IN': 'en',
    'en-MY': 'en',
    'en-NZ': 'en',
    'en-PH': 'en',
    'en-SG': 'en',
    'en-US': 'en',
    'ms-MY': 'ms-my',
    es: 'es',
    fr: 'fr',
    id: 'id',
    it: 'it',
    ja: 'ja',
    ko: 'ko',
    ru: 'ru',
    th: 'th',
    vi: 'vi',
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-cn',
    'zh-TW': 'zh-cn'
}

// 欧洲? 欧盟 语言
export const erLangCodeList: Data.SupportLanguageList = ['de', 'it', 'fr', 'ru', 'es']


// 语言和偏好地区映射
export const langPreferCountryMap: Record<Data.Language, string[]> = {
    'zh-TW': ['TW', 'HK', 'MO', 'SG', 'CN', 'TH', 'MY', 'AU', 'US', 'KR'],
    'zh-HK': ['HK', 'TW', 'MO', 'SG', 'CN', 'TH', 'MY', 'AU', 'US', 'KR'],
    'zh-CN': ['CN', 'HK', 'SG', 'MY', 'KR', 'US', 'CA', 'AU', 'TH'],
    en: ['DE', 'FR', 'RU', 'NL', 'BE', 'CH', 'SE', 'ES', 'BR', 'AE'],
    'en-US': ['US', 'MX', 'CA', 'BR', 'CO', 'AG', 'PE', 'VE', 'CL', 'KR'],
    'en-AU': ['AU', 'NZ', 'PG', 'SG', 'ID', 'MY', 'IN', 'AE', 'GB', 'US'],
    'en-NZ': ['NZ', 'AU', 'PG', 'SG', 'ID', 'MY', 'IN', 'AE', 'GB', 'US'],
    'en-GB': ['GB', 'DK', 'FI', 'IS', 'NO', 'SE', 'IE', 'BE', 'NL', 'CH'],
    'en-IN': ['IN', 'LK', 'PK', 'BD', 'MY', 'SG', 'NP', 'AE', 'GB', 'US'],
    'en-SG': ['SG', 'MY', 'PH', 'TH', 'ID', 'IN', 'AU', 'HK', 'CA', 'GB'],
    'en-CA': ['CA', 'US', 'HK', 'GB', 'FR', 'LB', 'AE', 'SG', 'PH', 'CN'],
    'en-HK': ['HK', 'TW', 'MO', 'SG', 'CN', 'TH', 'MY', 'AU', 'US', 'KR'],
    'en-PH': ['PH', 'SG', 'MY', 'TH', 'ID', 'IN', 'AU', 'HK', 'CA', 'US'],
    'en-MY': ['MY', 'SG', 'PH', 'TH', 'ID', 'IN', 'AU', 'HK', 'AE', 'GB'],
    'ms-MY': ['MY', 'SG', 'PH', 'TH', 'ID', 'IN', 'AU', 'HK', 'AE', 'GB'],
    ko: ['KR', 'CN', 'JP', 'US', 'TW', 'HK', 'PH', 'SG', 'TH', 'MY'],
    th: ['TH', 'SG', 'HK', 'PH', 'KR', 'MY', 'IN', 'AU', 'ID', 'CA'],
    vi: ['VN', 'SG', 'HK', 'PH', 'KR', 'MY', 'IN', 'AU', 'ID', 'CA'],
    id: ['ID', 'SG', 'HK', 'MY', 'PH', 'TW', 'KR', 'CN', 'TH', 'US'],
    ja: ['JP', 'US', 'GB', 'AU', 'CA', 'SG', 'CN', 'HK', 'TW', 'KR'],
    de: ['DE', 'AU', 'CH', 'BE', 'DK', 'SE', 'GB', 'NL', 'NO', 'IS'],
    it: ['IT', 'AL', 'US', 'CH', 'CA', 'MT', 'BE', 'HR', 'SM', 'MC'],
    fr: ['FR', 'CA', 'BE', 'CH', 'LU', 'SE', 'NL', 'GB', 'MC', 'MA'],
    ru: ['RU', 'UA', 'KZ', 'BY', 'KG', 'EE', 'LV', 'LT', 'IL', 'MD'],
    es: ['ES', 'MX', 'AR', 'CO', 'PE', 'VE', 'CL', 'EC', 'CR', 'PA']
}

// en语言
export const enLangCodeList: Data.SupportLanguageList = ['en', 'en-AU', 'en-CA', 'en-GB', 'en-HK', 'en-IN', 'en-MY', 'en-NZ', 'en-PH', 'en-SG', 'en-US']

// zh语言
export const zhLangCodeList: Data.SupportLanguageList = ['zh-CN', 'zh-TW', 'zh-HK']
