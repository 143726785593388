import { Obj, RequestOptions, RequestCallback } from './types'
import { deepMerge, isObject, isFunction, isString } from './utils'
import { ApplicationJson } from './constants'

const noop: RequestCallback = function() {}

class Request {
  useFetch = true
  options: RequestOptions = {
    timeout: 3000,
    useSendBeacon: false,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  }

  constructor(options: RequestOptions = {}) {
    this.set(options)
  }

  set(options: RequestOptions = {}) {
    this.options = deepMerge(this.options, options)
  }

  /**
   * 发送 POST 请求
   * @param url URL
   * @param data 数据
   * @param options 配置项
   * @param callback 回调函数
   */
  post(
    url: string,
    data: Obj | string,
    options: RequestOptions = {},
    callback: RequestCallback = noop
  ) {
    const opts = deepMerge(this.options, options)
    let body = ''

    // 如果是非 string 类型，帮忙 stringify 一下
    try {
      body = typeof data === 'string' ? data : JSON.stringify(data)
    } catch (error) {
      callback(error as Error)
      return
    }

    // 如果启用 sendBeacon 且浏览器支持
    const beaconUrl = isString(opts.beacon) ? opts.beacon : opts.beacon?.url
    if (beaconUrl && isFunction(window.navigator.sendBeacon)) {
      const cb = (err: any, data: any) => {
        if (err) {
          // using xhr retry to send data
          return this.fetchOrXhr(url, body, opts, callback)
        }
        return isFunction(callback) ? callback(err, data) : callback
      }
      this._sendBeacon(beaconUrl, body, opts, cb)
      return
    }

    this.fetchOrXhr(url, body, opts, callback)
  }

  fetchOrXhr(
    url: string,
    body: string,
    opts: RequestOptions = {},
    callback: RequestCallback = noop
  ) {
    // if (this.useFetch) {
    //   const cb = (...args: any[]) => {
    //     if (args[0]) {
    //       this.useFetch = false
    //     }
    //     return isFunction(callback) ? callback.apply(this, args as any) : null
    //   }
    //   this.fetch(url, body, opts, cb)
    // } else {
    this.sendWithXhr(url, body, opts, callback)
    // }
  }

  sendWithXhr(
    url: string,
    body: string,
    options: RequestOptions = {},
    callback: RequestCallback = noop
  ) {
    const headers = isObject(options.headers) ? options.headers! : {}

    // 发起 XMLHttpRequest 请求
    const xhr = new XMLHttpRequest()

    xhr.open('POST', url, true)

    // 设置 HTTP Headers
    Object.keys(headers).forEach(key => {
      headers[key] && xhr.setRequestHeader(key, headers[key])
    })

    xhr.withCredentials = !!options.withCredentials
    xhr.timeout =
      options.timeout && options.timeout > 0 ? options.timeout : 30000

    xhr.addEventListener('load', function() {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            callback(null, JSON.parse(xhr.responseText))
          } catch (error) {
            callback(error as Error, xhr.responseText)
          }
        } else {
          callback(new Error(xhr.statusText))
        }
      }
    })

    xhr.addEventListener('abort', function() {
      callback(new Error(`Request abort`))
    })

    xhr.ontimeout = function() {
      callback(new Error(`Request timeout`))
    }

    xhr.addEventListener('error', function() {
      callback(new Error(`An error occurred`))
    })

    xhr.send(body)
  }

  /**
   * 使用 sendBeacon 发送数据
   * @NOTE: 注意，sendBeacon 不支持自定义 HTTP Headers
   * Ref: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
   * @param url URL
   * @param data 数据
   * @param options 配置项
   * @param callback 回调函数
   */
  private _sendBeacon(
    url: string,
    data: string,
    options: RequestOptions,
    callback: RequestCallback
  ) {
    try {
      const headers = options.headers || {}
      const blob = new Blob([data], { type: ApplicationJson })
      const platform = headers.platform || headers['X-Platform'] || 'desktop'
      const corsUrl = this.normalizeUrl(url, platform)

      if (window.navigator.sendBeacon) {
        const result = window.navigator.sendBeacon(corsUrl, blob)
        callback(result ? null : new Error('Request failed in sendBeacon()'))
      } else {
        callback(new Error('Not support sendBeacon()'))
      }
    } catch (error) {
      callback(error as Error)
    }
  }

  normalizeUrl(url: string, platform: string) {
    const urlArr = url.split('?')
    urlArr[1] = [`platform=${platform}`, urlArr[1]].filter(Boolean).join('&')
    return urlArr.join('?')
  }

  fetch(
    url: string,
    data: string,
    options: RequestOptions,
    callback: RequestCallback = noop
  ) {
    try {
      window
        .fetch(this.normalizeUrl(url, options.headers?.platform || 'desktop'), {
          keepalive: true,
          headers: { 'content-type': ApplicationJson },
          body: new Blob([data], { type: ApplicationJson }),
          method: 'POST',
          credentials: 'omit',
        })
        .then(res => {
          return res.json()
        })
        .then(data => {
          if (data.success) {
            callback(null)
          } else {
            callback(new Error(data.error?.message || 'api error'))
          }
        })
        .catch(e => callback(e))
    } catch (e) {
      callback(e as Error)
    }
  }
}

export default Request
