import { NBreadcrumb } from '../../types/breadcrumb'
import {IClientCore, IMessage} from "@klook/apem-core/types";
import { htmlTreeAsString } from "../utils/utils";
import { PluginCore } from "@klook/apem-core";

export class ApemPluginBreadcrumb extends PluginCore {
    config!: NBreadcrumb.IConfig
    client!: IClientCore
    breadcrumb = []

    constructor(config?: NBreadcrumb.IConfig) {
        super();

        this.config = {
            length: 3,
            ...config
        }
    }

    handleDomEvent({ event, name }: { event: Event, name: string }) {
        let target;
        try {
            target = event.target
                ? htmlTreeAsString(event.target as HTMLElement)
                : htmlTreeAsString(event as unknown as HTMLElement);
        } catch (e) {
            target = '<unknown>';
        }

        if (target) {
            this.add({
                name,
                message: target,
            })
        }
    }

    monitor() {
        // todo 补充一个路由监听
        document.addEventListener('click', (event) => this.handleDomEvent({ event, name: 'click' }))
        document.addEventListener('keypress', (event) => this.handleDomEvent({ event, name: 'keypress' }))
    }

    init(client: NBreadcrumb.IBreadcrumbClient) {
        super.init(client)

        client.breadcrumb = this
        const { length } = this.config
        // const logUrl = client.config.logger.options?.url
        //
        // if (logUrl) {
        //     this.config.exclude = (item: any) => {
        //         const { url  } = item
        //         if (!url) {
        //             return true
        //         }
        //
        //         return url !== logUrl
        //     }
        // }

        client.on('build', (message: IMessage) => {
            const messageBreadcrumb = message.trace
            const currentTrace = this.breadcrumb.slice(0, length)
            const msg = {
                ...message,
                trace: messageBreadcrumb ? messageBreadcrumb.concat(currentTrace) : currentTrace
            }

            this.clean()

            return msg
        })
    }

    add(item: any) {
        let items = Array.isArray(item) ? item : [item]

        const exclude = this.config.exclude
        if (typeof exclude === "function") {
            items = items.filter(exclude)
        }

        this.breadcrumb = items.concat(this.breadcrumb)
    }

    clean() {
        this.breadcrumb = []
    }
}
