import { IMessage, NSender } from '../../types'
import loguery, {LogLevel} from '@klook/logquery'
import { PluginCore } from "./core";
import { Platfrom } from "@klook/logquery/dist/types";

const LOGQUERY = Symbol('Context#device#deviceInfo');

export class PluginCoreSender extends PluginCore {
    config!: NSender.IConfig

    constructor(config: NSender.IConfig) {
        super();

        this.config = {
            system: 'optimus',
            ...config,
            subtype: config.subtype || 'web_crash_log',
        }
    }

    getLogquery(message: IMessage) {
        if (this[LOGQUERY]) {
            return this[LOGQUERY];
        }

        const logOption = this.config.options || {}

        const platform = message.platform

        this[LOGQUERY] = new loguery({
            queue: { interval: 5000, size: 15 },
            headers: {
                'X-Platform': platform as Platfrom
            },
            ...logOption
        })

        return this[LOGQUERY]
    }

    send(message: IMessage) {
        const sender = this.getLogquery(message)

        const { subtype, system } = this.config

        if (!subtype) {
            return
        }

        const msg = {
            system,
            subtype,
            message
        }

        let DHFMessage = {
            level: message.level as LogLevel,
            message: msg,
            is_masked: message.isMasked ?? false,
            timestamp: Date.now()
        }

        DHFMessage = this.client.emit('PluginCoreSender:beforeSender', DHFMessage)

        if (this.client.config._debug) {
            console.log('message send to DHF: ', DHFMessage)
        }

        sender.general(DHFMessage)
    }
}
