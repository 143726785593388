export function getCookie(name: string) {
    const cookieArr = document.cookie.split(";");

    // 循环遍历数组元素
    for(let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if(name == cookiePair[0].trim()) {
            // 解码cookie值并返回
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}
