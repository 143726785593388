import intersection from 'lodash/intersection'
import { getWebsiteConfig } from '../site/index'
import {
    renderLanguages,
    supportLanguageList,
    countryLanguageMap,
    f2bLanguageMap,
    languageIsoMap,
    languageDayjsLocaleMap,
    erLangCodeList,
    langPreferCountryMap,
    acceptLanguageMap,
    enLangCodeList,
    zhLangCodeList
} from './config'
import Data from '../types'

/**
 * 获取当前站点支持语言
 * @param website
 */
export function getSupportLanguageList(website: string): Data.SupportLanguageList {
    return getWebsiteConfig(website).support_languages
}


/**
 * 判断当前站点是否支持该语言
 * @param language
 * @param webSite
 */
export function isSupportLanguage(language: Data.Language | undefined, webSite: string) {
    if (!language) {
        return false
    }

    return getSupportLanguageList(webSite).includes(language)
}

/**
 * 获取站点语言列表，header选择语言使用
 * @param webSite
 * @param supportLanguages
 */
export function getRenderLanguages(
    webSite: string,
    supportLanguages: Data.SupportLanguageList = supportLanguageList
) {
    const commonSupportLanguages = intersection(supportLanguages, getSupportLanguageList(webSite))
    return renderLanguages.filter((item) => {
        if (Array.isArray(item.children)) {
            item.children = item.children.filter((childItem) => {
                return childItem.value && commonSupportLanguages.includes(childItem.value)
            })
            return item.children.some((child) => {
                if (child.value) {
                    return commonSupportLanguages.includes(child.value)
                } else {
                    return false
                }
            })
        }

        if (item.value) {
            return commonSupportLanguages.includes(item.value)
        } else {
            return false
        }
    })
}


/**
 * 通过Country Code 获取推荐语言
 * @param countryCode
 */
export function getLanguageByCountry(
    countryCode: Data.Country
): string | undefined {
    return countryLanguageMap[countryCode]
}

/**
 * 通过 countryCode + AcceptLang detect 当前的语言
 * @param countryCode
 * @param acceptLangs
 */
export function getLanguageByCountryCodeAndAcceptLang(
    countryCode: Data.Country,
    acceptLangs: string[]
): Data.Language {
    const countryLang = countryLanguageMap[countryCode]

    // 是否有 accept lang 配置
    const curCountryLangConf = acceptLanguageMap[countryLang]
    if (!curCountryLangConf) return countryLang

    // 根据 accept lang 再次匹配
    for (const acceptLang of acceptLangs) {
         if (curCountryLangConf[acceptLang]) {
             return curCountryLangConf[acceptLang]
         }
    }

    return countryLang
}


/**
 * 前端语言转后端语言
 * @param lang
 */
export const convertToBackendCode = (lang: Data.Language) => {
    return f2bLanguageMap[lang]
}

/**
 * 后端语言转前端语言
 * @param lang
 */
export const convertToFrontendCode = (lang: string): Data.Language => {
    let resultLang: Data.Language = 'en'
    Object.keys(f2bLanguageMap).some((key) => {
        const langKey = key as Data.Language
        if (f2bLanguageMap[langKey] === lang) {
            resultLang = langKey
            return true
        }
        return false
    })

    return resultLang
}

/**
 * 是否是欧洲地区语言
 * @param langCode
 */
export function isErLang(langCode: Data.Language) {
    return erLangCodeList.includes(langCode)
}

/**
 * 获取语言偏好地区list，用于一些区号选择的推荐等
 * @param lang
 */
export const getPreferCountryListByLangCode = (lang: Data.Language) => {
    return langPreferCountryMap[lang]
}

/**
 * 获取国家偏好地区list，用于一些区号选择的推荐等
 * @param country
 */
export function getPreferCountryListByCountryCode(country: string) {
    const lang = countryLanguageMap[country] || 'en'

    return getPreferCountryListByLangCode(lang)
}

export {
    /**
     * 渲染语言列表树，二维
     */
    renderLanguages,

    /**
     * 默认支持所有的语言列表
     */
    supportLanguageList,

    /**
     * 语言与 ISO 码 Map 表
     */
    languageIsoMap,

    /**
     * 语言 与 dayjs 的语言 Map 表
     */
    languageDayjsLocaleMap,


    /**
     * 国家语言映射
     */
    countryLanguageMap,

    /**
     * 前后端语言映射表
     */
    f2bLanguageMap,

    /**
     * en语言
     */
    enLangCodeList,

    /**
     * zh语言
     */
    zhLangCodeList,
}


