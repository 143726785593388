import { PluginCore } from "@klook/apem-core";

export class ApemPluginGlobal extends PluginCore {
    handleError(event: ErrorEvent | PromiseRejectionEvent) {
        const exception = 'error' in event ? event.error : event.reason
        this.client.capture({
            isMasked: true,
            exception,
            catchBy: `web:ApemPluginGlobal:${'error' in event ? 'error' : 'unhandledrejection'}`
        })
    }

    monitor() {
        window.addEventListener('error', this.handleError.bind(this))
        window.addEventListener('unhandledrejection', this.handleError.bind(this))
    }

    destroy() {
        window.removeEventListener('error', this.handleError)
        window.removeEventListener('unhandledrejection', this.handleError)
    }
}
