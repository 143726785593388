import { EncodeFn } from '../types';

/**
 * 小程序不支持 btoa 函数，对base64进行补充
 * 对字符串进行base64编码
 * @param str 进行编码字符串
 */
function btoa(str: string) {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let bitmap,
    a,
    b,
    c,
    result = '',
    i = 0,
    rest = str.length % 3; // 计算余数，得知"="需要补充几位

  for (; i < str.length; ) {
    a = str.charCodeAt(i++);
    b = str.charCodeAt(i++);
    c = str.charCodeAt(i++);
    if (a > 255 || b > 255 || c > 255) {
      throw new TypeError(
        'The string to be encoded contains characters outside of the Latin1 range.'
      );
    }

    bitmap = (a << 16) | (b << 8) | c;
    result +=
      CHARS.charAt((bitmap >> 18) & 63) +
      CHARS.charAt((bitmap >> 12) & 63) +
      CHARS.charAt((bitmap >> 6) & 63) +
      CHARS.charAt(bitmap & 63);
  }

  // 补全字符串后面“=”
  return rest ? result.slice(0, rest - 3) + '==='.substring(rest) : result;
}

export const encode: EncodeFn = str => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(Number(`0x${p1}`))
    )
  );
};
