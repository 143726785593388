// @ts-nocheck
import VueI18n from './index'
export default {
  beforeCreate(): void {
    const options: any = this.$options

    if (options.i18n) {
      if (options.i18n instanceof VueI18n) {
        // init locale messages via custom blocks
        this._i18n = options.i18n
      } else {
        if (process.env.NODE_ENV !== 'production') {
          console.warn('[vue-i18n] ' + `Cannot be interpreted 'i18n' option.`)
        }
      }
    } else if (
      this.$root &&
      this.$root.$i18n &&
      this.$root.$i18n instanceof VueI18n
    ) {
      // root i18n
      this._i18n = this.$root.$i18n
    } else if (
      options.parent &&
      options.parent.$i18n &&
      options.parent.$i18n instanceof VueI18n
    ) {
      // parent i18n
      this._i18n = options.parent.$i18n
    }
  },
}
