import { PostFn } from '../types';

// 请求依赖 uni-app框架，兼容三个小程序发送请求
export const post: PostFn = (url, data, options, callback) => {
  // @ts-ignore
  uni
    .request({
      url: url,
      data: data,
      method: 'POST',
      header: options.headers,
    })
    .then((result: any) => {
      const [error, resp] = result;

      if (error) {
        callback(new Error(JSON.stringify(error)));
        return;
      }
      if (+resp.statusCode === 200) {
        try {
          callback(null, JSON.parse(resp));
        } catch (err) {
          callback(err, resp);
        }
      }
    })
    .catch((err: any) => {
      callback(err, err);
    });
};
