import EventEmitter from 'eventemitter3'
import { Payload, Partial } from './types'
// import throttle from 'lodash.throttle'
import {
  deepMerge,
  json2string,
  isSearchBot,
  isObject,
  transformObj,
} from './utils'
import Request from './request'
import Logger from './logger'
import { requestQueueOptions } from './types'
import TrackerCache from './cache'
import { Tracker } from './tracker'
import * as Constants from './constants'

type MixPayload = Payload | Payload[]

class RequestQueue extends EventEmitter {
  private _queue: Payload[] = []
  private haveChance: Boolean = true
  private queueingSet = new Set<MixPayload>()
  private _request: Request
  private _logger: Logger
  private concurrent: number = 0
  private serverFailCount: number = 0

  private innerState: Map<string, any> = new Map()

  private debug: boolean = false

  private _instance?: Tracker

  private timeId: any

  options: requestQueueOptions = {
    url: '',
    size: 5,
    retry: 5,
    headers: {},
    platform: '',
    deviceId: '',
    timeout: 15000,
    concurrent: 3,
    throttleTime: 16,
    maxQueueSize: 50,
    localCacheKey: 'inhouse:RequestQueue:local',
  }

  constructor(options?: Partial<requestQueueOptions>, instance?: Tracker) {
    super()
    this.setOptions(options)

    this._request = new Request({})
    this._logger = new Logger({ name: 'RequestQueue', debug: options?.debug })
    this._instance = instance
    this.debug = Boolean(options?.debug)

    this.bindEvent()
  }

  emit<T extends string | symbol>(event: T, ...args: any[]): boolean {
    this._instance!.emit(event, ...args)
    return super.emit(event, ...args)
  }

  private bindEvent() {
    this.on('request:before', () => {
      this.concurrent++
    })

    this.on('request:success', () => {
      const {
        options: { size = 5 },
      } = this
      this.serverFailCount = 0

      this.options.size = size < 10 ? size + 1 : 5
    })

    this.on('request:fail', () => {
      const {
        options: { size = 5 },
      } = this
      this.serverFailCount++
      this.options.size = size > 2 ? size - 2 : 1
    })

    this.on('request:finish', () => {
      this.concurrent--

      const serverFailCount = this.serverFailCount
      if (serverFailCount >= 2) {
        this.options.concurrent = 1
      } else {
        this.options.concurrent = 4
      }
      this.getDataFromCache()
    })

    this.on('queue:empty', () => {
      this.getDataFromCache()
    })

    this.on('page:unload', () => {
      this.pageWillUnload()
    })
  }

  private getDataFromCache() {
    // 避免同步读取影响页面渲染

    if (!this.isOnline()) return
    const {
      innerState,
      options: { localCacheKey },
    } = this
    const key = localCacheKey || 'hasLocalData'

    if (!innerState.has(key)) {
      this.processNextTick(() => {
        let payloads = TrackerCache.getItem(localCacheKey!)

        if (payloads) {
          this.sendData(payloads)
          TrackerCache.removeItem(localCacheKey!)
        } else {
          innerState.set(key, false)
        }
      })
    }

    if (this.serverFailCount > 7) return

    const processingKey = 'isItemProcessing'
    if (innerState.has(processingKey)) return
    innerState.set(processingKey, true)
    const deTimeout = this.concurrent > 1 ? 1 : 0.2
    const timeout =
      this.serverFailCount < 10
        ? 1000 * (this.serverFailCount || deTimeout)
        : 10000

    clearTimeout(this.timeId)
    this.timeId = this.processNextTick(async () => {
      this.getItems()
      innerState.delete(processingKey)
    }, timeout)
  }

  private processNextTick(cb: () => any, timeout = 500) {
    return setTimeout(() => {
      cb.call(this)
    }, timeout)
  }

  private async getItems() {
    const { _queue } = this

    if (!this.haveChanceFlag()) return
    if (_queue.length > 0) {
      this.throttleRequest()
      return
    }

    return TrackerCache.getItemAsync()
      .then(async (res: any) => {
        if (res && res.key && res.value) {
          const { key, value } = res
          const payloads = Array.isArray(value) ? value : [value]

          await TrackerCache.removeItemAsync(key)
          this.pushQueue(payloads)
        }
      })
      .catch(e => {
        this._logger.error(e)
      })
  }

  setOptions(configs?: Partial<requestQueueOptions>) {
    if (!isObject(configs)) return
    const filterConfig = transformObj(configs || {})
    const { options } = this
    this.options = {
      ...options,
      ...filterConfig,
    }
  }

  private isOnline() {
    return navigator && navigator.onLine !== undefined ? navigator.onLine : true
  }

  private haveChanceFlag() {
    let {
      haveChance,
      concurrent,
      options: { concurrent: maxConcurrent },
    } = this
    maxConcurrent = maxConcurrent || 5

    if (concurrent > maxConcurrent) {
      this.concurrent--
    }

    const hasUrl =
      this.options.url || this._instance?.options?.url || this.useBeacon()

    const online = this.isOnline()
    return haveChance && concurrent < maxConcurrent && online && hasUrl
  }

  private uuid() {
    return String(Date.now() + Math.random())
  }

  pushQueue(logs: Payload[]) {
    const {
      _queue: queue,
      queueingSet,
      concurrent,
      serverFailCount,
      options: { maxQueueSize, concurrent: maxConcurrent = 5 },
    } = this
    const haveChance = this.haveChanceFlag()
    if (this._queue.length > maxQueueSize || !haveChance) {
      if (
        concurrent >= maxConcurrent &&
        serverFailCount < 1 &&
        this._queue.length <= maxQueueSize / 2
      ) {
        this._queue = queue.concat(logs).filter(Boolean)
        return
      }

      if (concurrent < 1 && this._queue.length > 0 && serverFailCount > 1) {
        this.serverFailCount--
      }

      // cache data
      queueingSet.add(logs)
      this._logger.info('Cache to indexdb', logs)
      this.setLogs2Idb(this.uuid(), logs)
        .then(() => {
          queueingSet.delete(logs)
        })
        .catch(e => {
          this._logger.error(e)
        })
    } else {
      this._queue = queue.concat(logs).filter(Boolean)
    }
    this.throttleRequest()
  }

  private setLogs2Idb(key: string, logs: Payload[]) {
    const payloads: (Payload & { rty: number })[] = []
    const { retry = 5 } = this.options

    logs.forEach(i => {
      const rty = ((i as any).rty | 0) + 1
      if (rty <= retry) {
        payloads.push({ ...i, rty })
      }
    })

    return payloads.length
      ? TrackerCache.setItemAsync(key, payloads)
      : Promise.resolve()
  }

  /**
   * 发送数据接口
   * @param logs MixPayload
   */
  sendData(logs: MixPayload) {
    this.pushQueue(Array.isArray(logs) ? logs : [logs])
  }

  public pageWillUnload() {
    const payloads = this.getPayloads()
    const {
      options: { localCacheKey },
    } = this
    this._logger.info('cache to local', payloads)
    if (payloads.length && localCacheKey) {
      let localData = TrackerCache.getItem(localCacheKey!)
      localData = Array.isArray(localData) ? localData : []
      localData = localData.concat(payloads).slice(0, 100)
      TrackerCache.setItem(localCacheKey, localData)
      this.emptyCurrentQueue()
    }
  }

  private emptyCurrentQueue() {
    this._queue.length = 0
    this.queueingSet.clear()
  }

  public getPayloads() {
    const { _queue: queue, queueingSet } = this
    let payloads: Payload[] = queue.slice()
    const queueingArr = Array.from(queueingSet)
      .filter(Boolean)
      .reduce((a, b) => {
        return (a as Payload[]).concat(b)
      }, [])

    payloads = payloads.concat(queueingArr)
    return payloads
  }

  flushQueue() {
    this.throttleRequest()
  }

  private hasListenerCount(keys: string[]) {
    return keys.some(k => k && this._instance?.listenerCount(k))
  }

  private throttleRequest() {
    const {
      _queue: queue,
      queueingSet,
      options: { size },
    } = this
    const haveChance = this.haveChanceFlag()
    if (!haveChance) return
    if (!queue.length) {
      this.emit('queue:empty')
      return
    }

    const payloads = queue.splice(0, size)
    queueingSet.add(payloads)
    this.emit('request:before', { payloads })
    this.send(payloads, (error, resp) => {
      const callbackData = {
        payloads,
        error,
        raw: payloads,
        success: !error,
      }
      let decodeData
      const decodePayload = (payloads: Payload[]) => {
        return payloads.map(i => {
          let msg = i.msg
          try {
            msg = JSON.parse(atob(i.msg))
          } catch (_e) {}
          return { ...i, msg: msg }
        })
      }
      if (
        this.hasListenerCount([
          Constants.RequestSuccess,
          Constants.RequestFinish,
          Constants.RequestFail,
        ])
      ) {
        decodeData = decodePayload(payloads)
        callbackData.payloads = decodeData
      }
      if (!error) {
        queueingSet.delete(payloads)
        this.emit(Constants.RequestSuccess, callbackData)
        try {
          if (this.debug) {
            this._logger.info(
              'success data: ',
              decodeData || decodePayload(payloads)
            )
          }
        } catch (e) {
          this._logger.error(e)
        }
        if (isObject(resp) && !(resp as any).success) {
          this._logger.warn('server response: ', resp)
        }
      } else {
        this.emit(Constants.RequestFail, callbackData)
        this.setLogs2Idb(this.uuid(), payloads).then(() => {
          queueingSet.delete(payloads)
        })
        this._logger.warn('request Error: ', error)
      }
      this.emit(Constants.RequestFinish, callbackData)
    })
  }

  public useBeacon() {
    const useSendBeacon = this._instance?.options.beacon
    const reqBeacon = this.options.beacon
    return useSendBeacon || reqBeacon
  }

  private send(
    payloads: MixPayload,
    callback: (error?: any, resp?: any) => void = () => {}
  ) {
    const { timeout, headers, platform, deviceId } = this.options
    if (isSearchBot() || !payloads) {
      callback()
      return
    }

    if (!deviceId) {
      this._logger.warn('Lack Of deviceId', deviceId)
    }
    let xDeviceId = deviceId || this._instance?.getDeviceId()
    if (!xDeviceId) {
      const payload = Array.isArray(payloads) ? payloads[0] : payloads
      xDeviceId = (payload && payload.deviceId) || ''
      this.options.deviceId = xDeviceId
    }
    const headerOptions = deepMerge(headers, {
      'X-Platform': platform || 'desktop',
      'X-DeviceId': xDeviceId,
    })

    const url = this.options.url || this._instance?.options?.url
    const useSendBeacon = this.useBeacon()

    if (!url && !useSendBeacon) {
      callback(new Error('Url not be empty'))
      return
    }

    this.emit('request:beforeSend', { headers: headerOptions, payloads })
    this._request.post(
      url!,
      json2string({ logs: payloads }),
      {
        beacon: useSendBeacon,
        useSendBeacon: !!useSendBeacon,
        timeout,
        headers: headerOptions,
      },
      (error, resp) => {
        callback(error, resp)
        if (error) {
          this._logger.error('inhouse tracker xhr error', error)
        }
      }
    )
  }
}

export default RequestQueue
