type ErrorCode = number // 0 | 100 | 200

// errcode 100 not supported

export interface IhtErrorOptions {
  name?: string
  message?: string
  options?: {
    cause?: unknown
  }
  errcode?: ErrorCode
  stack?: string
}

export class IhtError extends Error {
  errcode?: ErrorCode = 0
  errmsg?: string

  constructor(options?: IhtErrorOptions) {
    super(options?.message)
    this.errcode = options?.errcode
    if (options?.stack) {
      this.stack = options.stack
    }

    this.name = options?.name || 'IhtError'
  }
}
