export function onPageLoaded(fn: Function) {
    if (document.readyState === 'complete') {
        fn()
    } else {
        window.addEventListener('load', fn as any, false)
    }
}

export function getDomain() {
    return document.domain || location.hostname
}

export function isBrowser() {
    return typeof document === 'object' && typeof window === 'object'
}

/**
 * 获取数据的字节长度
 * Ref: https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript
 * @param data 数据
 */
export function getContentLength(data: string) {
    const m = encodeURIComponent(data).match(/%[89ABab]/g)
    return data.length + (m ? m.length : 0)
}

export function onPageSsrLoaded(vue, fn: Function) {
    const originalMounted = vue.mounted
    vue.mounted = function () {
        originalMounted && originalMounted.call(this)
        fn()
    }
}

export function convertHighResTimeToInt(timing: DOMHighResTimeStamp) {
    return Math.round(timing)
}

export function formatToThreeDecimals(value: number) {
    return +(Number(value).toFixed(3));
}

export function generateRandomNumber(t = 21) {
    try {
        return crypto.getRandomValues(new Uint8Array(t)).reduce(function (t, e) {
            return t += (e &= 63) < 36 ? e.toString(36) : e < 62 ? (e - 26).toString(36).toUpperCase() : e > 62 ? "-" : "_";
        }, "");
    } catch (e) {
        ""
    }
}
