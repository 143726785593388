import { encode } from './base64';
import { post } from './request';
import { BaseLogquery } from '../share';
import { EncodeFn, PostFn } from '../types';
import MiniProgramLogquery from '../mini-program';

// Export types
export * from '../types';

class Logquery extends BaseLogquery {
  protected encode: EncodeFn = encode;
  protected post: PostFn = post;
}

export default Logquery;
export { MiniProgramLogquery };

export { encode };
export * from '../sens';
export * from '../utils';
