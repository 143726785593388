import { IClientCore } from "../../types";
import { IPluginCore } from "../../types";

export class PluginCore implements IPluginCore {
    client!: IClientCore
    config!: any

    constructor(config?: any) {
        this.config = config || {}
    }

    init(client: IClientCore) {
        this.client = client
    }
}
