import extend from './extend'
import mixin from './mixin'

export let installedVue: any

export function install(_Vue: any) {
  // @ts-ignore
  install.installed = true

  installedVue = _Vue

  extend(installedVue)
  installedVue.mixin(mixin)

  // use simple mergeStrategies to prevent i18n instance lose '__proto__'
  const strats = installedVue.config.optionMergeStrategies
  strats.i18n = function(parentVal: any, childVal: any) {
    return childVal === undefined ? parentVal : childVal
  }
}
