import {IPluginCore, IClientCore, IConfig, IMessage, IFullMessage} from "../../types";
import EventEmitter from "../utils/eventmitter";

export class ClientCore extends EventEmitter implements IClientCore {
    config: IConfig
    service!: string

    constructor() {
        super()
    }

    traversePlugin(prop: keyof Omit<IPluginCore, 'client'>, plugins?: IPluginCore[]) {
        try {
            this.getPluginsByDef(prop, plugins).forEach(p => {
                const func = p[prop]
                if (typeof func === "function") {
                    func.call(p, this)
                }
            })
        } catch (_e) {
            // ignore
        }
    }

    getPluginsByDef(prop: keyof Omit<IPluginCore, 'client'>, plugins?: IPluginCore[]) {
        try {
            return (plugins || this.config.plugins || [])
                .filter(v => v[prop])
        } catch (_e) {
            return []
        }
    }

    capture(_message: IMessage) {
        console.error('Please implement the breadcrumb plugin function!')
    }

    breadcrumb = {
        add(_item: any) {
            console.error('Please implement the breadcrumb plugin function!')
        }
    }

    createLogger(_tag: string) {
        console.error('Please implement the breadcrumb plugin function!')
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return (_message: IFullMessage['exception'], _extra?: Omit<IMessage, 'exception'>) => {}
    }
}
