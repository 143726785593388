import { Obj } from './types'
import { isObject } from './utils'

let _isChecked: boolean | null = null
const isSupported = function(
  storage: Storage | null = window.localStorage,
  forceCheck: boolean = false
) {
  if (_isChecked !== null && !forceCheck) {
    return _isChecked
  }

  let supported = true
  try {
    const key = '__inhouse:test'
    const val = 'abc'

    storage = storage || window.localStorage
    storage.setItem(key, val)
    if (storage.getItem(key) !== val) {
      supported = false
    }
    storage.removeItem(key)
  } catch (err) {
    console.error('localStorage unsupported')
    supported = false
  }

  _isChecked = supported
  return supported
}

const prefix = '__inhouse:'

const local = {
  get(key: string) {
    try {
      key = prefix + key
      const value = window.localStorage.getItem(key)
      if (value === null) {
        return value
      }

      const data = JSON.parse(value)
      if (!isObject<Obj>(data)) {
        return data
      }
      if (data.expires && Date.now() > data.expires) {
        local.remove(key)
        return null
      }
      return data.value
    } catch (error) {
      console.error(error)
    }
    return null
  },

  getRaw(key: string) {
    try {
      key = prefix + key
      const value = window.localStorage.getItem(key)
      if (value === null) {
        return value
      }

      const data = JSON.parse(value)
      if (!isObject<Obj>(data)) {
        return data
      }
      return data.value
    } catch (error) {
      console.error(error)
    }
    return null
  },

  set(key: string, value: any, maxAge?: number) {
    try {
      key = prefix + key
      const data: any = { value }
      maxAge && (data.expires = Date.now() + maxAge)
      window.localStorage.setItem(key, JSON.stringify(data))
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },

  remove(key: string) {
    try {
      key = prefix + key
      window.localStorage.removeItem(key)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
}

const session = {
  get(key: string) {
    try {
      key = prefix + key

      const value = window.sessionStorage.getItem(key)
      if (value === null) {
        return value
      }

      const data = JSON.parse(value)
      if (!isObject<Obj>(data)) {
        return data
      }
      return data.value
    } catch (error) {
      console.error(error)
    }
    return null
  },

  set(key: string, value: any) {
    try {
      key = prefix + key
      const data: any = { value }
      window.sessionStorage.setItem(key, JSON.stringify(data))
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },

  remove(key: string) {
    try {
      key = prefix + key
      window.sessionStorage.removeItem(key)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
}

export default {
  isSupported,
  local,
  session,
}
