import { IClientCore } from "@klook/apem-core/types";
// @ts-ignore
import { PluginCore } from "@klook/apem-core";
import { ApemPluginGlobal } from "./global";
import { ApemPluginEnv } from "./env";
import { ApemPluginFetch } from "./fetch";
import { ApemPluginBreadcrumb } from "./breadcrumb";
import { ApemPluginXHR } from "./xhr";
import { ApemPluginResource } from "./resource";
import { ApemPluginPreparation } from './preparation'
import { ApemStackParser } from "./stack";

export default class ApemPluginWeb extends PluginCore {
    init(client: IClientCore) {
        client.assignPlugins([
            new ApemPluginBreadcrumb(),
            new ApemPluginGlobal(),
            new ApemPluginFetch(),
            new ApemPluginXHR(),
            new ApemPluginResource(),
            new ApemPluginPreparation(),
            new ApemPluginEnv({
                sdk: {
                    name: '__buildName',
                    version: '__buildVersion'
                }
            }),
            new ApemStackParser()
        ])
    }
}
