import { getSite as getMarketByEnv } from '@klook/env-utils'

import {
    availableWebsite,
    whitelist,
    defaultConf,
    SiteConf,
    websiteConfig,
    marketConfig
} from './config'

/**
 * 获取某个站点配置信息
 * @param website
 */
const getWebsiteConfig = (website: string) => {
    return {
        ...defaultConf,
        ...websiteConfig[website] || {},
        ...marketConfig[getMarketByEnv()]
    }
}

/**
 * 扩展website 配置，比如一些特殊的站点配置，可以合并到站点中来
 * @param site
 * @param config
 */
function extendConfig(site: string, config: Partial<SiteConf>) {
    websiteConfig[site] = config
}

export {
    availableWebsite,
    getWebsiteConfig,
    extendConfig,
    whitelist
}
