import { Headers, PostFn } from '../types';

const setHeaders = (xhr: XMLHttpRequest, headers: Headers = {}) => {
  Object.keys(headers).forEach(name => {
    headers[name] && xhr.setRequestHeader(name, headers[name]);
  });
};

export const post: PostFn = (url, data, options, callback) => {
  const strData = JSON.stringify(data);

  if (typeof fetch === 'function') {
    const cb = (error: Error | null, data?: any) => {
      if (error) {
        return sendXhr(url, strData, options, callback);
      }
      return callback && callback.call(null, error, data);
    };
    return fetchData(url, strData, options, cb);
  }

  sendXhr(url, strData, options, callback);
};

export const sendXhr = (
  url: string,
  data: any,
  options: Record<string, any>,
  callback: Function
) => {
  const xhr = new XMLHttpRequest();
  xhr.open('post', url);
  xhr.timeout = options.timeout && options.timeout > 0 ? options.timeout : 60000;
  xhr.withCredentials = !!options.withCredentials;

  xhr.addEventListener('load', function() {
    if (xhr.readyState === 4) {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          callback(null, JSON.parse(xhr.responseText));
        } catch (err) {
          callback(err as Error, xhr.responseText);
        }
      } else {
        callback(new Error(xhr.statusText));
      }
    }
  });

  xhr.addEventListener('timeout', function() {
    callback(new Error(`timeout with ${options.timeout}ms`));
  });

  setHeaders(xhr, options.headers);
  xhr.send(data);
};

export const fetchData = (
  url: string,
  data: string,
  options: Record<string, any> = {},
  callback: Function = () => {}
) => {
  const fetchUrl = url;
  fetch(fetchUrl, {
    keepalive: true,
    headers: { ...options?.headers },
    body: new Blob([data], { type: 'application/json' }),
    method: 'POST',
    credentials: 'omit',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (data.success) {
        callback(null);
      } else {
        callback(new Error(data.error?.message || 'api error'));
      }
    });
};

// function sendBeacon(
//   url: string,
//   data: string,
//   options: RequestOptions,
//   callback: RequestCallback
// ) {
//   try {
//     const headers = options.headers || {}
//     const blob = new Blob([data], { type: ApplicationJson })
//     const platform = headers.platform || 'desktop'
//     const corsUrl = this.normalizeUrl(url, platform)

//     if (window.navigator.sendBeacon) {
//       const result = window.navigator.sendBeacon(corsUrl, blob)
//       callback(result ? null : new Error('Request failed in sendBeacon()'))
//       if (!result) {
//         this.fetchOrXhr(url, data, options)
//       }
//     } else {
//       this.fetchOrXhr(url, data, options, callback)
//     }
//   } catch (error) {
//     callback(error as Error)
//   }
// }
