export default class EventEmitter {
    _listeners: Record<string, any> = {}

    /**
     * Registers an event listener.
     * @param {string} evt Event name
     * @param {EventEmitterListener} fn Listener
     * @param {*} [ctx] Listener context
     * @returns {this} `this`
     */
    on(evt: string, fn: any, ctx?: any) {
        (this._listeners[evt] || (this._listeners[evt] = [])).push({
            fn  : fn,
            ctx : ctx || this
        });
        return this;
    }

    /**
     * Removes an event listener or any matching listeners if arguments are omitted.
     * @param {string} [evt] Event name. Removes all listeners if omitted.
     * @param {EventEmitterListener} [fn] Listener to remove. Removes all listeners of `evt` if omitted.
     * @returns {this} `this`
     */
    off(evt: string, fn: any) {
        if (evt === undefined)
            this._listeners = {};
        else {
            if (fn === undefined)
                this._listeners[evt] = [];
            else {
                const listeners = this._listeners[evt];
                for (let i = 0; i < listeners.length;)
                    if (listeners[i].fn === fn)
                        listeners.splice(i, 1);
                    else
                        ++i;
            }
        }
        return this;
    }

    /**
     * Emits an event by calling its listeners with the specified arguments.
     * @param {string} evt Event name
     * @param {...*} args Arguments
     * @returns {this} `this`
     */
    emit(evt: string, arg?: any) {
        const listeners = this._listeners[evt];
        if (listeners) {
            return listeners.reduce((acc, listener) => {
                // const call = i === 0 ? 'apply' : 'call'
                const result = listener.fn.call(listener.ctx, acc)

                return result || acc
            }, arg)
        }

        return arg
    }
}
