import { isFunction } from './utils'

const getKlkCommonFunc = (k: string, args?: any[]) => {
  const klk_common = window.klk_common
  const session = klk_common && klk_common.session
  return typeof session !== 'undefined' && isFunction(session[k])
    ? session[k].apply(session, args)
    : null
}

export const updateIhtSession = () => getKlkCommonFunc('updateIhtSession')

export const getSessionId: () => {
  iht_fe_session_id: string
  ga_fe_session_id: string
} = () => getKlkCommonFunc('getCurrentSession') || {}
