import { htmlTreeAsString } from "../utils/utils";
import { PluginCore } from "@klook/apem-core";

const hostReg = /^http(s)?:\/\/(.*?)\//

export class ApemPluginResource extends PluginCore {
    resourceTagName = ['img', 'script', 'link'].map(v => v.toUpperCase())

    handleError(event: Event) {
        const target = event.target as HTMLScriptElement | HTMLLinkElement
        const tagName = target.tagName

        const isResource = this.resourceTagName.includes(target.tagName)

        if (!isResource) {
            return
        }

        const message = `${'src' in target ? target.src : target.href}`
        const stack = `${htmlTreeAsString(target)}[${message}]`

        const host = message.match(hostReg)?.[0]
        const isThirdPart = host && !host.includes('klook')

        this.client.capture({
            isMasked: true,
            exception: {
                name: 'resourceLoadError',
                message: tagName.toLowerCase(),
                stack
            },
            catchBy: `web:ApemPluginResource`,
            isThirdPart
        })
    }

    monitor() {
        window.addEventListener('error', this.handleError.bind(this), true)
    }

    destroy() {
        window.removeEventListener('error', this.handleError)
    }
}
