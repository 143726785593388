import { Locale } from './types'

import { capitalize } from './utils'

/**
 * 处理一下 code, 获取多语言单复数规则中的语系
 * @param code 多语言对应 code
 */
function formatLanguageCode(code: string): string {
  // http://www.iana.org/assignments/language-tags/language-tags.xhtml
  if (code.indexOf('-') > -1) {
    const specialCases = [
      'hans',
      'hant',
      'latn',
      'cyrl',
      'cans',
      'mong',
      'arab',
    ]
    let p = code.split('-')

    if (p.length === 2) {
      p[0] = p[0].toLowerCase()
      p[1] = p[1].toUpperCase()

      if (specialCases.indexOf(p[1].toLowerCase()) > -1)
        p[1] = capitalize(p[1].toLowerCase())
    } else if (p.length === 3) {
      p[0] = p[0].toLowerCase()

      // if length 2 guess it's a country
      if (p[1].length === 2) p[1] = p[1].toUpperCase()
      if (p[0] !== 'sgn' && p[2].length === 2) p[2] = p[2].toUpperCase()

      if (specialCases.indexOf(p[1].toLowerCase()) > -1)
        p[1] = capitalize(p[1].toLowerCase())
      if (specialCases.indexOf(p[2].toLowerCase()) > -1)
        p[2] = capitalize(p[2].toLowerCase())
    }

    return p.join('-')
  }

  return code
}

/**
 * 获取多语言对应 code
 * @param code 多语言 locale
 */
export function getLanguagePartFromCode(code: Locale): string {
  const p = code.split('-')
  return formatLanguageCode(p[0])
}
