export default function extend(Vue: any): void {
  if (!Vue.prototype.hasOwnProperty('$i18n')) {
    Object.defineProperty(Vue.prototype, '$i18n', {
      get() {
        return this._i18n
      },
    })
  }

  Vue.prototype.$t = function(key: string, ...args: any[]): string {
    const i18n = this.$i18n
    return i18n.t(key, ...args)
  }

  Vue.prototype.$tc = function(key: string, ...args: any[]): string {
    const i18n = this.$i18n
    return i18n.t(key, ...args)
  }
}
