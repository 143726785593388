import dsBridge from 'dsbridge'
import jsBridge from '@klook/jsbridge'

export const bridgeAdaptor = {
  triggerEventName: 'iht.triggerEvent',
  call(...args: any[]) {
    if (window && window.dsBridge && typeof dsBridge.call === 'function') {
      return new Promise(resolve => {
        dsBridge.call(this.triggerEventName, ...args, (result: unknown) => {
          resolve(result)
        })
      })
    }

    if (window && window._klookJSBridge) {
      return jsBridge.call(this.triggerEventName, ...args)
    }

    return Promise.reject('No bridge found')
  },
}
