import { IhtError } from './error'
import querystring from 'querystring'
import Logger from './logger'
import Detector from './detector'
import storage from './storage'
import { encode } from './base64'
import { polyfillNodeList } from './polyfill'
import './listener'
import EventEmitter from 'eventemitter3'
import * as Constants from './constants'
import { bridgeAdaptor } from './bridge'

import config from './config'
import plugins from './plugins'

import {
  // isArray,
  isObject,
  isFunction,
  deepMerge,
  // randomString,
  getQueryValue,
  json2string,
  stripUndefinedFields,
  deepCompactObj,
  isInApp,
  getQueryParam,
  uidWithVersion,
  isIe,
  getNow,
  transformObj,
  getFromCookie,
  isSearchBot,
  encodeSpm,
  joinClickSpmToUrl,
  findLatestElement,
  getClickInfo,
  retry,
  pick,
  isSpecialLink,
  isNil,
  isString,
  querySelector,
  hasTrackerInfo,
  mergeData,
  runQueue,
  forEach,
  generateSpm,
  // tryCatch,
} from './utils'
import {
  Props,
  Sprops,
  TrackerOptions,
  BaseElement,
  PageElement,
  ModuleElement,
  ItemElement,
  VirtualItemElement,
  EventType,
  LogObj,
  Payload,
  PageViewOptions,
  ExposureOptions,
  LocalConfigOptions,
  CallBackType,
  ActionType,
  ActionTypeOptions,
  PartialTrackerOptions,
  EventCallback,
  EventOption,
  MutableTrackData,
  CollectParamerter,
  ScopeState,
  QueryOpt,
  DisableConfig,
  ItemNode,
} from './types'
import RequestQueue from './requestQueue'
import { updateIhtSession, getSessionId } from './klkCommon'
import { TrackerAttr } from './constants'

polyfillNodeList()

const ClickEmpty = { clickId: '-1', clickSpm: 'unknown', timestamp: -1 }
export class Tracker extends EventEmitter {
  constants = { ...Constants }
  // 默认配置项
  options: TrackerOptions = {
    url: '',
    timeout: 15000,
    throttleTime: 400,
    autoPageView: true,
    props: {
      pageId: '',
      deviceId: '',
      keplerId: '',
      distinctId: '',
      experiments: [],
      platform: '',
      siteName: '',
      siteLanguage: '',
      siteCurrency: '',
    },
    sprops: {},
    isDebugMode: false,
    logDebug: false,
    concurrent: 4,
    batchSize: 5,
    exposureDuration: 500,
    exposureRate: 0.8,
    enableProxyClick: true,
  }

  private innerState: { [key: string]: any } = {}

  private _callbackQueue: any[] = []

  private _isReady = false

  version: string = config.version

  private _logger: Logger
  // private _request: Request
  private _requestQueue: RequestQueue
  private _detector: Detector

  // 埋点属性配置
  private _attrs = {
    page: 'data-spm-page',
    module: 'data-spm-module',
    item: 'data-spm-item',
    virtualItem: 'data-spm-virtual-item',
  }

  // dom 监听器
  private _mobserver?: MutationObserver | null

  // 曝光监听器
  private _iobserver?: IntersectionObserver | null

  // 普通 props
  private _props: Props

  // 持久化 props
  private _sprops: Sprops

  // 追踪信息
  private _from: { clickId: string; clickSpm: string; timestamp?: number } = {
    clickId: '-1',
    clickSpm: 'unknown',
    timestamp: -1,
  }

  private localConfig: LocalConfigOptions = {
    lastRef: '',
    unloadT: 0,
    initT: getNow(),
    lastPv: '',
    currentPv: '',
    pvCount: 0,
    from: {},
  }
  private latestFrom: {
    targeSrc?: string
    clickSpm?: string
    clickId?: string
    timestamp?: number
  } = {}

  firstPv: string = ''

  // 追踪节点
  $pages: PageElement[] = []
  $modules: ModuleElement[] = []
  $items: ItemElement[] = []
  $virtualItems: VirtualItemElement[] = []

  concurrent: number = 0
  pageIsUnload: Boolean = false

  isIe: Boolean = false
  private isBot: Boolean = false
  private isDebug: Boolean = false

  actions: ActionType[] = []

  actionQueue: Array<LogObj> = []

  constructor(options: Partial<TrackerOptions> = {}) {
    super()
    this.isIe = isIe()
    this.isBot = isSearchBot()
    // if (this.isBot) return;
    const debug = storage.local.get('debug')
    this.isDebug = Boolean(debug)
    this.options = deepMerge(this.options, options, {
      isDebugMode: Boolean(debug),
    }) as TrackerOptions
    this._props = this.options.props || {}
    this._sprops = this.options.sprops || {}
    this._logger = new Logger({ debug })

    this._detector = new Detector()
    this._requestQueue = new RequestQueue({ debug: Boolean(debug) }, this)

    this.innerState._navTime = performance.timing?.navigationStart || getNow()

    this.readLocalConfig()

    this.initMutationAndInter()

    this.initEvents()
    this.bindNativeEvent()
    this.bindFromEvent()
    plugins(this)
  }

  /**
   * Add track page
   * @param page
   */
  public addPage(_page: BaseElement | PageElement) {
    let pages = this.$pages
    const { page: pageAttr } = this._attrs
    const page = _page as PageElement
    if (_page.hasAttribute(pageAttr) && !pages.includes(page)) {
      pages.push(page)
    }
    return pages
  }

  /**
   * @internal
   */
  private _disableConfig: {
    module: (RegExp | Function)[]
  } = {
    module: [],
  }

  /**
   * 禁用 `page` | `module` | `item` 事件上报
   *
   * @example 禁用所有module 的上报
   * ```typescripts
   * tracker.disable({
   *  type: 'module',
   *  rules: () => return true
   * })
   * ```
   * @example
   * ```typescripts
   * tracker.disable({
   *  type: 'module',
   *  rules: [() => {
   *    const current = new Date()
   *     const date = current.getDate()
   *     const year = current.getFullYear()
   *     const month = current.getMonth()
   *     if (year !== 2023) return false
   *     // 7.7
   *     if (month === 6 && date === 7) return true
   *     return false
   *  }]
   * })
   * ```
   *
   * @example 禁用/test/页面module上报
   * ```typescripts
   * tracker.disable({
   *  type: 'module',
   *  rules: /test/
   * })
   * ```
   * @param config
   */
  disable(config: DisableConfig) {
    const { type, rules } = config
    const _rules = Array.isArray(rules) ? rules : [rules]
    const { _disableConfig } = this

    const mod = _disableConfig[type as 'module']
    if (mod) {
      _rules.forEach(rule => {
        if (!mod.includes(rule)) {
          mod.push(rule)
        }
      })
    }
  }

  isModuleDisabled() {
    const { _disableConfig } = this
    const { module } = _disableConfig

    return module.some(rule => {
      if (typeof rule === 'function') {
        return rule()
      }
      return rule.test(window.location.href)
    })
  }

  callActions<T extends Record<string, any> = any>(
    action: ActionTypeOptions
  ): Partial<T> {
    const { actions } = this
    let state: Partial<T> = {}
    actions.forEach(fn => {
      let newState = fn.call(this, state, action)
      state = Object.assign(state, newState)
    })
    return state
  }

  registerAction<T = any>(action: ActionType<T>) {
    if (!this.actions.includes(action)) this.actions.push(action)
  }

  getTimestamp() {
    // const state = this.callActions<{ timestamp: number }>({
    //   type: 'ServerTime',
    // })
    return getNow()
  }

  close() {
    this.emit('close')
  }

  private readLocalConfig() {
    let localData = storage.local.get(Constants.LocalConfigKey) || {}
    this.localConfig = {
      ...this.localConfig,
      ...localData,
    }
  }

  bindFromEvent() {
    this.off('fromChange')
  }

  initMutationAndInter() {
    if (this.isIe || this.isBot) return
    this._mobserver = this.createMutationObserver()

    if (this.isModuleDisabled()) return
    this._iobserver = this.createIntersectionObserver()
  }

  private bindNativeEvent() {
    if (this.isBot) return
    window.addEventListener('online', () => {
      this._requestQueue.flushQueue()
    })
    window.addEventListener('beforeunload', () => {
      this.pageIsUnload = true
      this._requestQueue.flushQueue()
    })
    window.addEventListener('unload', () => {
      this._logger.info('unload:unload', getNow())
      this.pageMayUnload()
    })

    document.addEventListener(
      'click',
      ev => {
        if (this.isBot) return
        if (!this.options.enableProxyClick) return
        const target = (ev.target || ev.currentTarget) as Element
        if (target) {
          // @ts-ignore
          if (target.clicked) {
            // @ts-ignore
            target.clicked = false
          }
        }
      },
      false
    )

    document.addEventListener(
      'click',
      ev => {
        if (this.isBot || !this.options.enableProxyClick) return
        const target = (ev.target || ev.currentTarget) as Element
        // @ts-ignore
        if (target && !target.clicked) {
          this.proxyClickInfo(target, ev)
        }
      },
      true
    )
    document.addEventListener(
      'mousedown',
      ev => {
        if (this.isBot || !this.options.enableProxyClick) return
        const target = (ev.target || ev.currentTarget) as Element
        if (target) {
          this.proxyClickInfo(target, ev)
        }
      },
      true
    )
  }

  proxyClickInfo(target: Element, _ev: MouseEvent) {
    const { item, virtualItem, module, page } = this._attrs
    const latestItem = findLatestElement(target, ele => {
      if (ele?.hasAttribute?.(item) || ele?.hasAttribute?.(virtualItem)) {
        return 1
      }
      if (ele?.hasAttribute?.(module)) {
        return -1
      }
      return 0
    })

    if (!latestItem) return
    const latestA = findLatestElement(target, ele => {
      if (ele?.hasAttribute && ele?.tagName === 'A') {
        const href = ele.getAttribute('href') || ''
        if (isSpecialLink(href)) return 0
        return 1
      }
      if (ele?.hasAttribute?.(page)) {
        return -1
      }
      return 0
    })

    const aItem = latestItem.tagName === 'A' ? latestItem : latestA
    const href = aItem?.getAttribute?.('href')
    const spmItem = latestItem as BaseElement
    const spm = this.collectSpm(spmItem)
    if (!spm.code || !spm.page || !spm.module || !spm.item) {
      this._logger.warn('Not found spm info', spm)
      return
    }
    const __tracker = spmItem.__virtualTracker__ ||
      spmItem.__tracker__ || { clickId: '' }

    const clickId = this.getClickId().slice(-10)
    const clickSpm = spm.code
    if (href && aItem) {
      __tracker.clickId = clickId
      let url = joinClickSpmToUrl(href, clickSpm || '', clickId)
      if (!isSpecialLink(href)) {
        aItem?.setAttribute('href', url)
      }

      // @ts-ignore
      target.clicked = true
      this.latestFrom = {
        targeSrc: href,
        timestamp: getNow(),
        clickId: clickId,
        clickSpm: clickSpm,
      }
    } else {
      __tracker.clickId = clickId

      this.latestFrom = {
        timestamp: getNow(),
        clickId: clickId,
        clickSpm: clickSpm,
      }
    }
  }

  // private checkClickEvent(aItem: Element,
  //   // spmItem: Element,
  //   e: Event) {
  //   const href = aItem.getAttribute?.('href') || ''
  //   if (aItem?.tagName === 'A' && href && !href.startsWith('javascript')) {
  //     e.stopImmediatePropagation()
  //     // this.track('action', spmItem)
  //   }
  // }

  private pageMayUnload() {
    this._requestQueue.emit('page:unload')
    this.setLocalConfig({
      unloadT: getNow(),
      lastPv: this.firstPv || '',
      lastRef: window.location.href,
      pvCount: 0,
    })
  }

  private getLastRef() {
    const {
      localConfig: { lastRef },
    } = this
    return lastRef || ''
  }

  setLocalConfig(Options: Partial<LocalConfigOptions> = {}) {
    const localData = storage.local.get(Constants.LocalConfigKey) || {}
    this.localConfig = {
      ...this.localConfig,
      ...localData,
      ...Options,
    }
    storage.local.set(Constants.LocalConfigKey, this.localConfig)
  }

  initEvents() {
    this.on(Constants.EventPageView, (data: LogObj) => {
      if (this.localConfig.pvCount < 1) {
        this.firstPv = data?.eventCommon?.spm || ''
      }
      this.localConfig = {
        ...this.localConfig,
        currentPv: data?.eventCommon?.spm,
        pvCount: this.localConfig.pvCount + 1,
      }

      updateIhtSession()
    })

    this.on(Constants.EventAction, () => {
      updateIhtSession()
    })

    this.on(Constants.EventExposure, () => {
      updateIhtSession()
    })
    this.on(Constants.InhouseInitReady, () => {
      this._isReady = true

      const callbackQueue = this._callbackQueue
      while (callbackQueue.length) {
        const args = callbackQueue.shift()
        if (args) {
          this.track.apply(this, args)
        }
      }
    })

    this.on(Constants.InhouseInit, () => {
      this._requestQueue.emit('request:success')
    })
  }

  setAppTrackIds() {
    if (isInApp()) {
      const url = window.location.href
      const getData = (key: string) =>
        getQueryParam(url, key) || getFromCookie(key)
      let distinctId = getData('app_track_distinct_id')
      let deviceId = getData('app_track_device_id')

      const props = {
        ...(distinctId ? { distinctId } : {}),
        ...(deviceId ? { deviceId, keplerId: deviceId } : {}),
      }
      this.setProps({ ...props })

      if (deviceId) {
        this._requestQueue.setOptions({
          deviceId: deviceId || this.options.props.deviceId || '',
          platform: this._props.platform,
        })
      }
    }
  }

  /**
   * 更新inhouse配置
   * @param options
   */
  setConfig(options: PartialTrackerOptions) {
    this.options = deepMerge(this.options, options) as TrackerOptions<
      ThisType<Tracker>
    >

    this.setProps(this.options.props)
    if (this.options.sprops) {
      this.setSprops(this.options.sprops)
    }
    this.emit(Constants.ConfigChangeEvent, this)
  }

  /**
   * alias of setConfig
   * @param options
   */
  config(options: PartialTrackerOptions = {}) {
    this.setConfig(options || {})
  }

  updateReferrerInSpa() {
    const { innerState } = this
    innerState['lastReferer'] = innerState['referer'] || document.referrer
    innerState['referer'] = window.location.href
  }

  getClickInfo() {
    return {
      ...this._from,
    }
  }

  /**
   * 初始化
   * @param options 配置项
   */
  init(options: Partial<TrackerOptions> = {}) {
    if (this.isBot) return
    this.setConfig(options)

    this._props = this.options.props || {}
    this.setAppTrackIds()
    this.updateReferrerInSpa()

    this._sprops = this.options.sprops || {}
    this._requestQueue.setOptions({
      ...this.options,
      ...this.options.props,
      url: this.options.url,
      platform: this._props.platform,
      ...this.options.request,
    })
    this.onDomReady(() => {
      this._from = this.collectFromInfo()
      this.$pages = this.collectPageNodes()

      this.$items = this.collectItemNodes()
      this.$virtualItems = this.collectVirtualItemNodes()

      this.$modules = this.collectModuleNodes()

      // 自动上报 pageview 事件
      if (this.options.autoPageView) {
        this.$pages.forEach($page => {
          if ($page.__tracker__?.trigger === 'auto') {
            this.trackPageView($page)
          }
        })
      }

      this.bindEvents()

      this.emit(Constants.InhouseInitReady)
    })

    this.emit(Constants.InhouseInit)
  }

  /**
   * 返回 props
   */
  getProps() {
    return this._props
  }

  /**
   * 设置 props
   * @param props 属性对象
   */
  setProps(props: Partial<Props>) {
    this._props = deepMerge(this._props, props) as Props
  }

  /**
   * #### 获取已经设置的 `superproperties` 值
   * @NOTE: 暂不需持久化存储了（storage），如后期需要，可修改此方法
   */
  getSprops() {
    return this._sprops
  }

  /**
   * #### 更新或设置 superproperties 里面的值; 如需要更新inhouse属性，可以使用此方法
   * @NOTE: 暂不需持久化存储了（storage），如后期需要，可修改此方法
   * @param props 属性对象
   */
  setSprops(props: Sprops) {
    this._sprops = deepMerge(this._sprops, props)
  }

  private getFromLocalFrom() {
    let rawFrom = storage.local.getRaw('from')
    let from = storage.local.get('from')

    this.innerState['hasRawFrom'] = Boolean(rawFrom)

    storage.local.remove('from')
    return from || null
  }

  get_spm_info(
    maybeUrl: string,
    config: { duration?: number; args?: any } = {}
  ) {
    const { item, module, virtualItem } = this._attrs
    if (this.isBot || !this.options.enableProxyClick) return maybeUrl
    if (typeof maybeUrl !== 'string') return maybeUrl
    let url = maybeUrl
    const { timestamp = 0, clickSpm = '', clickId = '', targeSrc = '' } =
      this.latestFrom || {}
    const duration = config?.duration || 0
    const range = getNow() - (timestamp + duration)

    const checkUrlInTarget = (href: string, targetHref: string) => {
      if (!href || !targetHref) return false
      const targetArr = targetHref.split('?')
      const hrefArr = href.split('?')

      if (targetArr[0] !== hrefArr[0]) return false
      if (href === targetHref) return true

      if (typeof URL === 'function') {
        try {
          const t1 = new URL(targetHref, window.location.origin)
          const h1 = new URL(href, window.location.origin)
          if (t1.pathname !== h1.pathname) return false
          t1.searchParams.delete('spm')
          t1.searchParams.delete('clickId')
          t1.searchParams.sort()
          h1.searchParams.sort()
          if (t1.search !== h1.search) return false
          if (t1.hash !== h1.hash) return false
          return true
        } catch (e) {
          return false
        }
      }
      return false
    }

    if ((range < 50 && clickSpm) || checkUrlInTarget(url, targeSrc)) {
      url = joinClickSpmToUrl(url, clickSpm, clickId)
    } else {
      if (window.event) {
        const target = (window.event.target ||
          window.event.currentTarget) as Element
        const latestItem = findLatestElement(target, ele => {
          if (ele?.hasAttribute?.(item) || ele?.hasAttribute?.(virtualItem)) {
            return 1
          }
          if (ele?.hasAttribute?.(module)) {
            return -1
          }
          return 0
        })
        const spmItem = latestItem as BaseElement
        if (spmItem) {
          const spm = this.collectSpm(spmItem)
          if (!spm.code || !spm.page || !spm.module || !spm.item) {
            this._logger.warn('Get_spm_info not found spm info', spm)
          } else {
            const __tracker =
              spmItem?.__virtualTracker__ || spmItem?.__tracker__
            url = joinClickSpmToUrl(
              url,
              spm.code,
              clickId || __tracker?.clickId || ''
            )
          }
        }
      }
    }
    this._logger.info('Get_spm_info', config, range, url, maybeUrl, {
      ...this.latestFrom,
    })
    this.latestFrom = {}

    return url
  }

  /**
   * 收集追踪信息
   */
  private collectFromInfo() {
    let ref: any = getClickInfo()
    let localRef = this.getFromLocalFrom()
    ref = {
      ...ClickEmpty,
      ...localRef,
      ...ref,
    }

    return {
      clickId: ref.clickId,
      clickSpm: ref.clickSpm,
      timestamp: localRef?.timestamp || (ref.clickSpm && Date.now()) || -1,
    }
  }

  /**
   * 收集 page 节点 & 附加追踪信息
   * @param el dom 节点
   */
  private collectPageNodes(el: Document | Element = document) {
    const attrName = this._attrs.page
    const $pages: NodeListOf<PageElement> = el.querySelectorAll(`[${attrName}]`)
    $pages.forEach($page => this.attachTrackInfoToDom(attrName, $page))
    return Array.from($pages)
  }

  /**
   * 收集 module 节点 & 附加追踪信息
   * @param el dom 节点
   */
  private collectModuleNodes(el: Document | Element = document) {
    const attrName = this._attrs.module
    const $modules: NodeListOf<ModuleElement> = el.querySelectorAll(
      `[${attrName}]`
    )
    $modules.forEach($module => this.attachTrackInfoToDom(attrName, $module))
    return Array.from($modules)
  }

  /**
   * 收集 item 节点 & 附加追踪信息
   * @param el dom 节点
   */
  private collectItemNodes(el: Document | Element = document) {
    const attrName = this._attrs.item
    const $items: NodeListOf<ItemElement> = el.querySelectorAll(`[${attrName}]`)
    $items.forEach($item => this.attachTrackInfoToDom(attrName, $item))
    return Array.from($items)
  }

  /**
   * 收集 virtualItem 节点 & 附加追踪信息
   * @param el dom 节点
   */
  private collectVirtualItemNodes(el: Document | Element = document) {
    const attrName = this._attrs.virtualItem
    const $virtualItems: NodeListOf<VirtualItemElement> = el.querySelectorAll(
      `[${attrName}]`
    )
    $virtualItems.forEach($virtualItem =>
      this.attachTrackInfoToDom(attrName, $virtualItem)
    )
    return Array.from($virtualItems)
  }

  /**
   * set spm path info to tracking element
   * @param el relavent element
   * @param isVirtual is virtual item element
   */
  attachSpmToDom(el: BaseElement, isVirtual: boolean = false) {
    const spm = this.collectSpm(el) as { code: string }
    const trackVal = isVirtual ? el.__virtualTracker__ : el.__tracker__
    const code = spm.code
    if (code && trackVal && !trackVal.spm && !/[{}]/.test(code)) {
      trackVal.spm = spm
    }
  }

  /**
   * 附加信息到 dom 节点上
   * @param type 埋点类型
   * @param el dom 节点
   */
  private attachTrackInfoToDom(type: string, el: BaseElement) {
    const { page, module, item, virtualItem } = this._attrs
    const spm = el.getAttribute(type) as string
    const binding = this.parseBinding(spm)

    if (!binding) {
      return
    }

    const attachOpt = {
      // [+-]: 是否attach; 1, 2, 3, 4 代表: page, module, item, virtual
      spmStatus: 0,
    }

    if (type === virtualItem) {
      ;(el as VirtualItemElement).__virtualTracker__ = {
        value: binding.value,
        virtualValue: binding.virtualValue,
        trigger: binding.trigger || 'auto',
        event: binding.event || 'click',
        modifier: binding.modifier || '',
        type: binding.type,
        objectId: binding.objectId,
        extra: binding.extra,
      }
      attachOpt.spmStatus = 4
    }

    if (type === page) {
      let pageId = this.uuid_version()
      const __tracker__ = (el as PageElement).__tracker__
      // the same page value, and the same pageId
      if (__tracker__?.value === binding.value) {
        pageId = __tracker__.pageId || pageId
      }

      ;(el as PageElement).__tracker__ = {
        value: binding.value,
        trigger: binding.trigger || 'auto',
        objectId: binding.objectId,
        extra: binding.extra,
        pageId: pageId,
        _from: binding._from,
      }
    } else if (type === module) {
      const __tracker__ = (el as ModuleElement).__tracker__ || {}
      const isSame = __tracker__.value === binding.value && binding.value

      let extendProps: Record<string, any> = {}
      let _time = isSame ? __tracker__.time : undefined
      if (!isSame) {
        const exposureRate = this.calculateModuleExposureRatio(el)
        if (exposureRate >= 0.8) {
          _time = getNow()
        }
      }
      // 重新收集
      __tracker__.spm = undefined
      const spm = this.collectSpm(el)
      if (spm.page && spm.module) {
        extendProps = {
          ...extendProps,
          spm,
        }
      }
      extendProps = {
        ...extendProps,
        time: _time,
      }
      ;(el as ModuleElement).__tracker__ = {
        ...extendProps,
        value: binding.value,
        trigger: binding.trigger || 'auto',
        objectId: binding.objectId,
        index: binding.index,
        length: binding.length,
        extra: binding.extra,
      }
      attachOpt.spmStatus = 2
    } else if (type === item) {
      ;(el as ItemElement).__tracker__ = {
        value: binding.value,
        trigger: binding.trigger || 'auto',
        event: binding.event || 'click',
        modifier: binding.modifier || '',
        type: binding.type,
        objectId: binding.objectId,
        extra: binding.extra,
      }
      attachOpt.spmStatus = 3
    }
    if (attachOpt.spmStatus > 0) {
      // attachSpm
      this.attachSpmToDom(el, attachOpt.spmStatus === 4)
    }
  }

  /**
   * dom ready
   * @param callback 回调
   */
  private onDomReady(callback: Function) {
    if (document.readyState !== 'loading') {
      callback()
    } else {
      window.addEventListener('DOMContentLoaded', callback as any, false)
    }
  }

  /**
   * 绑定各种事件
   */
  private bindEvents() {
    this.bindItemsEvent()
    this.bindVirtualItemsEvent()
    this.observerModules()
    this.observerDomChange()
    this.onPageStateChange()
    this.onPageHide()
  }

  /**
   * 绑定 item 事件
   * @param $items item 节点列表
   */
  private bindItemsEvent($items: ItemElement[] = this.$items) {
    $items.forEach($item => {
      // 优先取virtual-item 的配置
      const { trigger, event, modifier } = ($item.__virtualTracker__ ||
        $item.__tracker__ ||
        {}) as ItemNode

      if (trigger !== 'auto') {
        return
      }
      if ($item.removeEventListeners) {
        $item.removeEventListeners(event)
      }
      if ($item.addCustomEventListener) {
        $item.addCustomEventListener(
          event,
          (e: Event) => {
            modifier.includes('stop') && e.stopPropagation()
            modifier.includes('prevent') && e.preventDefault()

            this.trackAction($item)
          },
          {
            capture: modifier.includes('capture'),
            once: modifier.includes('once'),
          }
        )
      }
    })
  }

  /**
   * 绑定 virtualItem 事件
   * @param $virtualItems item 节点列表
   */
  private bindVirtualItemsEvent(
    $virtualItems: VirtualItemElement[] = this.$virtualItems
  ) {
    $virtualItems.forEach($virtualItem => {
      const { trigger, event, modifier } = $virtualItem.__virtualTracker__ || {}

      if (trigger !== 'auto') {
        return
      }
      if ($virtualItem.removeEventListeners) {
        $virtualItem.removeEventListeners(event)
      }
      if ($virtualItem.addCustomEventListener) {
        $virtualItem.addCustomEventListener(
          event,
          (e: Event) => {
            modifier.includes('stop') && e.stopPropagation()
            modifier.includes('prevent') && e.preventDefault()

            this.trackVirtualAction($virtualItem)
          },
          {
            capture: modifier.includes('capture'),
            once: modifier.includes('once'),
          }
        )
      }
    })
  }

  private collectAndSetSpm($modules: ModuleElement[]) {
    if (!$modules.length) return
    this._logger.info('Ready to collect spm')
    const documentContains = (m: any) => {
      try {
        if (document.body?.contains && document.body.contains(m)) return true
      } catch (e) {
        return true
      }
      return false
    }
    const toCollect = ($module: ModuleElement, cb?: CallBackType) => {
      if (!documentContains($module)) return
      const moduleSpm = $module[TrackerAttr]
      if (!moduleSpm) {
        this.attachTrackInfoToDom(this._attrs.module, $module)
      }
      const spm = this.collectSpm($module)
      if (spm.code && spm.page && spm.module && moduleSpm) {
        moduleSpm.spm = spm as ModuleElement['__tracker__']['spm']
      } else {
        this._logger.warn('IntersectionObserver Invalid spm:', spm)
        cb && setTimeout(cb, 200)
      }
    }
    $modules.forEach($module => {
      retry(toCollect, { count: 3, args: $module })
    })
  }

  /**
   * 创建一个曝光监听器
   */
  private createIntersectionObserver() {
    const exposureRate = this.options.exposureRate || 0.8
    try {
      return new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const $module = entry.target as ModuleElement

            const __tracker__ = $module.__tracker__
            if (!__tracker__) {
              this.attachTrackInfoToDom(this._attrs.module, $module)
            }

            $module.__tracker__ = $module.__tracker__ || {}

            if (entry.isIntersecting) {
              $module.__tracker__.time = Date.now()
              // @NOTE: Fix spm undefined when dom was removed
              if (!$module.__tracker__?.spm) {
                this.collectAndSetSpm([$module])
              }
            } else {
              $module.__tracker__?.trigger === 'auto' &&
                this.trackExposure($module)
            }
          })
        },
        {
          threshold: exposureRate || 0.8, // 可视区域 >= 80% 视为曝光
        }
      )
    } catch (error) {
      this._logger.error(error)
      return null
    }
  }

  enqueueTask(taskCb: () => any) {
    setTimeout(() => taskCb.call(this), 0)
  }

  private processRemovedNode(removedNodes: NodeList) {
    if (!removedNodes.length) return

    // @NOTE: Ignore，如需处理 dom 移除，可在此操作
    const { module } = this._attrs
    // let $modules: ModuleElement[] = []

    removedNodes.forEach(node => {
      // 如果是非元素节点，则不处理
      if (node.nodeType !== Node.ELEMENT_NODE) {
        return
      }

      // 1.异步收集，避免耗时过多，带来卡顿
      // this.enqueueTask(() => {
      //   const $el = node as BaseElement

      //   // 收集节点自身埋点
      //   if ($el.getAttribute(module)) {
      //     this.attachTrackInfoToDom(module, $el)
      //     $modules.push($el as ModuleElement)
      //   }

      //   // 收集子节点埋点
      //   $modules = $modules.concat(this.collectModuleNodes($el))
      // })
    })

    this.enqueueTask(() => {
      this.$modules = Array.from(document.querySelectorAll(`[${module}]`))
    })

    // 2.由于 1 是异步的，故这里也异步
    // this.enqueueTask(() => {
    //   if ($modules.length) {
    //     this.$modules = this.$modules.filter(module => {
    //       return !$modules.some(deletedModule => {
    //         const deletedTracker = deletedModule.__tracker__
    //         const {
    //           value,
    //           index,
    //           length,
    //           extra,
    //           objectId,
    //         } = module.__tracker__
    //         return (
    //           deletedTracker.value === value &&
    //           deletedTracker.index === index &&
    //           deletedTracker.length === length &&
    //           JSON.stringify(deletedTracker.extra) ===
    //             JSON.stringify(extra) &&
    //           deletedTracker.objectId === objectId
    //         )
    //       })
    //     })
    //   }
    // })
  }

  private processAddedNode(addedNodes: NodeList) {
    if (!addedNodes.length) return

    const { page, module, item, virtualItem } = this._attrs

    let $pages: PageElement[] = []
    let $modules: ModuleElement[] = []
    let $items: ItemElement[] = []
    let $virtualItems: VirtualItemElement[] = []

    addedNodes.forEach(node => {
      // 如果是非元素节点，则不处理
      if (node.nodeType !== Node.ELEMENT_NODE) {
        return
      }

      const $el = node as BaseElement
      // 1.异步收集，避免耗时过多，带来卡顿
      this.enqueueTask(() => {
        // 收集 virtualItem 自身埋点
        if ($el.getAttribute(virtualItem)) {
          this.attachTrackInfoToDom(virtualItem, $el)
          $virtualItems.push($el as VirtualItemElement)
        }

        // 收集节点自身埋点
        if ($el.getAttribute(item)) {
          this.attachTrackInfoToDom(item, $el)
          $items.push($el as ItemElement)
        } else if ($el.getAttribute(module)) {
          this.attachTrackInfoToDom(module, $el)
          $modules.push($el as ModuleElement)
        } else if ($el.getAttribute(page)) {
          this.attachTrackInfoToDom(page, $el)
          $pages.push($el as PageElement)
        }

        // 收集子节点埋点
        $pages = $pages.concat(this.collectPageNodes($el))
        $modules = $modules.concat(this.collectModuleNodes($el))
        $items = $items.concat(this.collectItemNodes($el))
        $virtualItems = $virtualItems.concat(this.collectVirtualItemNodes($el))

        if ($modules.length) {
          this.$modules = this.$modules.concat($modules)
        }
      })
    })

    this.enqueueTask(() => {
      // // 上报新添加节点的 pageview
      $pages.forEach($page => {
        if ($page.__tracker__?.trigger === 'auto') {
          this.trackPageView($page)
        }
      })

      this.observerModules($modules)

      this.bindItemsEvent($items)
      this.bindVirtualItemsEvent($virtualItems)
    })
  }

  private processAttrMutation(mutation: MutationRecord) {
    // 如果属性值变更了，则重新解析
    const { target, attributeName } = mutation
    const { module, page } = this._attrs
    const $el = target as BaseElement
    const attr = attributeName || ''
    const value = $el.getAttribute(attr)
    this._logger.info(`The ${attr} attribute was modified: ${value}`)
    if (!value) return

    if (!Object.values(this._attrs).includes(attr)) return
    const oldValue = ($el as BaseElement)?.__tracker__?.value

    this.attachTrackInfoToDom(attr, $el)

    const parseValue = this.parseBinding(value || '')
    const newValue = parseValue?.value
    if (![module, page].includes(attr) && newValue && newValue === oldValue) {
      return
    }
    if (attr === module) {
      this.collectAndSetSpm([$el as ModuleElement])
      this.enqueueTask(() => this.collectItemNodes($el))
      this.enqueueTask(() => this.collectVirtualItemNodes($el))
    } else if (attr === page) {
      const trigger = parseValue?.trigger
      if (
        newValue &&
        newValue !== oldValue &&
        (!trigger || trigger === 'auto')
      ) {
        this.trackPageView($el)
      }
      this.addPage($el)
      this.enqueueTask(() => {
        const collectModules = this.collectModuleNodes($el)
        this.collectAndSetSpm(collectModules)
      })
      this.enqueueTask(() => this.collectItemNodes($el))
      this.enqueueTask(() => this.collectVirtualItemNodes($el))
    }
  }

  /**
   * 创建一个 DOM 监听器
   */
  private createMutationObserver() {
    try {
      return new MutationObserver(mutationsList => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            // 如果 dom 节点变更，添加 或 移除
            const { addedNodes, removedNodes } = mutation
            this.processRemovedNode(removedNodes)
            this.processAddedNode(addedNodes)
          } else if (mutation.type === 'attributes') {
            this.processAttrMutation(mutation)
          }
        }
        this.processIdleMutation()
      })
    } catch (error) {
      this._logger.error(error)
      return null
    }
  }

  runIdleTask(cb: any) {
    const requstIdleCb = (window as any).requestIdleCallback
    if (typeof requstIdleCb === 'function') {
      return requstIdleCb.call(window, cb)
    } else {
      return setTimeout(cb, 1000)
    }
  }

  cancelIdleTask(id: number) {
    if (id) {
      const cancelIdleCallback = (window as any).cancelIdleCallback
      if (typeof cancelIdleCallback === 'function') {
        cancelIdleCallback.call(window, id)
      } else {
        clearTimeout(id)
      }
    }
  }

  private processIdleMutation() {
    const { innerState } = this
    this.cancelIdleTask(innerState.processIdleMutation)

    innerState.processIdleMutation = this.runIdleTask(() => {
      this._logger.info('Ready idle mutation')
      const { $items, $virtualItems, $modules } = this
      const currentItems = this.collectItemNodes()
      if (currentItems.length) {
        const diffItems = currentItems.filter(i => !$items.includes(i))
        this.bindItemsEvent(diffItems)
      }
      const currentVirtualItems = this.collectVirtualItemNodes()
      if (currentVirtualItems.length) {
        const diffVItems = currentVirtualItems.filter(
          i => !$virtualItems.includes(i)
        )
        this.bindVirtualItemsEvent(diffVItems)
      }
      const currentModules = this.collectModuleNodes()
      if (currentModules.length) {
        const diffModules = currentModules.filter(i => !$modules.includes(i))
        this.observerModules(diffModules)
      }
    })
  }

  /**
   * 监控 module 曝光
   * @param $modules module 节点列表
   */
  private observerModules($modules: ModuleElement[] = this.$modules) {
    $modules.forEach($module => {
      if ($module.__observed__) {
        return
      }

      this._iobserver?.observe($module)
      $module.__observed__ = true
    })
  }

  /**
   * 取消监控 module 曝光
   * @param $modules module 节点列表
   */
  private unobserverModules($modules: ModuleElement[] = this.$modules) {
    $modules.forEach($module => {
      this._iobserver?.unobserve($module)
      $module.__observed__ = false
    })
  }

  /**
   * 监听 dom 变更
   */
  private observerDomChange() {
    const body: any = document.body || document.documentElement
    if (this._mobserver) {
      const { page, module, item, virtualItem } = this._attrs

      this._mobserver.observe(body, {
        attributeFilter: [page, module, item, virtualItem],
        attributes: true,
        childList: true,
        subtree: true,
      })

      body.__hasObserved = true
    }
  }

  /**
   * 监听页面状态变更
   * @note 如果时页面发生跳转的场景时， setInterval 内函数不会执行，即不会发起请求，故还需要额外监听 pagehide
   */
  private onPageStateChange() {
    let hidden!: string, visibilityChange!: string
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else {
      // @ts-ignore
      if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
        // @ts-ignore
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
      }
    }

    if (hidden) {
      const attr = `hasBind${visibilityChange}`
      if (this.innerState[attr]) return
      this.innerState[attr] = true
      document.addEventListener(visibilityChange, () => {
        // @ts-ignore
        this.innerState.showStatus = document[hidden] ? 'hide' : 'show'
        // @ts-ignore
        if (document[hidden]) {
          this._logger.info('pagehidden')
          this.unobserverModules()
          let __tracker__
          this.$modules.forEach($module => {
            __tracker__ = $module.__tracker__ || {}
            __tracker__.trigger === 'auto' && this.trackExposure($module)
          })
        } else {
          this._logger.info('pageshow')
          this.observerModules()
          this._requestQueue.flushQueue()
        }
      })
    } else {
      this._logger.error('Your browser does not support visibilityChange event')
    }
  }

  /**
   * 页面跳转时，trackExposure 需要立即上报
   * @note pagehide 与 visibilitychange 触发顺序见 https://github.com/w3c/page-visibility/issues/39
   */
  private onPageHide() {
    const key = 'hasPagehide'
    if (this.innerState[key]) return
    this.innerState[key] = true
    window.addEventListener(
      'pagehide',
      () => {
        this._logger.info('onPageHide:pagehide')
        this.unobserverModules()
        let __tracker__
        this.$modules.forEach($module => {
          __tracker__ = $module.__tracker__ || {}
          __tracker__.trigger === 'auto' && this.trackExposure($module)
        })
        this.pageMayUnload()
      },
      false
    )
  }

  /**
   * compose spm uri
   * @param spm spm value
   * @param query spm query string
   */
  encodeSpm = encodeSpm

  generateSpm = generateSpm

  /**
   * 解析绑定信息
   * @param binding 绑定信息
   * @notice __default item 和 module 放在同一层级的唯一冲突在 value 这里
   */
  private parseBinding(binding?: string) {
    if (typeof binding !== 'string') {
      return null
    }

    const splitReg = /\?/
    const _bindingMatch = splitReg.exec(binding)
    const _binding = _bindingMatch
      ? [
          binding.slice(0, _bindingMatch.index),
          binding.slice(_bindingMatch.index + 1),
        ]
      : [binding]

    const [value, search = ''] = _binding
    // const [value, search = ''] = binding.split('?')
    let query = {}
    try {
      query = querystring.parse(search)
    } catch (_err) {
      const err = _err as Error
      this._logger.error('Parse query string failed in', `"${binding}"`)
      const stack = `Parse Error: ${binding}; \n` + err.stack
      const errmsg = `[Iht] parseBinding ${value} error; ${err.message}`
      const newError = new IhtError({
        message: errmsg,
        name: err.name,
      })
      console.error(stack)
      setTimeout(() => {
        this.sendDebugData({
          level: 'E',
          errmsg,
          errstack: stack,
        })
        throw newError
      }, 10)
    }

    const trimValue = value.trim()

    if (!trimValue) {
      return null
    }

    // 尝试解析 extra
    let extra = getQueryValue(query, 'ext')
    try {
      extra && (extra = JSON.parse(extra))
    } catch (error) {
      this._logger.error('Parse json failed in', `"${binding}"`)
      this.sendDebugData({
        level: 'E',
        errmsg: `Parse extra failed in ${binding}`,
      })
    }
    let obj_kvs: any = extra?.object_kvs
    if (isObject(extra) && obj_kvs) {
      try {
        obj_kvs = JSON.parse(obj_kvs)
        if (isObject(obj_kvs)) {
          ;(extra as any).object_kvs = transformObj(obj_kvs, val => {
            if (isObject(val)) {
              this._logger.warn(
                'object_kvs only can be a key-value Object, and its value only can be String or Array'
              )
            }
            return isObject(val) ? '' : String(val || '')
          })
        } else {
          this._logger.error(
            `object_kvs should be a object what's type is key-value, but got type ${typeof obj_kvs}`
          )
        }
      } catch (error) {
        this._logger.error('Parse object_kvs error in ', `${obj_kvs}`)
      }
    }

    const valueObj: { value: string; virtualValue: string } = {
      value: trimValue,
      virtualValue: '',
    }

    if (trimValue === '__virtual') {
      valueObj['value'] = ''
      valueObj['virtualValue'] = trimValue
    }

    return {
      ...valueObj,
      trigger: getQueryValue(query, 'trg'),
      event: getQueryValue(query, 'evt'),
      modifier: getQueryValue(query, 'mod'),
      type: getQueryValue(query, 'typ'),
      objectId: getQueryValue(query, 'oid'),
      index: getQueryValue(query, 'idx', true),
      length: getQueryValue(query, 'len', true),
      extra: isObject(extra) ? extra : void 0,
      _from: getQueryValue(query, '_f') || getQueryValue(query, '_from'),
    }
  }

  /**
   * 更新绑定信息
   * @param el 元素节点
   * @param binding 绑定信息
   */
  updateBinding(
    el: BaseElement,
    binding: {
      val?: string
      trg?: string
      evt?: string
      mod?: string
      typ?: string
      oid?: string
      idx?: number
      len?: number
      ext?: Record<string, any> | string
    },
    opt?:
      | string
      | {
          spmType?: 'page' | 'module' | 'item' | 'virtualItem'
          updateExtra?: boolean
        }
  ) {
    const { page, module, item, virtualItem } = this._attrs
    let itemBinding = el.getAttribute(item)
    const moduleBinding = el.getAttribute(module)
    const pageBinding = el.getAttribute(page)
    let oldBinding = ''
    let type = ''

    const _opt = isString(opt) ? { spmType: opt, updateExtra: false } : opt
    let spmType = _opt?.spmType

    if (spmType === 'virtualItem') {
      itemBinding = el.getAttribute(virtualItem)
      if (itemBinding) {
        type = virtualItem
      }
    } else if (itemBinding) {
      oldBinding = itemBinding
      type = item
    } else if (moduleBinding) {
      oldBinding = moduleBinding
      type = module
    } else if (pageBinding) {
      oldBinding = pageBinding
      type = page
    }

    // 该节点没有绑定任何信息的埋点，则不处理
    if (!type) {
      return
    }

    // 如果 ext 是 object，帮忙序列化以下
    if (isObject(binding.ext)) {
      binding.ext = JSON.stringify(binding.ext)
    }

    // const [spm, search = ''] = oldBinding.split('?')
    // const oldQuery = querystring.parse(search)

    const [spm, search = ''] = oldBinding.split('?')
    const oldQuery = querystring.parse(search)

    if (_opt?.updateExtra && oldQuery.ext && binding.ext) {
      const _ext = isString(oldQuery.ext)
        ? JSON.parse(oldQuery.ext)
        : oldQuery.ext
      const _binding_ext = binding.ext
      const bindingExt = isString(_binding_ext)
        ? JSON.parse(_binding_ext)
        : _binding_ext
      if (isObject(_ext) && _binding_ext) {
        binding.ext = {
          ..._ext,
          ...bindingExt,
        }
      }
    }

    // 如果 ext 是 object，帮忙序列化以下
    if (isObject(binding.ext)) {
      binding.ext = JSON.stringify(binding.ext)
    }

    const newQuery = Object.assign(oldQuery, stripUndefinedFields(binding))
    const newBinding = querystring.stringify(newQuery)
    el.setAttribute(type, `${spm}?${newBinding}`)
  }

  /**
   * 寻找节点
   * @param node 节点
   */
  private findNode(node: Element | string) {
    let $node: BaseElement | null = null

    // 处理 dom 节点
    if (node instanceof Element) {
      $node = node as BaseElement
    }

    // 处理 dom 选择器
    if (typeof node === 'string') {
      try {
        $node = querySelector<BaseElement>(node)
      } catch (error) {
        this._logger.error(error)
      }
    }

    // 如果 __tracker__ 不存在，则该节点未埋点
    if (!$node || !hasTrackerInfo($node)) {
      $node = null
    }

    return $node
  }

  /**
   * 把内容推到服务端
   * @param payloads 内容列表
   * @param isRetry 是否补偿上报
   * @param cb 回调函数
   */
  private pushToServer(payloads: Payload | Payload[]) {
    if (isSearchBot()) return
    this._requestQueue.sendData(payloads)
  }

  getFromLocal(debug_cache_key: string) {
    let cache_value = storage.local.get(debug_cache_key)
    if (!cache_value) return cache_value
    let cache_expire = cache_value.cache_expire
    if (cache_expire && cache_expire <= Date.now()) {
      storage.local.remove(debug_cache_key)
      return null
    }
    return cache_value
  }

  /**
   * 追踪 PageView 事件
   * @param node 页面节点
   */
  private trackPageView(node: Element | string, options: PageViewOptions = {}) {
    if (node === 'all') {
      this.$pages.forEach(page => this.trackPageView(page))
      return
    }

    const $page = this.findNode(node) as PageElement | null
    if (!$page) {
      this._logger.warn(`Can't find the page node: , `, node)
      return
    }

    this.attachTrackInfoToDom(this._attrs.page, $page)
    if ($page.__tracker__?.trigger === Constants.Manual && !options.force) {
      this._logger.warn(
        `Node is manual: If you want report, you need to set options.force eq true`,
        node
      )
      return
    }

    this.emit(Constants.BeforePageViewData, $page)

    const data = this.getPageViewData({
      eventType: 'pageview',
      spm: $page.__tracker__.value,
      page: $page.__tracker__,
    })

    const scopeState: ScopeState = {
      data: data,
      node: $page,
      spmType: 'page',
      cbList: [],
      isCall: options.__call === 'dispatchEvent',
    }

    this.initScopeState(scopeState, options)

    this.emit(Constants.EventSpm, data)

    this.triggerTransformEvent($page, data)

    scopeState.cbList.push(
      () => {
        if (options.eventCallback) {
          scopeState.eventCallback = options.eventCallback
          this.callHook(scopeState)
        }
      },
      () => {
        this.checkClickIdSpm(scopeState)
      },
      ({ data }) => {
        this._logger.info(Constants.EventPageView, data)
        this.emit(Constants.EventPageView, data, $page)
      },
      ({ data }) => {
        this.waitP(data._readyQueue, () => {
          const payload = this.getPayload(data)
          this.emit(Constants.PageViewData, data)
          this.pushToServer(payload)
        })
      },
      () => {
        this.syncToDom(scopeState)
      }
    )

    this.runQueueCb(scopeState)
  }

  waitP(p: any, cb: Function) {
    if (this.getAsyncCacheData()) {
      cb()
    } else {
      Promise.resolve(p).then(() => cb())
    }
  }

  checkClickIdSpm(scopeState: ScopeState) {
    const { node, data } = scopeState
    const trackerData = (node as PageElement).__tracker__

    if (trackerData._from === '1') {
      const actionData = this.actionQueue[0]
      if (!actionData) return
      data.page.clickId = actionData.clickId!
      data.page.clickSpm = actionData.eventCommon.spm!
    }
  }

  /**
   * 追踪 custom 事件
   * @param node 页面节点, 与需要带上 page 信息的绑定 pageview 的 node 一致
   *
   */
  private trackCustom(
    node: Element | string,
    customExtra?: { [key: string]: any }
  ) {
    const $page = this.findNode(node) as PageElement | null
    if (!$page) {
      this._logger.warn(`custom Can't find the page node:`, node)
      return
    }
    let transform = (v: any) => v
    if (customExtra?.transformData) {
      transform = customExtra.transformData
      delete customExtra.transformData
    }

    const customSpm = customExtra?.spm

    this.emit(Constants.BeforeCustomData, $page)
    const data = this.getPageViewData({
      eventType: 'custom',
      spm: customSpm || $page.__tracker__.value,
      page: $page.__tracker__,
    })

    const scopeState: ScopeState = {
      data: data,
      node: $page,
      spmType: 'page',
      eventCallback: customExtra?.eventCallback,
      cbList: [],
      isCall: customExtra?.__call === 'dispatchEvent',
    }
    this.initScopeState(scopeState, customExtra)

    scopeState.cbList.push(
      () => {
        if (customExtra?.__call === 'dispatchEvent') {
          this.callHook(scopeState)
          // remove
          ;['__call', 'syncToDom', 'eventCallback'].forEach(i => {
            if (!isNil(customExtra[i])) {
              customExtra[i] = undefined
            }
          })
        }
      },
      ({ data }) => {
        data.eventType = 'custom'
        data.customExtra = customExtra
        if (typeof transform === 'function') {
          transform.call(this, data)
        }
      },
      ({ data }) => {
        this._logger.info('event:custom', data)
        this.waitP(data._readyQueue, () => {
          this.emit(Constants.EventCustom, data, $page)
          const payload = this.getPayload(data)
          this.pushToServer(payload)
        })
      }
    )

    this.runQueueCb(scopeState)
  }

  getExposureDuration() {
    return this.options.exposureDuration || 500
  }

  /**
   * 追踪 Exposure 事件
   * @param node 模块节点
   * @param instant 是否立即上报
   */
  private trackExposure(node: Element | string, options: ExposureOptions = {}) {
    if (this.isModuleDisabled()) return
    if (node === 'all') {
      this.$modules.forEach(module => this.trackExposure(module))
      return
    }

    const $module = this.findNode(node) as ModuleElement | null
    if (!$module) {
      this._logger.warn(`Can't find the module node:`, node)
      return
    }

    const { time, trigger } = $module.__tracker__

    if (trigger === Constants.Manual && !options.force) {
      return
    }
    // 找不到模块 或 没有开始曝光时间，则返回
    if (!time) {
      return
    }

    // 小于 1s 的曝光视为无效曝光
    const duration = getNow() - time
    if (duration < this.getExposureDuration()) {
      return
    }

    // 重置曝光事件
    $module.__tracker__.time = void 0

    // 收集 spm 码并缓存起来，避免下次重新收集
    const spm = this.collectSpm($module)
    if (spm.code && spm.page && spm.module) {
      $module.__tracker__.spm = spm as ModuleElement['__tracker__']['spm']
    } else {
      this._logger.warn('TrackExposure Invalid spm:', spm)
      return
    }

    const spmCode = spm.code.replace('.__virtual', '')

    const data = this.getExposureData({
      spm: spmCode,
      duration,
      page: spm.page.__tracker__,
      module: spm.module.__tracker__,
    })

    const scopeState: ScopeState = {
      data: data,
      node: $module,
      spmType: 'module',
      cbList: [],
      isCall: options?.__call === 'dispatchEvent',
    }
    this.initScopeState(scopeState, options)

    scopeState.cbList.push(
      () => {
        // 触发 DOM 事件
        this.triggerTransformEvent($module, data)
      },
      () => {
        if (options.eventCallback) {
          scopeState.eventCallback = options.eventCallback
          this.callHook(scopeState)
        }
      },
      ({ data }) => {
        this._logger.info('event:exposure', data)
        this.emit(Constants.EventExposure, data)

        this.waitP(data._readyQueue, () => {
          const payload = this.getPayload(data)
          this.pushToServer(payload)
        })
      },
      () => {
        this.syncToDom(scopeState)
      }
    )

    this.runQueueCb(scopeState)
  }

  /**
   * 追踪 Action 事件
   * @param node 元素节点
   */
  private trackAction(
    node: Element | string,
    options: Record<string, any> = {}
  ) {
    if (node === 'all') {
      this._logger.warn('Unsupport track all action event')
      return
    }
    if (this.isBot) return

    const $item = this.findNode(node) as ItemElement | null
    if (!$item) {
      this._logger.warn(`Can't find the item node:`, node)
      return
    }

    if ($item.__virtualTracker__) {
      return this.trackVirtualAction(node, options)
    }

    const __tracker__ = $item.__tracker__ || {}
    const { event, type, clickId: click_id } = __tracker__

    // 收集 spm 码并缓存起来，避免下次重新收集
    const spm = this.collectSpm($item)
    if (spm.code && spm.page && spm.module && spm.item) {
      $item.__tracker__.spm = spm as ItemElement['__tracker__']['spm']
    } else {
      this._logger.warn('TrackAction Invalid spm:', spm)
      return
    }

    // 为每个 Action 事件生成一个标识 ID
    let clickId = this.getClickId()
    if (click_id) {
      __tracker__.clickId = clickId
      clickId = click_id
    }
    const spmCode = spm.code.replace('.__default', '')
    this._logger.info('current click info: ', clickId, spmCode)

    // 普通节点，上报它的 action 事件
    let data = this.getActionData({
      spm: spmCode,
      event,
      clickId,
      moduleExposureRatio: this.calculateModuleExposureRatio($item),
      page: spm.page.__tracker__,
      module: spm.module.__tracker__,
      // @ts-ignore
      item: spm.item.__tracker__,
    })

    const scopeState: ScopeState = {
      data: data,
      node: $item,
      spmType: 'item',
      cbList: [],
      isCall: options?.__call === 'dispatchEvent',
    }
    this.initScopeState(scopeState, options)

    this.triggerTransformEvent($item, data)
    const commonStamp = data?.eventCommon?.timestamp || getNow()

    scopeState.cbList.push(
      () => {
        // 触发 DOM 事件
        if (type !== Constants.Manual) {
          this.setFrom({
            clickId,
            clickSpm: spmCode,
            timestamp: commonStamp,
          })
        }
      },
      () => {
        if (options.eventCallback) {
          scopeState.eventCallback = options.eventCallback
          this.callHook(scopeState)
        }
      },
      ({ data }) => {
        this.pushActionQueue(data)
      },
      ({ data }) => {
        this._logger.info('event:action', data)

        this.waitP(data._readyQueue, () => {
          this.emit(Constants.EventAction, data)
          const payload = this.getPayload(data)
          this.pushToServer(payload)
        })
      },
      () => {
        this.syncToDom(scopeState)
      }
    )

    this.runQueueCb(scopeState)
  }

  private setFrom(obj: { [key: string]: any }) {
    // storage.session.set('from', {
    //   ...obj,
    // })
    storage.local.set(
      'from',
      {
        ...obj,
      },
      15000
    )

    this.emit('fromChange', obj)
  }

  /**
   * 追踪 virtual item Action 事件
   * @param node 元素节点
   * @private
   */
  private trackVirtualAction(
    node: Element | string,
    options: Record<string, any> = {}
  ) {
    if (node === 'all') {
      this._logger.warn('Unsupport track all action event')
      return
    }
    if (this.isBot) return

    const $item = this.findNode(node) as VirtualItemElement | null
    if (!$item) {
      this._logger.warn(`Can't find the item node:`, node)
      return
    }

    const __tracker__ = $item.__virtualTracker__ || {}
    const { event, type, clickId: click_id } = __tracker__

    // 收集 spm 码并缓存起来，避免下次重新收集
    const spm = this.collectSpm($item)
    if (spm.code && spm.page && spm.module && spm.item) {
      // 绑在同级 module 的 __tracker__ 上
      $item.__tracker__ &&
        ($item.__tracker__.spm = spm as VirtualItemElement['__virtualTracker__']['spm'])
    } else {
      this._logger.warn('TrackVirtualAction Invalid spm:', spm)
      return
    }

    // 为每个 Action 事件生成一个标识 ID
    let clickId = this.getClickId()
    if (click_id) {
      __tracker__.clickId = clickId
      clickId = click_id
    }
    const spmCode = spm.code.replace('.__virtual', '')

    this._logger.info('current click info: clickId, spm ', clickId, spmCode)
    // 普通节点，上报它的 action 事件
    let data = this.getActionData({
      spm: spmCode,
      event,
      clickId,
      moduleExposureRatio: this.calculateModuleExposureRatio($item),
      page: spm.page.__tracker__,
      module: spm.module.__tracker__,
      // @ts-ignore
      item: spm.item.__virtualTracker__,
    })

    const scopeState: ScopeState = {
      data: data,
      node: $item,
      spmType: 'virtualItem',
      cbList: [],
      isCall: options?.__call === 'dispatchEvent',
    }
    this.initScopeState(scopeState, options)

    // 触发 DOM 事件
    this.triggerTransformEvent($item, data)
    const commonStamp = data?.eventCommon?.timestamp || getNow()

    scopeState.cbList.push(
      () => {
        if (type !== Constants.Manual) {
          this.setFrom({
            clickId,
            clickSpm: spmCode,
            timestamp: commonStamp,
          })
        }
      },
      () => {
        if (options.eventCallback) {
          scopeState.eventCallback = options.eventCallback
          this.callHook(scopeState)
        }
      },
      ({ data }) => {
        this.pushActionQueue(data)
      },
      ({ data }) => {
        this._logger.info('event:virtual:action', data)

        this.waitP(data._readyQueue, () => {
          this.emit(Constants.EventVirtualAction, data)
          const payload = this.getPayload(data)
          this.pushToServer(payload)
        })
      },
      () => {
        this.syncToDom(scopeState)
      }
    )

    this.runQueueCb(scopeState)
  }

  findLatestModuleDom(el: Element) {
    let currentNode = el
    const { module } = this._attrs
    while (currentNode && currentNode.tagName !== 'HTML') {
      if (currentNode.hasAttribute(module)) {
        return currentNode
      }
      currentNode = currentNode.parentNode as BaseElement
    }
    return null
  }

  calculateModuleExposureRatio(clickEl: Element): number {
    const el = this.findLatestModuleDom(clickEl)
    if (!el || !el.getBoundingClientRect) {
      return 0
    }
    const domRect = el.getBoundingClientRect()
    const winHeight = window.innerHeight
    const winWidth = window.innerWidth
    const { left, right, top, bottom, width, height } = domRect

    const rectSize = width * height

    let rectHeight = 0,
      rectWidth = 0
    if (right <= 0 || left >= winWidth) {
      rectWidth = 0
    } else if (left >= 0 && right <= winWidth) {
      rectWidth = width
    } else {
      rectWidth = left < 0 ? right : winWidth - left
    }
    if (top >= winHeight || bottom <= 0) {
      rectHeight = 0
    } else if (top >= 0 && bottom <= winHeight) {
      rectHeight = height
    } else {
      rectHeight = top < 0 ? bottom : winHeight - top
    }

    return (rectHeight * rectWidth) / rectSize
  }

  /**
   * trigger transform hook/event
   * Ref: https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
   * @param el 元素节点
   * @param data 数据
   */
  private triggerTransformEvent(el: Element, data: LogObj) {
    // 调用 transform hook
    const { transform } = this.options
    if (isFunction(transform)) transform!(data)

    try {
      // 触发 transform dom 事件
      const customEvent = new CustomEvent('transform', { detail: { data } })
      el.dispatchEvent(customEvent)
    } catch (e) {
      console.warn('not supported', e)
    }
  }

  /**
   * 获取有效载荷
   * Ref: https://klook.slab.com/posts/%E7%BB%9F%E4%B8%80%E6%97%A5%E5%BF%97%E4%B8%8A%E6%8A%A5%E4%B8%8E%E5%AD%98%E5%82%A8-%E5%85%AC%E5%85%B1%E6%96%87%E6%A1%A3-loea0sfc
   * @param data 上报数据
   */
  private getPayload(data: LogObj) {
    const { platform } = this._props
    deepCompactObj(data)

    this.beforeDataEncode(data)
    this.emit('beforeDataEncode', data)
    const deviceId = this.getDeviceId() || this.getKeplerId()
    const userId = this.getUserId()
    let message
    if (data.eventType === 'custom') {
      message = json2string(data, 'customExtra')
    } else {
      message = json2string(data)
    }
    // if (this.isDebug) {
    // this._logger.info('Transformed Data', JSON.parse(message))
    // }
    const payload: Payload = {
      type: 3, // 固定为 3 给 in-house 用的
      level: 'I', // 固定为 I 即 Info
      time: this.getTimestamp(),
      msg: encode(message),
      logVer: 1, // 固定为 1 通常不会改
      uid: userId,
      isMasked: true,
      platform,
      deviceId,
    }

    return payload
  }

  private getValOrEmpty(data: LogObj, key: string, targetKey: string) {
    const val = (data?.page as any)?.[key]
    return val ? { [targetKey]: val } : {}
  }

  private beforeDataEncode(data: LogObj) {
    if (!isInApp()) return

    const eventType = data?.eventType
    const spm = data?.eventCommon?.spm
    if (!spm || eventType !== 'action') return

    const unEventId = data.unique_event_id

    this._logger.info('dsBridge', spm, data.eventType)

    bridgeAdaptor
      .call({
        spm,
        extra_info: {
          $event_id: unEventId,
          event_type: data.eventType,
          ...this.getValOrEmpty(data, 'objectId', 'object_id'),
          ...this.getValOrEmpty(data, 'clickSpm', 'click_spm'),
          ...this.getValOrEmpty(data, 'clickId', 'click_id'),
        },
      })
      .then(ret => {
        this._logger.info('dsBridge result: ', ret)
      })
      .catch(err => {
        this._logger.error('dsBridge error: ', err)
      })
  }

  checkLocal() {
    const key = 'localStorageSupport'
    this.innerState[key] = storage.isSupported()
    return this.innerState[key]
  }

  getKeplerId() {
    const kepler_id = this._props.keplerId || getFromCookie('kepler_id') || ''
    return kepler_id
  }

  getDeviceId() {
    const deviceId = this._props.deviceId || getFromCookie('kepler_id') || ''
    return deviceId
  }

  getUserId() {
    const userId = this._props.userId || getFromCookie('user_id') || ''
    return userId
  }

  private getDomLoadedTime() {
    const key = `domLoadedTime`
    if (this.innerState[key]) return this.innerState[key]
    const timing = window.performance?.timing
    let domLoadedTime = 0
    if (timing) {
      domLoadedTime = timing.domContentLoadedEventEnd - timing.navigationStart
    }
    this.innerState[key] = domLoadedTime
    return domLoadedTime
  }

  uuid_version() {
    return uidWithVersion()
  }

  private getDebugMode() {
    const { isDebug, options } = this
    const debug = isDebug ? isDebug : options?.isDebugMode
    return Boolean(debug)
  }

  private resolveTimeout(p: Promise<any>) {
    const { innerState } = this
    // const timeout = 'A.out';
    const key = Constants.AsyncDataCache
    let isResolve = false
    // const sNow = getNow()
    const callWhenResolved = (cb: Function) => {
      if (isResolve) return
      isResolve = true
      cb()
    }

    return new Promise(resolve => {
      p.then(d => callWhenResolved(() => resolve(d)))
      setTimeout(() => callWhenResolved(() => resolve({})), 500)
    }).then(d => {
      innerState[key] = d && isObject(d) ? d : {}
      return innerState[key]
    })
  }

  getDataInfo(): Promise<Record<string, any>> {
    let p: any = this.options.data
    const { innerState } = this
    const key = Constants.AsyncDataCache
    if (p && isFunction(p)) {
      return this.resolveTimeout(Promise.resolve(p.call(this, this)))
    }
    innerState[key] = p && isObject(p) ? p : {}
    return Promise.resolve(innerState[key])
  }

  public getAsyncCacheData() {
    return this.innerState[Constants.AsyncDataCache]
  }

  /**
   * 获取通用数据 (use public for test)
   * @param type 事件类型
   * @param props 属性参数
   */
  public getCommonData(
    type: EventType,
    props: {
      spm: string
      clickId?: string
      page: {
        pageId?: string
        value?: string
        objectId?: string
        extra?: Record<string, any>
      }
    }
  ) {
    const _props = this._props

    let unique_event_id = this.uuid_version()
    // const clickId = props.clickId
    const pageOpenId =
      (props.page && props.page.pageId) || _props.pageId || unique_event_id

    const referrerInfo = this._detector.getReferrer(this.getLastRef())
    const currentReferer = this._detector.getReferrerOfUrl(
      this.innerState['lastReferer']
    )

    const kid = this.localConfig.kid

    const kepler_id = this.getKeplerId()
    const deviceId = this.getDeviceId()

    let extraProps: Record<string, any> =
      kid && kepler_id && kid !== kepler_id ? { aliasKeplerId: kid } : {}

    if (type === 'pageview') {
      extraProps = {
        ...extraProps,
        lut: getNow() - (this.localConfig.unloadT || 0),
        ck: `cl:${(document.cookie || '').length}; ce:${
          navigator.cookieEnabled
        };`,
      }
    }

    const eventCommon = this.options.eventCommon || {}

    const gaProperties = {
      engagementTimeMsec: 1,
      userId: this.getUserId(),
    }

    const setGa = (res: Record<string, any>) => {
      pick(gaProperties, res, ['clientId', 'sessionId'])
    }
    const dataInfo = this.getDataInfo()
      .then(res => {
        setGa(res)
        return res
      })
      .catch()

    const cacheData = this.getAsyncCacheData()
    if (cacheData) {
      setGa(cacheData)
    }

    const data: LogObj = {
      isDebugMode: this.getDebugMode(),
      eventType: type,
      siteCommon: {
        siteName: _props.siteName,
        language: _props.siteLanguage,
        currency: _props.siteCurrency,
      },
      eventCommon: {
        ...this.getSessionId(),
        timestamp: this.getTimestamp(),
        spm: props.spm,
        experiments: _props.experiments,
        keplerDeviceId: String(kepler_id || ''),
        ...eventCommon,
      },
      superProperties: Object.assign(
        {
          ...extraProps,
          $os: this._detector.os,
          $browser: this._detector.browser,
          $currentUrl: window.location.href,
          $browserVersion: this._detector.browserVersion,
          $screenHeight: this._detector.screen.height,
          $screenWidth: this._detector.screen.width,
          $deviceId: deviceId || '',
          $distinctId: _props.distinctId || kepler_id,
          $initialReferrer: currentReferer.referrer || this._detector.referrer,
          $initialReferringDomain:
            currentReferer.referringDomain || this._detector.referringDomain,
          $referrerRef: referrerInfo.referrer,
          $referringDomainRef: referrerInfo.referringDomain,
          $searchEngine: this._detector.searchEngine,
          $navigationType: this.getNavigationType(),
          $domLoadedTime: this.getDomLoadedTime(),
          $dureTime: this.getDureTime(),
          $pShow: this.innerState.showStatus,
          $uActive: this.isActive(),
        },
        this._sprops
      ),
      page: {
        clickSpm: this._from.clickSpm,
        clickId: String(this._from.clickId),
        pageOpenId: pageOpenId,
        // pageOpenId: _props.pageId,
        appWebviewOs:
          this._detector.webviewOS || this._props.platform || void 0,
        objectId: props.page.objectId,
        extra: props.page.extra,
        is_new_page: this.getNavigationType() !== 'back_forward',
        linked_click_timestamp: +(!this._from.timestamp
          ? -1
          : this._from.timestamp),
      },
      unique_event_id: unique_event_id,
      gaProperties,
      _readyQueue: dataInfo,
    }

    return data
  }

  isActive() {
    // @ts-ignore
    const ua = navigator.userActivation || {}
    const active = ua.isActive
    return typeof active === 'undefined' ? 'nosupp' : active ? 'yes' : 'no'
  }

  getDureTime() {
    return (getNow() - this.innerState._navTime) / 1000
  }

  getSessionId() {
    return getSessionId()
  }

  private getNavigationType() {
    let type = 'unknown'
    if (
      performance &&
      performance.navigation &&
      typeof performance.navigation.type !== 'undefined'
    ) {
      let { type: ctype, redirectCount } = performance.navigation

      const T_Map = { 0: 'navigate', 1: 'reload', 2: 'back_forward' }

      type = T_Map[ctype as keyof typeof T_Map] || type

      if (ctype === 0 && redirectCount > 0) {
        type = 'redirect'
      }
      if (type === 'unknown') {
        type = String(ctype)
      }
    } else if (performance && performance.getEntriesByType) {
      let navigation: PerformanceEntry =
        performance.getEntriesByType('navigation')[0] || {}
      type = (navigation as PerformanceNavigationTiming).type
    }
    return type
  }

  /**
   * 获取 PageView 数据
   * @param props 属性参数
   */
  private getPageViewData(props: {
    spm: string
    eventType?: EventType
    page: {
      pageId?: string
      objectId?: string
      extra?: Record<string, any>
    }
  }) {
    const { marketing } = this._props
    const data = this.getCommonData(
      props.eventType ? props.eventType : 'pageview',
      {
        spm: props.spm,
        page: props.page,
      }
    )

    if (props.eventType === 'pageview') {
      data.unique_event_id = data.page.pageOpenId
      if (props.page.pageId) {
        props.page.pageId = data.unique_event_id
      }
    }

    if (marketing) {
      data.trafficCommon = {
        ...(marketing.props || {}),
        utmSource: marketing.utmSource,
        utmMedium: marketing.utmMedium,
        utmCampaign: marketing.utmCampaign,
        utmContent: marketing.utmContent,
        utmTerm: marketing.utmTerm,
        affAid: marketing.affAid,
        affPid: marketing.affPid,
        affSid: marketing.affSid,
        affAdid: marketing.affAdid,
        klookCampaignId: marketing.campaignId,
        googleAdsId: marketing.googleAdsId,
        googleDisplayadsId: marketing.googleDisplayadsId,
        wbraid: marketing.wbraid,
        gbraid: marketing.gbraid,
        campaignId: marketing.utmId,
        referringDomain: marketing.referringDomain,
        trafficRetain: marketing.trafficRetain,
        persistedSource: marketing.persistedSource,
        googleClickSource: marketing.googleClickSource,
        trafficUpdateTimestamp: marketing.trafficUpdateTimestamp,
        channel_level_1: marketing.channel_level_1,
        klc_l1: marketing.klc_l1,
        klc_l2: marketing.klc_l2,
      }
      data.trafficCommon = transformObj(data.trafficCommon, val =>
        String(val || '')
      )

      const trafficCommon = data.trafficCommon

      for (const key in trafficCommon) {
        if (
          trafficCommon.hasOwnProperty(key) &&
          typeof trafficCommon[key] !== 'boolean' &&
          !trafficCommon[key]
        ) {
          delete trafficCommon[key]
        }
      }
    }

    return data
  }

  /**
   * 获取 Exposure 事件数据
   * @param props 属性参数
   */
  private getExposureData(props: {
    spm: string
    duration: number
    page: {
      objectId?: string
      extra?: Record<string, any>
    }
    module: {
      objectId?: string
      index?: number
      length?: number
      extra?: any
    }
  }) {
    const data = this.getCommonData('exposure', {
      spm: props.spm,
      page: props.page,
    })
    const { duration, module } = props

    data.duration = duration
    data.module = {
      objectId: module.objectId,
      listIndex: module.index,
      listLen: module.length,
      extra: module.extra,
    }
    const isNil = (s: any) => s !== 0 && !s
    if (
      props.spm.toLowerCase().includes('_list') &&
      (isNil(data.module.listLen) || isNil(data.module.listIndex))
    ) {
      this._logger.warn('Module lack of listLen or listIndex', data)
    }
    return data
  }

  /**
   * 获取 Action 事件数据
   * @param props 属性参数
   */
  private getActionData(props: {
    spm: string
    event: string
    clickId: string
    moduleExposureRatio?: number
    page: {
      objectId?: string
      extra?: Record<string, any>
    }
    module: {
      objectId?: string
      index?: number
      length?: number
      extra?: Record<string, any>
    }
    item: {
      objectId?: string
      extra?: Record<string, any>
    }
  }) {
    const { spm, page, module, item, clickId, moduleExposureRatio } = props
    const data = this.getCommonData('action', { spm, page, clickId })

    data.module = {
      objectId: module.objectId,
      listIndex: module.index,
      listLen: module.length,
      extra: module.extra,
    }
    data.item = {
      objectId: item.objectId,
      extra: item.extra,
    }
    data.clickId = props.clickId
    data.unique_event_id = props.clickId
    data.actionType = props.event

    data.module_exposure_ratio = moduleExposureRatio || 0

    return data
  }

  /**
   * 收集 spm 码
   * @param el 元素节点
   */
  private collectSpm(el: BaseElement) {
    const { page, module, item, virtualItem } = this._attrs
    const spm: {
      code?: string
      page?: PageElement
      module?: ModuleElement
      item?: ItemElement | VirtualItemElement
    } = {}

    // 如果该节点已收集过了，直接返回
    if (el.__tracker__ && el.__tracker__.spm) {
      return el.__tracker__.spm
    }

    while (el && el.tagName !== 'HTML') {
      const _el = el
      el = el.parentNode as BaseElement

      const virtualItemSpm = _el.getAttribute(virtualItem)
      if (
        virtualItemSpm &&
        _el.__virtualTracker__ &&
        !spm.page &&
        !spm.module &&
        !spm.item
      ) {
        spm.code = '{page}.{module}.{item}'
        spm.item = _el as VirtualItemElement
        spm.code = spm.code.replace(
          '{item}',
          _el.__virtualTracker__.virtualValue ||
            _el.__virtualTracker__.value ||
            ''
        )
      }

      const itemSpm = _el.getAttribute(item)
      if (itemSpm && _el.__tracker__ && !spm.page && !spm.module && !spm.item) {
        spm.code = '{page}.{module}.{item}'
        spm.item = _el as ItemElement
        spm.code = spm.code.replace('{item}', _el.__tracker__.value)
        continue
      }

      const moduleSpm = _el.getAttribute(module)
      if (moduleSpm && _el.__tracker__ && !spm.page && !spm.module) {
        spm.code = spm.code || '{page}.{module}'
        spm.module = _el as ModuleElement
        spm.code = spm.code.replace('{module}', _el.__tracker__.value)
        continue
      }

      const pageSpm = _el.getAttribute(page)
      if (pageSpm && _el.__tracker__ && !spm.page) {
        spm.code = spm.code || '{page}'
        spm.page = _el as PageElement
        spm.code = spm.code.replace('{page}', _el.__tracker__.value)
        break
      }
    }

    if (spm.code) {
      let spmCode = spm.code
      spmCode = spmCode.replace('.__virtual', '')
      spmCode = spmCode.replace('.__default', '')
      spm.code = spmCode
    }

    return spm
  }

  /**
   * 生成 click id 用于标识每个点击
   */
  private getClickId() {
    return this.uuid_version()
  }

  /**
   * 统一上报事件
   * @param event 事件名称
   * @param node 节点
   * @param customExtra customEvent 上报所需 extra 信息
   */
  track(
    event: EventType,
    node: Element | string,
    customExtra?: object | PageViewOptions
  ) {
    const supportEvents = [
      'pageview',
      'exposure',
      'action',
      'custom',
      'virtualAction',
    ]
    if (this.isBot) return

    if (!supportEvents.includes(event)) {
      this._logger.warn(
        `Unsupport event: ${event}, avaliable events: ${supportEvents.join(
          ', '
        )}`
      )
      return
    }

    if (!this._isReady) {
      const args = [].slice.apply(arguments)
      this._callbackQueue.push(args)
      return
    }

    const handleEventObject = {
      pageview: this.trackPageView,
      exposure: this.trackExposure,
      action: this.trackAction,
      virtualAction: this.trackVirtualAction,
      custom: this.trackCustom,
    }
    if (handleEventObject[event]) {
      handleEventObject[event].call(
        this,
        node,
        // @ts-ignore
        Object.assign({ force: true }, customExtra)
      )
    }
  }

  assert(cond: boolean, errorMessage: any, level: 'error' | 'warn' = 'error') {
    if (cond) {
      this._logger[level](errorMessage)
      return true
    }
    return false
  }

  detectSpmType(node: Element, spmType: EventOption['spmType']) {
    let type: EventOption['spmType']
    const { module, page, virtualItem, item } = this._attrs
    if (spmType === 'virtualItem') {
      type = node?.hasAttribute(virtualItem) ? 'virtualItem' : 'item'
    } else {
      if (node.hasAttribute(page)) {
        type = 'page'
      } else if (node.hasAttribute(module)) {
        type = 'module'
      } else if (node.hasAttribute(item)) {
        type = 'item'
      }
    }
    return type
  }

  dispatchEvent(event: EventOption, eventCallback?: EventCallback) {
    if (
      this.assert(
        !event || !event.node,
        new Error('dispatchEvent: parameter is required')
      )
    )
      return

    const domNode = isString(event.node)
      ? querySelector(event.node as string)
      : event.node
    const node = domNode as BaseElement
    if (this.assert(!node, new Error(`dispatchEvent: cann't find node!`)))
      return

    const eventOpt: EventOption = {
      ...event,
      node: node!,
      syncToDom: isNil(event.syncToDom)
        ? event.spmType !== 'custom'
        : event.syncToDom,
    }
    const spmType = this.detectSpmType(node as Element, eventOpt.spmType)

    if (this.assert(!spmType, `dispatchEvent: cann't find spm info!`)) return

    const config = {
      force: true,
      syncToDom: eventOpt.syncToDom,
      eventCallback,
      __call: 'dispatchEvent',
    }

    if (spmType === 'item' || spmType === 'virtualItem') {
      this.trackAction(node, config)
    } else if (spmType === 'module') {
      this.trackExposure(node, config)
    } else if (spmType === 'custom') {
      this.trackCustom(node, config)
    } else if (spmType === 'page') {
      this.trackPageView(node, config)
    } else {
      this._logger.error(new Error(`Can not find expected type:  "${spmType}`))
    }
  }

  callHook(info: {
    data: LogObj
    node: Element
    eventCallback?: EventCallback
    spmType: EventOption['spmType']
    dataLayer?: any
  }) {
    const { eventCallback = {}, data, node, spmType } = info
    const { afterCollect } = eventCallback
    let result = data
    const dataLayer: Array<MutableTrackData> = []

    const checkReset = (
      record: MutableTrackData,
      type: 'page' | 'module' | 'item'
    ) => {
      const keyMap = {
        ext: 'extra',
        oid: 'objectId',
      }
      forEach(keyMap, (item, key) => {
        const k = key as keyof typeof keyMap
        if (record[type]?.[k]) {
          const typeRecord: any = record[type]
          typeRecord[item] = typeRecord[k]
          delete typeRecord[k]
        }
      })
      if (!isNil(record[type]?.oid)) {
      }
    }
    const setData = (record: MutableTrackData) => {
      checkReset(record, spmType as any)
      dataLayer.push(record)
    }

    if (afterCollect) {
      const trackerData = (node as BaseElement)[
        spmType === 'virtualItem' ? '__virtualTracker__' : '__tracker__'
      ]
      const cbData: CollectParamerter = {
        eventData: data,
        setData,
      }
      const key = Constants.SpmKeyMap[spmType!] as keyof Pick<
        CollectParamerter,
        'page' | 'module' | 'item'
      >
      cbData[key] = trackerData!

      afterCollect(cbData)
      info.data = mergeData(data, dataLayer)
      info.dataLayer = dataLayer
    }
    return result
  }

  initScopeState(scopeState: ScopeState, options: Record<string, any> = {}) {
    if (options?.syncToDom) {
      scopeState.syncToDom = true
    }
  }

  private runQueueCb(scopeState: ScopeState) {
    runQueue(scopeState.cbList, (cb, next) => {
      cb(scopeState)
      next()
    })
  }

  /**
   * 更新dom上的埋点数据
   *
   * @example <div id="test1" data-spm-page="test" />
   * inhouse.update2Dom('#test1', 'page', { page: { objectId: '1212' } }
   * dom#test1 -> <div id="test1" data-spm-page="test?oid=1212" />
   *
   * @param selector Element | string node 节点或者选择器
   * @param spmType 'page' | 'module' | 'item' | 'virtualItem' 节点上的埋点数据类型
   * @param dataLayer { item, page, module } 要更新的埋点数据 data-spm-item 对应 spmType=item, 其他依次对应
   *
   */
  public update2Dom(
    selector: string | Element,
    spmType: 'page' | 'module' | 'item' | 'virtualItem',
    dataLayer: Pick<MutableTrackData, 'item' | 'module' | 'page'>
  ) {
    const node = isString(selector)
      ? querySelector(selector as string)
      : (selector as Element)
    if (!node) return
    this.syncToDom({
      node,
      spmType,
      data: {} as LogObj,
      cbList: [],
      isCall: true,
      syncToDom: true,
      dataLayer: [dataLayer],
    })
  }

  private syncToDom(scopeState: ScopeState) {
    const { node, spmType, dataLayer, isCall, syncToDom } = scopeState
    if (!isCall || !syncToDom) return
    const keyMap: Record<string, string> = {
      objectId: 'oid',
      extra: 'ext',
      index: 'idx',
      listIndex: 'idx',
      length: 'len',
      listLen: 'len',
    }
    const syncData: Record<string, any> = {}

    if (['page', 'item', 'module', 'virtualItem'].includes(spmType!)) {
      const key = Constants.SpmKeyMap[
        spmType as 'page' | 'module' | 'item' | 'virtualItem'
      ] as keyof MutableTrackData

      forEach(dataLayer!, item => {
        const itemData = (item && item[key]) || {}
        forEach(itemData, (val, key) => {
          if (keyMap[key]) {
            syncData[keyMap[key]] = val
          } else {
            syncData[key] = val
          }
        })
      })
    }

    if (Object.keys(syncData).length) {
      this.updateBinding(node as BaseElement, syncData, {
        spmType,
        updateExtra: true,
      } as any)
    }
  }

  pushActionQueue(data: LogObj) {
    this.actionQueue.unshift(data)
    if (this.actionQueue.length > 3) {
      this.actionQueue.pop()
    }
  }

  /**
   * 内部状态查询
   * @param opt
   */
  public queryData(opt: QueryOpt = {}) {
    opt = opt || {}
    if (opt.node && opt.spmType) {
      const { spmType, node } = opt
      const _node = typeof node === 'string' ? querySelector(node) : node
      if (!_node) {
        this._logger.error(
          `queryData: node ${node} not found, spmType: ${spmType}`
        )
      }
      const spmAttrValue = this._attrs[spmType]

      let el = _node as BaseElement
      if (opt.nearSpm) {
        const keys = Object.keys(this._attrs).map(
          k => this._attrs[k as 'page' | 'module' | 'item' | 'virtualItem']
        )
        const latestNode = findLatestElement(
          el,
          ele => {
            if (!spmAttrValue)
              return keys.some(i => ele?.hasAttribute?.(i)) ? 1 : 0
            return ele?.hasAttribute?.(spmAttrValue) ? 1 : 0
          },
          { stopTag: null }
        )
        el = latestNode as BaseElement
      }
      if (!el) return {}

      this.attachTrackInfoToDom(spmAttrValue, el)

      const trackerData =
        spmType === 'virtualItem'
          ? el.__virtualTracker__
          : el.__tracker__ || el.__virtualTracker__
      const spm = this.collectSpm(node as BaseElement)

      return {
        ...trackerData,
        currentValue: trackerData?.value,
        spm: spm.code,
        spmInfo: { ...spm },
      }
    }

    const action0 = this.actionQueue[0]
    const page0 = this.$pages[0]

    page0 && this.attachTrackInfoToDom('data-spm-page', page0)

    const pageTracker = page0?.__tracker__ || {}

    return {
      clickFrom: {
        ...this._from,
      },
      firstClickInfo: {
        ...(action0 || {}),
        clickId: action0?.clickId,
        clickSpm: action0?.eventCommon?.spm,
      },
      pageInfo: {
        ...pageTracker,
        pageOpenId: pageTracker.pageId,
        pageSpm: pageTracker.value,
      },
      prop: this._props,
    }
  }

  sendDebugData(record: Record<string, any>) {
    if (!record) return

    const { level = 'I' } = record
    const kepler_id = this.getKeplerId()
    const page = querySelector(`[${this._attrs.page}]`)
    const pageSpm = page?.getAttribute(this._attrs.page) || ''
    const spmInfo = pageSpm.split('?')

    const message = JSON.stringify({
      system: 'optimus',
      subtype: 'iht-tracker-data',
      message: {
        current_url: window.location.href,
        page_spm: spmInfo[0],
        page_spm_info: pageSpm,
        kepler_id,
        ...record,
      },
    })

    const payload: Payload = {
      type: 5, // 固定为 3 给 in-house 用的
      level: level, // 固定为 I 即 Info
      time: this.getTimestamp(),
      msg: encode(message),
      logVer: 1, // 固定为 1 通常不会改
      uid: this.getUserId(),
      isMasked: true,
      platform: this._props.platform || 'desktop',
      deviceId: kepler_id,
    }

    this._requestQueue.sendData(payload)
    return payload
  }
}
