import { getWebsiteConfig } from '../site'
import Data from '../types'

import {
    currencySymbolMap,
    currencyRateMap,
    countryCurrencyMap,
    langCurrencyMap,
    suggestCurrencyMap,
} from './config'

export {
    // 现有代码中太多使用变量的场景，需要兼容
    currencySymbolMap,

    // 兜底的HKD汇率，外部直接使用了
    currencyRateMap,

    // 国家码币种映射
    countryCurrencyMap,

    // 语言码币种映射
    langCurrencyMap,

    // 推荐货币映射表
    suggestCurrencyMap
}

/**
 * 当前站点支持的currency list
 * @param website
 */
export function getSupportCurrenciesList(website: string): Data.Currency[] {
    return getWebsiteConfig(website).support_currencies
}

type SuggestResults = {
    suggestCurrencies: Data.Currency[]
    otherCurrencies: Data.Currency[]
}
/**
 * 通过语言和站点计算 Header suggest和other currency
 * @param language
 * @param website
 */
export function getSuggestCurrency(language: Data.Language, website: string) {
    const currencyList = getSupportCurrenciesList(website)
    const result: SuggestResults = {
        suggestCurrencies: [],
        otherCurrencies: []
    }

    result.suggestCurrencies = suggestCurrencyMap[language].filter(item =>
        currencyList.includes(item)
    )
    result.otherCurrencies = currencyList
        .filter(item => !suggestCurrencyMap[language].includes(item))
        .sort()

    return result
}

/**
 * 计算当前站点是否支持该币种
 * @param currency
 * @param website
 */
export function isSupportCurrency(currency: Data.Currency | undefined, website: string) {
    if (!currency) {
        return false
    }

    return getSupportCurrenciesList(website).includes(currency)
}


/**
 * 可在 server端 中间件放行，为了避免新增货币发版流量不能瞬间在新的服务上，所以新增货币先放开此处中间件，后续在放开站点配置，语言映射等
 * @param currency
 * @param website
 */
export function isServerMiddlewareSupportCurrency(currency: Data.Currency | undefined, website: string) {
    if (!currency) {
        return false
    }

    const webSiteConfig =getWebsiteConfig(website)

    return [
        ...webSiteConfig.support_currencies,
        ...webSiteConfig.only_server_support_currencies,
    ].includes(currency)
}

/**
 * 获取货币标识
 *
 * example:
 * getCurrencySymbol('HKD') -> return 'HK$'
 */
export function getCurrencySymbol(currency: Data.Currency) {
    return currencySymbolMap[currency]
}

/**
 * 通过国家获取货币
 */
export function getCurrencyByCountry(country: Data.Country) {
    return countryCurrencyMap[country]
}

/**
 *  根据语言选择默认货币
 */
export function getCurrencyCodeByLang(acceptLang: Data.Language) {
    return langCurrencyMap[acceptLang]
}


// 需要排除的币种列表，不会通过获取所有币种列表获取到
const allCurrencyExcludeList = ['SAR']

/**
 *  获取所有货币list
 */
export function getAllCurrencyList() {
    return Object.keys(currencySymbolMap).filter(v => !allCurrencyExcludeList.includes(v))
}

/**
 *  获取所有货币加符号list
 */
export function getAllCurrencySymbolList() {
    return Object.entries(currencySymbolMap)
        .filter(([currency]) => !allCurrencyExcludeList.includes(currency))
        .map(([currency, symbol]) => ({
        currency,
        symbol
    }))
}
